import React, { useRef, useState } from 'react';
import {
  Row,
  Col,
  Space,
  Card,
  Button,
  message,
  Typography,
  Image,
  Divider,
  Tooltip,
} from 'antd';
import { TransactionOutlined, CopyOutlined } from '@ant-design/icons'
import { exchangeService } from '@/api/userSystem';
import { _validateResponse } from '@/utils/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CryptoJS from 'crypto-js';
import ExchangeModal from './OrderComp/ExchangeModal';
import InterestModal from './OrderComp/InterestModal';
import PosterModal from './OrderComp/PosterModal';
import QRCode from 'qrcode.react';
import img_points from '@/images/reward/points.png';
import img_share from '@/images/reward/share.png';
import img_userinfo from '@/images/reward/userinfo.png';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

const OrderPage = ({
  role,
  exData,
  userInfo,
  userData,
  serviceList,
  memberInfo,

  onRefresh,
}) => {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visiblePoster, setVisiblePoster] = useState(false);
  //const containerRef = useRef();

  const _exchangeService = async (val) => {
    const maxPoint = parseInt(_.get(exData, 'memberPoint', 0));
    if (!val.costPoint || val.serviceIndex === null) {
      message.info('请填写完整的兑换信息！')
      return
    }

    if (maxPoint < val.costPoint) {
      message.info('积分余额不足！')
      return
    }

    const sIndex = _.get(val, 'serviceIndex');
    let params = {
      costPoint: _.get(val, 'costPoint'),
      goodsName: serviceList[sIndex].name,
      description: serviceList[sIndex].description,
      levelId: serviceList[sIndex].levelId,
      type: serviceList[sIndex].type,
      userId: _.get(userInfo, 'id'),
    }

    let exRes = await exchangeService(params);
    if (_.get(exRes, 'code') === '200') {
      message.success(_.get(exRes, 'message', '兑换成功！'));
      setVisible(false);
      onRefresh();
    } else {
      message.info(_.get(exRes, 'message', '兑换失败！'));
    }
  }

  const btn_col = { xxl: 6, xl: 6, lg: 6, md: 12, sm: 16, xs: 16, style: { textAlign: 'right' } };
  // 加密分享链接 AES加密：对称字符串:order123
  const LINK_URL_SHOW = 'http://39.103.203.59:8870/register';
  const getUserId = _.get(exData, 'userId', 0)
  const cryptoUserid = CryptoJS.AES.encrypt(_.toString(getUserId), 'order123').toString();
  const LINK_URL = `${LINK_URL_SHOW}??@${cryptoUserid}`;

  const nextLevelData = role === 'trader' ? [
    { label: '1、建立微信群', value: '50人以上' },
    { label: '2、自购', value: '自购免费体验' },
  ] : [
    { label: '1、活跃值大于4000分', value: '我的(直属)基金经理12名，全部基金经理50名' },
    { label: '2、活跃值大于4000分', value: '我的(直属)活跃基金经理5名，全部活跃基金经理20名' },
  ];

  const PublicCard = () => {
    return (
      <Card>
        <Space>
          <Image src={img_share} width={20} preview={false} />
          <Text strong>{'您的推广链接'}</Text>
        </Space>
        <Row
          gutter={[16, 16]}
          justify='center'
          align='middle'
          style={{ minHeight: 305, textAlign: 'center' }}
        >
          <Col span={24}>
            <QRCode value={LINK_URL} size={145} />
            <div style={{ marginTop: 10 }}>
              <CopyToClipboard text={LINK_URL}
                onCopy={() => { message.success('复制成功！') }}>
                <Text strong underline style={{ fontSize: 16, cursor: 'pointer' }}>
                  {LINK_URL_SHOW}
                </Text>
              </CopyToClipboard>
            </div>
            <CopyToClipboard text={LINK_URL}
              onCopy={() => { message.success('复制成功！') }}>
              <Space>
                <Text strong underline style={{ fontSize: 16, cursor: 'pointer' }}>
                  点击复制
                </Text>
                <CopyOutlined />
              </Space>
            </CopyToClipboard>
          </Col>
        </Row>
      </Card>
    )
  }

  const InfoCard = () => {
    return (
      <Card>
        <Space>
          <Image src={img_userinfo} width={20} preview={false} />
          <Text strong>下一级晋升标准</Text>
        </Space>
        <div style={{ height: 15 }}></div>
        {nextLevelData.map((n, i) => (
          <Row justify='space-between' key={'next' + i}>
            <Col span={role === 'trader' ? 12 : 8}>{n.label}</Col>
            <Col span={role === 'trader' ? 12 : 16} style={{ textAlign: 'right' }}>{n.value}</Col>
          </Row>
        ))}
        <Row justify='end' style={{ marginTop: 9 }}>
          <Col {...btn_col}>
            <Button
              shape='round'
              size='small'
              // icon={<MoreOutlined />}
              onClick={() => setVisible2(true)}
            >
              更多权益
            </Button>
          </Col>
        </Row>
        <Divider />
        <Space>
          <Image src={img_points} width={20} preview={false} />
          <Text strong>{'推广积分'}</Text>
        </Space>
        <div style={{ textAlign: 'right' }}>
          <Text>{'当前积分：' + _.get(exData, 'memberPoint', 0)}</Text>
        </div>
        {/* <div>
        <Progress percent={30} />
      </div> */}
        <Row justify='end' style={{ marginTop: 9 }}>
          <Col  {...btn_col}>
            <Tooltip title={_.get(exData, 'status', '') === 1 ? '会员期间无法兑换' : null}>
              <Button
                type='primary'
                shape='round'
                size='small'
                disabled={_.get(exData, 'status', '') === 1 ? true : false}
                icon={<TransactionOutlined />}
                onClick={() => setVisible(true)}
              >
                积分兑换
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Card>
    )
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <PublicCard />
        </Col>
        <Col span={24}>
          <InfoCard />
        </Col>
      </Row>

      <ExchangeModal
        visible={visible}
        list={serviceList}
        maxPoint={_.get(exData, 'memberPoint', 0)}
        onCancel={() => setVisible(false)}
        onConfirm={(params) => _exchangeService(params)}
      />

      <InterestModal
        visible={visible2}
        role={role}
        onCancel={() => setVisible2(false)}
      />

      <PosterModal
        visible={visiblePoster}
        qrLink={LINK_URL}
        role={role}
        onCancel={() => setVisiblePoster(false)}
      />
    </>
  )
}

export default OrderPage
