import React, { useState } from 'react';
import { Button, Table, Tag, Modal, message } from 'antd';
import { Link } from 'react-router-dom';
import AddProductDetails from '../../ProductDetails/Components/AddProductDetails';
// import UpdateTraders from '../../ProductDetails/Components/UpdateTraders';
import { delManagerSubAccount } from '@/api/workbench';
import { getProductDetailss } from '@/api/details';
import _ from 'lodash';

const AccountTable = ({
  role,
  newAccountList,
  accountListHome,
  accountList,
  newProps,
  goBack
}) => {
  const [productList, setProductList] = useState([]);

  function _seeSubAccount(record, bool) {
    newProps.history.push({
      pathname: '/home/listsDetails',
      state: {
        subAccountInfo: {
          ...record,
          subAccountId: record.id,
          subAccountName: record.name
        },
        canTrade: bool ? 'yes' : 'no' // 子账户无权交易，跳转是进行判断
      }
    });
  }

  function _seeMDetail(record, bool) {
    newProps.history.push({
      pathname: '/home/mListsDetails',
      state: {
        subAccountInfo: {
          ...record,
          subAccountId: record.id,
          subAccountName: record.name
        },
        allAccount: accountListHome
      }
    });

  }

  async function _getProduct(record) {
    if (role === 'fund_manager') {
      const data = await getProductDetailss({ params: { productId: record.id } });
      // console.log('getProduct', data)
      if (data.code === '200') {
        let securitiesAccounts = data.data.securitiesAccounts;
        securitiesAccounts.map(item => {
          let totalRevenue = 0;
          let dailyRevenue = 0;
          item.subAccountList.map(item => {
            totalRevenue += item.totalRevenue;
            dailyRevenue += item.dailyRevenue;
            return item;
          })
          item.totalRevenue = totalRevenue;
          item.dailyRevenue = dailyRevenue;
          return item;
        })
        setProductList(securitiesAccounts);
      }
    }
  }

  async function _delSubAccount(record) {
    Modal.confirm({
      title: record.name,
      content: '是否删除该子账户',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const data = await delManagerSubAccount({ subAccountId: record.id });
        if (data.code === '200') {
          goBack();
          message.success('删除成功');
        } else {
          message.error(data.message);
        }
      }
    });
  }


  const columns = [
    {
      title: '账户名称',
      dataIndex: 'name',
      width: 80,
    },
    {
      title: '账户类别',
      dataIndex: 'subAccountType',
      width: 80,
      render: (text) => {
        return (text === '1' ? <span>股票</span> : text === '2' ? <span>期货</span> : <span>基金</span>)
      }
    },
    // {
    //   title: '证券账户',
    //   dataIndex: 'securitiesAccountName',
    //   width: 120,
    //   render: (text, record) => {
    //     const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
    //     return findindex !== -1 ? accountList[findindex].securitiesAccountName : ''
    //   }
    // },
    // {
    //   title: '基金产品',
    //   dataIndex: 'productName',
    //   width: 120,
    //   render: (text, record) => {
    //     const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
    //     return findindex !== -1 ? accountList[findindex].productName : ''
    //   }
    // },
    {
      title: '净值',
      dataIndex: 'netValue',
      width: 80,
      render: (text, record) => {
        const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
        return findindex !== -1 ? accountList[findindex].netValue : ''
      }
    },
    {
      title: '总资产',
      dataIndex: 'totalAsset',
      width: 100,
      render: (text, record) => {
        const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
        return findindex !== -1 ? accountList[findindex].totalAsset : ''
      }
    },
    {
      title: '总市值',
      dataIndex: 'position',
      width: 100,
      render: (text, record) => {
        const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
        return findindex !== -1 ? accountList[findindex].position : ''
      }
    },
    {
      title: '可用资金',
      dataIndex: 'balance',
      width: 100,
      render: (text, record) => {
        // const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
        // return findindex !== -1 ? accountList[findindex].balance : ''
        return _.get(record, 'availableBalance', '')
      }
    },
    {
      title: '总收益',
      dataIndex: 'totalRevenue',
      width: 100,
      render: (text, record) => {
        const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
        const getData = findindex !== -1 ? accountList[findindex].totalRevenue : ''
        return (parseFloat(getData) > 0 ? <span style={{ color: 'red' }}>{getData}</span> : parseFloat(getData) < 0 ? <span style={{ color: 'green' }}>{getData}</span> : <span>{getData}</span>)
      }
    },
    {
      title: '日收益',
      dataIndex: 'dailyRevenue',
      width: 80,
      render: (text, record) => {
        const findindex = _.findIndex(accountList, o => o.subAccountId === _.get(record, 'id', ''))
        const getData = findindex !== -1 ? accountList[findindex].dailyRevenue : ''
        return (parseFloat(getData) > 0 ? <span style={{ color: 'red' }}>{getData}</span> : parseFloat(getData) < 0 ? <span style={{ color: 'green' }}>{getData}</span> : <span>{getData}</span>)
      }
    },
    {
      title: '操作',
      fixed: 'right',
      width: 140,
      render: (text, record) => {
        //console.log('newAccountList', newAccountList)
        // console.log('accountList', accountList)
        return (
          <>
            <Button type="link" onClick={() => _seeMDetail(record, true)}>{'查看 >'}</Button>
            <AddProductDetails
              pageKey='traderDesk'
              detailValue={record}
              maxBalance={_.get(record, 'availableBalance', '')}
              listData={productList}
              goBack={() => _getProduct(record)}
              onShow={() => _getProduct(record)}
            />
          </>
        )
      }
    },
  ]
  const columns_org = [
    {
      title: '账户名称',
      dataIndex: 'subAccountName',
      width: 80,
    },
    {
      title: '账户类别',
      dataIndex: 'subAccountType',
      width: 80,
      render: (text) => {
        return (text === '1' ? <span>股票</span> : text === '2' ? <span>期货</span> : <span>基金</span>)
      }
    },
    // {
    //   title: '证券账户',
    //   dataIndex: 'securitiesAccountName',
    //   width: 120,
    // },
    // {
    //   title: '基金产品',
    //   dataIndex: 'productName',
    //   width: 120,
    // },
    {
      title: '净值',
      dataIndex: 'netValue',
      width: 80,
    },
    {
      title: '总资产',
      dataIndex: 'totalAsset',
      width: 100,
    },
    {
      title: '总市值',
      dataIndex: 'position',
      width: 100,
    },
    {
      title: '可用资金',
      dataIndex: 'balance',
      width: 100,
    },
    {
      title: '总收益',
      dataIndex: 'totalRevenue',
      width: 100,
      render: (text) => {
        return (parseFloat(text) > 0 ? <span style={{ color: 'red' }}>{text}</span> : parseFloat(text) < 0 ? <span style={{ color: 'green' }}>{text}</span> : <span>{text}</span>)
      }
    },
    {
      title: '日收益',
      dataIndex: 'dailyRevenue',
      width: 80,
      render: (text) => {
        return (parseFloat(text) > 0 ? <span style={{ color: 'red' }}>{text}</span> : parseFloat(text) < 0 ? <span style={{ color: 'green' }}>{text}</span> : <span>{text}</span>)
      }
    },
    {
      title: '操作',
      fixed: 'right',
      width: 100,
      render: (record) => {
        return (
          <Link to={
            {
              pathname: "/home/listsDetails",
              state: { subAccountInfo: record }
            }
          }>{'查看 >'}</Link>
          // <Button type="link" onClick={() => _seeSubAccount_org(record)}>查看</Button>
        )
      }
    },
  ]

  const expandedRowRender = (fatRecord) => {
    const columnsChildren = [
      {
        title: '子账户',
        dataIndex: 'name',
        width: 100,
        align: 'center',
        render: (text, record, index) => {
          return <div><Tag color="blue">{text}</Tag></div>
        }
      },
      {
        title: '资产',
        dataIndex: 'totalAsset',
        sorter: (a, b) => b.totalAsset - a.totalAsset,
        width: 120,
        align: 'center',
        render: (text, record, index) => {
          return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
        }
      },
      {
        title: '净值',
        dataIndex: 'netValue',
        width: 100,
        align: 'center',
        sorter: (a, b) => b.netValue - a.netValue,
        render: (text, record, index) => {
          return <div><Tag color="blue">{parseFloat(text).toFixed(4)}</Tag></div>
        }
      },
      {
        title: '收益',
        dataIndex: 'totalRevenue',
        sorter: (a, b) => b.totalRevenue - a.totalRevenue,
        width: 100,
        align: 'center',
        render: (text, record, index) => {
          return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
        }
      },
      {
        title: '日收益',
        dataIndex: 'dailyRevenue',
        align: 'center',
        width: 100,
        sorter: (a, b) => b.dailyRevenue - a.dailyRevenue,
        render: (text, record, index) => {
          return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
        },
      },
      {
        title: '可用资金',
        dataIndex: 'availableBalance',
        sorter: (a, b) => b.availableBalance - a.availableBalance,
        width: 100,
        align: 'center',
        render: (text, record, index) => {
          return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
        },
      },
      {
        title: '交易员',
        dataIndex: 'traderName',
        align: 'right',
        width: 100,
        render: (text, record, index) => {
          return (
            <><Tag color="blue">{text}</Tag></>
          )
        }
      },
      {
        title: '操作',
        align: 'right',
        width: 110,
        render: (text, record, index) => {
          return (
            <>
              <Button type="link" onClick={() => _seeSubAccount(record, false)}>{'查看>>'}</Button>

              {/* <UpdateTraders fatRecord={fatRecord} record={record} goBack={goBack} />

              <Button type="link" danger onClick={() => _delSubAccount(record)}>删除</Button> */}
            </>
          )
        }
      }
    ]

    return (
      <Table
        rowKey="id"
        dataSource={_.get(fatRecord, 'subAccountList', [])}
        columns={columnsChildren}
        pagination={false}
        scroll={{ x: 850 }}
      />
    )
  }

  return (
    <>
      {role === 'trader_manager' ? <Table
        rowKey="id"
        dataSource={newAccountList}
        columns={columns}
        bordered
        scroll={{ x: 950 }}
        expandable={{
          expandedRowRender,
        }}
        pagination={false}
      />
        : <Table
          rowKey="subAccountId"
          dataSource={accountList}
          columns={columns_org}
          bordered
          scroll={{ x: 950 }}
          pagination={{
            defaultPageSize: 5,
          }}
        />
      }
    </>
  )
}

export default AccountTable;