import React, { Component } from 'react';
import {
  Row,
  Col,
  Result,
  Card,
  PageHeader,
  Typography,
  Button,
  Steps,
  message,
  Modal,
  Alert,
  Space,
  Table
} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  newOrder, checkDiscount, goWechatPay, checkIsPaied, showSubscriptionServices,
  getOrders
} from '@/api/userSystem';
import { _validateResponse } from '@/utils/utils';
import QRCode from 'qrcode.react';
import moment from 'moment';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import UnpayTable from './UnpayTable';
import '../payment.scss'
import _ from 'lodash';

const { Step } = Steps;
const { Text } = Typography;
const CONTAINER_STYLE = { marginTop: 10, marginLeft: 8, marginRight: 8 };
const handleDiffTime = (time2) => {
  return moment().diff(moment(time2), 'minute');
}
class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      form: {
        duration: 1,
        level: {},
        buyCount: 1,
      },
      discountList: {},
      order: {},
      orderList: [],
      unpayList: [],
      serviceList: [],
      wechatResponse: {},
      visible: false,
      visibleUnpay: false,

      payOrderType: 0, // 支付类型，扣费还是支付
      unpayMoney: 0,
    }
    this.routeData = props.location.state;
  }

  componentDidMount() {
    console.log('路由', this.routeData)
    const unpayOrder = _.get(this.routeData, 'unpayOrder', []);
    if (_.size(unpayOrder) > 0) {
      this.setState({
        unpayList: unpayOrder,
        visibleUnpay: true
      })
    }
    if (_.get(this.routeData, 'fromPay', null)) {
      const getUnpayFees = _.get(this.routeData, 'unpayFees', 0)
      this.setState({
        payOrderType: 2,
        unpayMoney: getUnpayFees,
        form: { ...this.state.form, orderAmount: getUnpayFees }
      })
    } else {
      this.setState({ payOrderType: 1 })
    }

    this.getServices();
  }

  getServices = async () => {
    let servRes = await showSubscriptionServices();
    this.setState({
      serviceList: _validateResponse(servRes, [])
    })
  }

  getOrder = async () => {
    let orderRes = await getOrders();
    let orderData = _validateResponse(orderRes)
    this.setState({
      unpayList: _.filter(orderData, o => {
        return o.orderStatus === 0 && handleDiffTime(o.expiryTime) < 0
      }),
      visibleUnpay: true
    })
  }

  handleNext = async () => {
    const { form, serviceList, payOrderType } = this.state;
    let reg = /(^(([1-9]([0-9]+)?)|(0{1}))(\.[0-9]{1,2})?$)/;

    if (payOrderType === 0) {
      console.log('payOrderType有误！！')
      return
    }
    if (!_.get(form, 'level.id', null) && payOrderType === 1) {
      message.info('请选择服务规格!');
      return
    }
    if (!_.get(form, 'buyCount', null && payOrderType === 1)) {
      message.info('请选择服务时长!');
      return
    }

    if (payOrderType === 2 && !reg.test(_.get(form, 'orderAmount', 0))) {
      message.info('请输入正确的金额!');
      return
    }

    let params = {};
    if (payOrderType === 1) {
      const getItemIndex = parseInt(form.level.index);
      const getItemValue = serviceList[getItemIndex];
      const getDiscount = _.round(_.get(form, 'discount', 0), 2);
      const getBuyCount = _.get(form, 'buyCount', 1);
      const finalPrice = _.round(_.get(getItemValue, 'price', 0) * getBuyCount, 2)

      params = {
        "orderAmount": getDiscount > 0 ? finalPrice - getDiscount : finalPrice, //订单金额
        "goodsName": _.get(getItemValue, 'name', ''), //策略名称
        "buyCount": getBuyCount, //购买数量
        "description": _.get(getItemValue, 'description', ''), //策略描述
        "level": _.get(getItemValue, 'levelId', ''), //策略等级
        "duration": _.get(form, 'level.type', '') === 'trial' ? 'week' : 'month', //区间
        "type": _.get(form, 'level.type', ''),
        "orderType": payOrderType,
      }
    } else if (payOrderType === 2) {
      params = {
        "orderAmount": _.get(form, 'orderAmount'),
        "goodsName": '费用支付',
        "buyCount": 1,
        "description": '提前支付费用',
        "orderType": payOrderType,
      }
    }

    if (params.orderAmount < 0) {
      message.error('订单金额有误！')
      console.log('创建订单', params)
      return
    }

    let orderRes = await newOrder(params);
    let orderData = _validateResponse(orderRes, {})
    if (orderData.id) {
      this.setState({
        orderList: [orderData],
        order: orderData,
        step: 1
      })
    } else {
      message.info('创建订单失败！')
    }
  }

  handlePay = async () => {
    const { form, orderList } = this.state;
    const getPay = _.get(form, 'pay', null);
    const getOrderData = _.head(orderList);
    if (!getPay) {
      message.info('请选择支付方式!');
      return
    }

    if (getPay === 1) {
      let wechatRes = await goWechatPay(getOrderData.orderNo);
      if (_.get(wechatRes, 'code', '') === '200') {
        message.info('请扫码支付！')
        this.setState({
          wechatResponse: _.get(wechatRes, 'data', {}),
          visible: true
        })
      }

    } else if (getPay === 2) {
      //let aliRes = await goAlipay(getOrderData.orderNo);
      const w = window.open('about:blank');
      w.location.href = global.api + '/payment/goAliPay?orderNo=' + getOrderData.orderNo;

      Modal.confirm({
        title: '确认是否支付完成！',
        icon: <ExclamationCircleOutlined />,
        content: '',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.isOrderPayied(getOrderData.orderNo)
        },
        onCancel() { },
      });
    }
  }

  isOrderPayied = async (orderNo, type) => {
    let payRes = await checkIsPaied(orderNo);
    if (_.get(payRes, 'code') === '200') {
      message.success('支付成功！')
      if (type === 'wechat') {
        this.setState({
          visible: false, wechatResponse: {}
        }, () => {
          this.goOrderPage();
        })
      } else {
        this.goOrderPage();
      }
    } else if (_.get(payRes, 'code') === '400') {
      message.success('支付失败！')
      this.setState({
        visible: false, wechatResponse: {}
      })
    }
  }

  handleDiscount = async (id) => {
    const { form, discountList } = this.state;
    let tempDiscount = discountList;
    let tempForm = form;

    if (discountList[id]) {
      tempForm.discount = discountList[id]['preferentialAmount'];
      this.setState({
        form: tempForm
      })

      return
    }

    if (id) {
      let disRes = await checkDiscount(id);
      if (_.get(disRes, 'code') === '200') {
        const roundPrice = _.round(_.get(disRes, 'data.preferentialAmount', 0), 2);
        tempForm.discount = roundPrice;
        tempDiscount[id] = { preferentialAmount: roundPrice }
        this.setState({
          form: tempForm,
          discountList: tempDiscount
        })
      }
    }
  }

  handleWechatModalOk = () => {
    const { wechatResponse } = this.state;
    Modal.confirm({
      title: '确认是否支付完成！',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '是',
      cancelText: '否',
      onOk: () => {
        if (wechatResponse.orderNo) {
          this.isOrderPayied(_.get(wechatResponse, 'orderNo'), 'wechat')
        }
      },
      onCancel() { },
    });
  }

  handleModal = (show) => {
    this.setState({ visible: show })
  }

  handleUnpayOrder = (record, payType) => {
    this.setState({
      orderList: [{ ...record }],
      form: { ...this.state.form, pay: payType },
      visibleUnpay: false
    }, () => {
      this.handlePay()
    })
  }

  goOrderPage = () => {
    this.props.history.push({
      pathname: '/home/order',
    });
  }

  renderPrice = () => {
    const { step, orderList, form, payOrderType } = this.state;
    if (step > 0) {
      const orderPrice = _.chain(orderList).head().get('orderAmount').value();
      return _.round(orderPrice, 2)
    } else if (payOrderType === 2) {
      return _.round(_.get(form, 'orderAmount', 0), 2)
    } else {
      return _.round(_.get(form, 'level.price', 0) * _.get(form, 'buyCount', 1), 2);
    }
  }

  render() {
    const { step, form, orderList, visible, wechatResponse,
      order, serviceList, visibleUnpay, unpayList
    } = this.state;
    const nextBtn = <Button key="2" type="primary" onClick={() => {
      this.handleNext();
    }}>下一步</Button>
    const confirmBtn = <Button key="1" type="primary" disabled={step !== 1 ? true : false} onClick={() => {
      this.handlePay();
    }}>
      确认订单
    </Button>
    return (
      <div style={{ paddingBottom: 96 }}>
        <PageHeader
          ghost={false}
          title='订阅服务'
          onBack={() => this.props.history.goBack()}
        >

        </PageHeader>

        <div style={{ paddingTop: 20, paddingBottom: 15 }}>
          <Row justify="center" align='center'>
            <Col span={16}>
              <Steps current={step}>
                <Step title="选择服务" />
                <Step title="确认订单" />
                <Step title="支付完成" />
              </Steps>
            </Col>
          </Row>
        </div>

        {step === 0 && <StepOne
          containerStyle={CONTAINER_STYLE}
          serviceData={serviceList}
          isPayed={_.get(this.routeData, 'exData.isTrial', '') === 1 ? true : false}
          formValue={form}
          orderType={this.state.payOrderType}
          unpayFee={this.state.unpayMoney}
          onTypeChange={(type) => this.setState({ payOrderType: type })}
          onChange={(key, value) => {
            let newValue = { [key]: value };
            if (key === 'level' && value.type === 'trial') {
              newValue.buyCount = 1;
            }
            this.setState({
              form: { ...form, ...newValue }
            }, () => {
              if (key === 'level') {
                const getLevelId = value.id;
                this.handleDiscount(getLevelId);
              }
            })

          }} />}
        {step === 1 && <StepTwo
          containerStyle={CONTAINER_STYLE}
          tData={orderList}
          onChange={(key, value) => {
            this.setState({
              form: { ...form, [key]: value }
            })
          }}
        />}
        {step === 2 && <div style={CONTAINER_STYLE}>
          <Card bordered={false}>
            <Result
              status="success"
              title="支付成功"
              subTitle={`订单号：${_.get(order, 'orderNo', '')}; 商品描述：${_.get(order, 'description', '')}`}
              extra={[
                <Button key="buy" onClick={() => this.goOrderPage()}>查看订单列表</Button>,
              ]}
            />
          </Card>
        </div>}


        <div className='bottomBar'>
          <div className='unpay'>
            {_.size(unpayList) > 0 ? <div onClick={() => this.getOrder()} style={{ lineHeight: '65px' }}>
              <Text type='warning'>{`您有${_.size(unpayList)}笔未支付订单`}</Text>
            </div> : null}
          </div>
          <div className='confirmContent'>
            <div style={{ textAlign: 'right', marginRight: '25px', marginTop: '11px' }}>
              配置费用：
              <span style={{ color: 'red' }}>￥</span>
              <Text
                // delete={_.get(form, 'discount', 0) > 0 && step === 0 ? true : false}
                style={{ color: 'red', fontSize: '26px', fontWeight: 700 }}
              >
                {this.renderPrice()}
              </Text>
            </div>
            {_.get(form, 'discount', 0) > 0 && step === 0 &&
              <div style={{ marginTop: 8, textAlign: 'right', marginRight: '25px' }}>
                <Text type='danger'>{'续费可抵扣 ' + _.get(form, 'discount', 0)}</Text>
              </div>}
          </div>
          <div className='btnContent'>
            {step === 0 ? nextBtn : confirmBtn}
          </div>
        </div>

        <Modal
          title="微信扫码支付"
          visible={visible}
          onOk={() => this.handleWechatModalOk()}
          cancelText={null}
          onCancel={() => this.handleWechatModalOk()}
          maskClosable={false}
        >
          <div style={{ textAlign: 'center' }}>
            {wechatResponse.codeUrl ? <QRCode value={_.get(wechatResponse, 'codeUrl')} size={256} /> : null}
          </div>
        </Modal>

        <UnpayTable
          visible={visibleUnpay}
          unpayList={unpayList}
          onCancel={() => this.setState({ visibleUnpay: false })}
          onUnpayOrder={(record, status) => this.handleUnpayOrder(record, status)}
        />
      </div>
    )
  }
}

export default Payment;