import React, { useState } from 'react';
import {
    Row,
    Col,
    Modal,
    Typography,
    Button,
    Space,
    message
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CanvasPoster from 'react-canvas-poster';
import { triggerBase64Download } from 'react-base64-downloader';
import _ from 'lodash';

const { Title, Paragraph, Text } = Typography;
const { confirm } = Modal;

const PosterModal = ({ visible, role, qrLink, onCancel }) => {
    const [poster64, setPoster64] = useState(null)
    const posterData = {
        width: 360,
        height: 667,
        backgroundColor: '#fff',
        debug: true,
        views: [
            {
                type: 'image',
                //url: require('./test.png'),
                url: 'https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF',
                top: 0,
                left: 0,
                width: 360,
                height: 667,
            },
            {
                type: 'qcode',
                text: qrLink,
                top: 420,
                left: 135,
                width: 90,
                height: 90,
            },
        ]
    }

    function posterSuccess(res) {
        console.log('success', res);
        setPoster64(res)
    }

    return (
        <Modal
            visible={visible}
            onCancel={() => onCancel()}
            footer={null}
        >
            <div style={{ marginBottom: 10 }}>
                <Button type='primary' onClick={() => {
                    triggerBase64Download(poster64, '推广海报')
                }}>
                    点击下载
                </Button>
            </div>
            <CanvasPoster drawData={posterData} success={posterSuccess} />
        </Modal>
    )
}

export default PosterModal;