import moment from 'moment';
import _ from 'lodash';

const STORAGE_TYPE = {
  '1': 'url_string',
  '2': 'trading_status', // 全局存储交易状态，全局缓存数据用
  'cmp': 'compare_account', // 对比交易时存储上一次账户id
  'h_pdt': 'home_product', // 首页卡片存储习惯
  'sub_acc': 'sub_account', // subAccount 分页
};
// 产品对比虚拟产品标识
const VSTRING = 'V#';

const saveStorage = (type, val) => {
  try {
    localStorage.setItem(STORAGE_TYPE[type], val);
  } catch (error) { }
}
const getStorage = (type) => {
  try {
    let val = localStorage.getItem(STORAGE_TYPE[type]);
    return val ?? ''
  } catch (error) { }
}
// 生成一项简单表格的对象
const simpleColumns = (pmArray = ['', ''], rest = {}) => {
  let obj = {
    title: pmArray[0],
    dataIndex: pmArray[1],
    key: pmArray[1],
    ...rest
  }
  if (pmArray[2]) {
    obj.width = pmArray[2];
  }
  return obj;
}
// 属性判断
const isValidArray = (array) => array && _.isArray(array) && _.size(array) > 0 ? true : false;
const isValidObj = (object) => object && _.isObject(object) && _.size(object) > 0 ? true : false;
const isValidNumber = (number) => number && _.isNumber(number) ? true : false;
const isValidString = (string) => string && _.isString(string) && string !== '' ? true : false;
// 数字创建每三位逗号分隔的进位格式，返回字符串；
function addNumberSplit(number) {
  let finalStirng = '';
  let splitNumber = _.reverse(_.split(number, ''));
  let c = 0;
  let isDim = splitNumber.indexOf('.') !== -1 ? true : false; // 修复整数c无法递增，一直保持!isDim的状态
  let stringArray = [];
  splitNumber.map(n => {
    let num = n !== '.' ? parseInt(n) : null;
    if (n === '.') {
      isDim = false;
    }
    if (c === 3 && !isDim && n !== '-') {
      c = 0;
      stringArray.push(',');
    }
    if (num !== null && !isDim) {
      c = c + 1;
    }
    stringArray.push(n);
  })
  finalStirng = _.join(_.reverse(stringArray), '');
  if (_.includes(finalStirng, '.00')) {
    finalStirng = finalStirng.replaceAll('.00', '')
  }
  return finalStirng;
}

const labelValues = (array) => {
  let obj = { label: array[0], value: array[1] };
  if (array[2]) {
    obj.children = array[2]
  }
  return obj;
};
// 时间排序及创建timeKey字段; pure_date=数组内为时间字符串，不是对象格式
const format_obj = {
  'd': 'YYYY-MM-DD',
  's': 'HH:mm:ss', 'fs': 'YYYY-MM-DD HH:mm:ss',
  'm': 'HH:mm', 'fm': 'YYYY-MM-DD HH:mm',
  'fms': 'YY-MM-DD HH:mm:ss:SSS', 'ms': 'HH:mm:ss:SSS',
};
const key_obj = {
  d: 'd', s: 's', m: 'm', ms: 'millisecond',
  fs: 's', fm: 'm', fms: 'millisecond',
};
const createDatasTimeKey = (array = [], compareKey = '', timeString = 'd', orders = 'desc') => {
  let final = [];
  if (isValidArray(array) && isValidString(compareKey)) {
    final = array.map(n => {
      const cval = compareKey === 'pure_date' ? n : _.get(n, compareKey);
      return _.assign(compareKey === 'pure_date' ? { 'date': n } : { ...n }, {
        'timeKey': moment(cval).diff(moment(), key_obj[timeString]),
        'formatDate': moment(cval).format(format_obj[timeString] || 'YYYY-MM-DD'),
      })
    });
  }
  return _.orderBy(final, ['timeKey'], [orders]);
}
// 指定日期之后的日期禁止点击
const disabledDateFunc = (current, targetDate) => {
  const momentTarget = targetDate ? moment(targetDate) : moment();
  const tooLate = current.diff(momentTarget, 'days') > 0;
  return !!tooLate;
};
// 提取出产品内部的符合条件的子账户；同TradeCompare处理逻辑
const labelIncludesFalse = (label) => { // 检查是否包含中英文括号
  const bracket_en = ["(", ")"]; const bracket_cn = ["（", "）"];
  let ens = bracket_en.map(b => _.includes(label, b));
  let cns = bracket_cn.map(b => _.includes(label, b));
  return !ens.includes(false) || !cns.includes(false) ? true : false; // 中英文有一组都不包含false的，则匹配完整括号 返回true
}
// labelIncludesFalse版本2，检测()进行分类，并检测是否包含在首页当中
const accountOptionIndex = (label, isHome) => {
  const bracket_en = ["(", ")"]; const bracket_cn = ["（", "）"];
  let ens = bracket_en.map(b => _.includes(label, b));
  let cns = bracket_cn.map(b => _.includes(label, b));
  const finalBool = !ens.includes(false) || !cns.includes(false) ? true : false;
  //返回上层分类的index值: 0:每日更新；1:回测中 2:其他 
  if (finalBool === true) {
    return 0;
  } else if (finalBool === false && isHome) {
    return 1;
  } else if (finalBool === false) {
    return 2;
  }
}
// 计算当前是否是开盘时间
const isMarketTrading = (isTradeDay = true) => {
  const TOTAY = moment().format('YYYY-MM-DD');
  const OPEN_TIME = moment(TOTAY + ' 9:30');
  const CLOSE_TIME = moment(TOTAY + ' 15:00');
  const currentTime = moment();
  let isGet = false;
  const diffOpenMin = currentTime.diff(OPEN_TIME, 'm');
  const diffCloseMin = currentTime.diff(CLOSE_TIME, 'm');
  if (diffOpenMin >= 0 && diffOpenMin <= 120) {
    isGet = true;
  };
  if (diffCloseMin >= -120 && diffCloseMin <= 0) {
    isGet = true;
  };
  // 非交易日直接返回false
  if (!isTradeDay) {
    isGet = false;
  }
  return isGet;
};

export {
  saveStorage, getStorage,
  simpleColumns,
  isValidArray, isValidObj, isValidNumber, isValidString,
  addNumberSplit,
  labelValues,
  createDatasTimeKey,
  disabledDateFunc,
  labelIncludesFalse, accountOptionIndex,
  isMarketTrading,
  // 静态
  VSTRING,
}