import React, { useState, useEffect } from 'react';
import {
    Card, Col, Row, Button, Table, Form, DatePicker, Select, message, PageHeader,
    Tabs, Space, Divider, InputNumber, AutoComplete, Input, Statistic, Descriptions
} from 'antd';
import { useMediaQuery } from 'react-responsive'
import { showSubAccountData } from '@/api/workbench';
import { useUnmount } from 'ahooks';
import { buy, sell, market, showList, getCurrentStocks, getCurrentPositionStocks } from '@/api/stock';
import { PlusOutlined, MinusOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { cryptoDatas } from '@/utils/cryptoUtils';
import _ from 'lodash';

let asset = null;

export default function ListsDetails(props, { history }) {
    const { Search } = Input;
    const { RangePicker } = DatePicker;
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [subAccountInfo] = useState(props.location.state.subAccountInfo);
    const [active] = useState(props.location.state.active);
    const [record] = useState(props.location.state.record);
    const [assetData, setassetData] = useState({});
    const [listData, setlistData] = useState({});
    const [orderList, setorderList] = useState([]);
    const [askPrice, setaskPrice] = useState([]);
    const [askVol, setaskVol] = useState([]);
    const [bidPrice, setbidPrice] = useState([]);
    const [bidVol, setbidVol] = useState([]);
    const [options, setoptions] = useState([]);
    const [optionsSell, setoptionsSell] = useState([]);
    const [isDisabled, setisDisabled] = useState(false);
    const { TabPane } = Tabs;
    const { Option } = Select;
    const [form] = Form.useForm();
    const [Value, setValue] = useState(0);
    const [number, setNumber] = useState(100);
    const [currentPage, setcurrentPage] = useState(1);
    const [containsETF, setcontainsETF] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);

    const entrust = [
        {
            title: '序号',
            width: 70,
            render: (text, record, index) => { return (currentPage - 1) * 10 + index + 1 },
        },
        {
            title: '委托编号',
            dataIndex: 'clientOrderId',
            width: 130,
        },
        {
            title: '股票代码',
            dataIndex: 'stockCode',
            width: 90,
        },
        {
            title: '股票名称',
            dataIndex: 'stockName',
            width: 100,
        },
        {
            title: '委托时间',
            dataIndex: 'commissionTime',
            width: 130,
            sorter: (a, b) => {
                let aTime = new Date(a.commissionTime).getTime();
                let bTime = new Date(b.commissionTime).getTime();
                return aTime - bTime;
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend'
        },
        {
            title: '委托数量',
            dataIndex: 'quantity',
            width: 80,
        },
        {
            title: '成交数量',
            dataIndex: 'cumQty',
            width: 80,
        },
        {
            title: '委托均价',
            dataIndex: 'price',
            width: 80,
        },
        {
            title: '成交均价',
            dataIndex: 'avgPrice',
            width: 80,
        },
        {
            title: '买卖方向',
            dataIndex: 'side',
            width: 80,
            render: (text) => {
                return (text === 'BUY' ? <span>买入</span> : <span>卖出</span>)
            }
        },
        {
            title: '状态',
            dataIndex: 'orderStatus',
            fixed: 'right',
            width: 100,
            render: (text, record, index) => {
                return (text === '完成' ? <span style={{ color: 'green' }}>{text}</span> : text === '未完成' ? <span style={{ color: 'red' }}>{text}</span> : <span style={{ color: 'blue' }}>{text}</span>)
            }
        },
    ]
    useEffect(() => {
        if (number <= 100) { setisDisabled(true); }
        //资产收益等信息
        async function _getlist() {
            const data = await showSubAccountData({ subAccountId: subAccountInfo.subAccountId });
            if (data.code === '200') {
                setassetData(data.data);
            }
        }
        //获取行情买卖价
        async function _getMarke() {
            if (record.stockName.indexOf('ETF') !== -1 || record.stockName.indexOf('LOF') !== -1) {
                setcontainsETF(1);
            }
            let data = null;
            if (!record.tsCode) {
                data = await market({ subAccountId: subAccountInfo.subAccountId, tsCode: record.stockCode });
            } else {
                data = await market({ subAccountId: subAccountInfo.subAccountId, tsCode: record.tsCode });
            }
            if (data.code === '200') {
                if (data.data === null) {
                    return message.info(data.message);
                }
                setlistData(data.data.marketData);
                setaskPrice(data.data.marketData.askPrice);
                setaskVol(data.data.marketData.askVol);
                setbidPrice(data.data.marketData.bidPrice);
                setbidVol(data.data.marketData.bidVol);
                if (!record.buyOrSell) {
                    if (active === '1') {
                        setValue(parseFloat(data.data.marketData.askPrice[0]).toFixed(3));
                    } else {
                        setValue(parseFloat(data.data.marketData.bidPrice[0]).toFixed(3));
                    }
                } else {
                    if (record.buyOrSell === '1') {
                        setValue(parseFloat(data.data.marketData.askPrice[0]).toFixed(3));
                    } else {
                        setValue(parseFloat(data.data.marketData.bidPrice[0]).toFixed(3));
                    }
                }
            }
        }
        async function _get() {
            asset = setInterval(
                async function () {
                    const nextRoutePathName = window.location.pathname;
                    if (!String.prototype.startsWith.call(nextRoutePathName, '/home/Trading')) {
                        clearInterval(asset);
                        return;
                    }
                    _getlist();
                    _getMarke();
                    _getData();
                }, 30000);
        }
        //获取买入卖出股票代码池
        async function _getOptions() {
            const data1 = await getCurrentStocks({ subAccountId: subAccountInfo.subAccountId });
            const data2 = await getCurrentPositionStocks({ subAccountId: subAccountInfo.subAccountId });
            if (data1.code === '200') {
                let arr = [];
                data1.data.map(item => {
                    let b = { value: _.get(item, 'stockCode', '') + '  ' + _.get(item, 'stockName', '') };
                    arr.push(b);
                    return item;
                })
                setoptions(arr)
            }
            if (data2.code === '200') {
                let brr = [];
                data2.data.map(item => {
                    let b = { value: _.get(item, 'stockCode', '') + '  ' + _.get(item, 'stockName', '') };
                    brr.push(b);
                    return item;
                })
                setoptionsSell(brr)
            }
        }
        _getlist();
        _getMarke();
        _getData();
        _getOptions();
        _get();
    }, [updateCount])

    useUnmount(() => {
        asset && clearInterval(asset)
    });
    
    //修改交易记录表格时间
    function selectTime(date, dateString) {
        subAccountInfo.startAndEndTime = dateString;
        _getData();
    }
    //根据时间和股票名称查询交易记录
    async function selectRecord(value) {
        subAccountInfo.content = value.replace(/(^\s*)|(\s*$)/g, "");
        _getData();
    }
    //获取交易记录
    async function _getData() {
        let params = {
            subAccountId: subAccountInfo.subAccountId,
            state: true,
            beginTime: subAccountInfo.startAndEndTime ? subAccountInfo.startAndEndTime[0] : null,
            endTime: subAccountInfo.startAndEndTime ? subAccountInfo.startAndEndTime[1] : null,
            content: subAccountInfo.content ? subAccountInfo.content : null,
        }
        const data = await showList(params);
        if (data.code === '200' || data.code === '205') {
            if (data.data !== null) {
                data.data.map(item => {
                    item.commissionTime = item.commissionTime.substring(0, item.commissionTime.indexOf('.'));
                    return item;
                })
                setorderList(data.data);
            } else {
                setorderList([]);
            }
        } else {
            message.error(data.message);
        }
    }
    //下单
    async function orderBuy() {
        const values = await form.validateFields();
        values.subAccountId = subAccountInfo.subAccountId;
        values.highLimitPrice = listData.highLimited;
        values.stockName = record.stockName;
        if (!record.tsCode) {
            values.stockCode = record.stockCode.substring(0, record.stockCode.indexOf('.'));
            if (record.stockCode.substring(record.stockCode.indexOf('.') + 1) === 'SH') {
                values.market = 'SH_A';
            } else {
                values.market = 'SZ_A';
            }
        } else {
            values.stockCode = record.stockCode;
            if (record.tsCode.substring(record.tsCode.indexOf('.') + 1) === 'SH') {
                values.market = 'SH_A';
            } else {
                values.market = 'SZ_A';
            }
        }
        values.price = Value;
        values.quantity = number;
        values.side = "BUY";
        if (values.price === null) {
            return message.error("请输入委托价格");
        }
        if (values.quantity === null) {
            return message.error("请输入委托数量");
        }
        const data = await buy(cryptoDatas(values));
        if (data.code === '200') {
            message.success("购买成功");
            _getData();
            setNumber(100);
        } else {
            message.error(data.message);
        }
    }
    async function orderSell() {
        const values = await form.validateFields();
        values.subAccountId = subAccountInfo.subAccountId;
        values.highLimitPrice = listData.highLimited;
        values.stockName = record.stockName;
        if (!record.tsCode) {
            values.stockCode = record.stockCode.substring(0, record.stockCode.indexOf('.'));
            if (record.stockCode.substring(record.stockCode.indexOf('.') + 1) === 'SH') {
                values.market = 'SH_A';
            } else {
                values.market = 'SZ_A';
            }
        } else {
            values.stockCode = record.stockCode;
            if (record.tsCode.substring(record.tsCode.indexOf('.') + 1) === 'SH') {
                values.market = 'SH_A';
            } else {
                values.market = 'SZ_A';
            }
        }
        values.price = Value;
        values.quantity = number;
        values.side = "SELL";
        if (values.quantity === null) {
            return message.error("请输入委托数量");
        }
        const data = await sell(cryptoDatas(values));
        if (data.code === '200') {
            message.success("卖出成功");
            _getData();
            setNumber(100);
        } else {
            message.error(data.message);
        }
    }
    function onSelect(data) {
        let arr = data.split('  ');
        record.stockName = arr[1];
        record.tsCode = arr[0];
        record.stockCode = arr[0].substring(0, arr[0].indexOf('.'));
        setcontainsETF(0);
        if (arr[1].indexOf('ETF') !== -1 || arr[1].indexOf('LOF') !== -1) {
            setcontainsETF(1);
        }

        setUpdateCount(updateCount + 1)
    }
    function callback(key) {
        record.buyOrSell = key;
        if (key === '1') {
            setValue(parseFloat(bidPrice[0]).toFixed(3));
        } else {
            setValue(parseFloat(askPrice[0]).toFixed(3));
        }
    }

    function renderValueColor(value) {
        let pureValue = _.replace(value || '', ',', '');
        const floatValue = pureValue ? parseFloat(pureValue) : 0;
        if (floatValue > 0) {
            return 'red'
        } else if (floatValue < 0) {
            return 'green'
        } else {
            return 'black'
        }
    }

    function renderValuePrefix(value) {
        const floatValue = value ? parseFloat(value) : 0;
        if (floatValue > 0) {
            return <ArrowUpOutlined style={{ color: 'red' }} />
        } else if (floatValue < 0) {
            return <ArrowDownOutlined style={{ color: 'green' }} />
        } else {
            return null
        }
    }

    const common_static_style = {
        fontSize: 24, fontWeight: '600'
    }
    const header_col = { xxl: 4, xl: 4, lg: 6, md: 12, sm: 12, xs: 12 };
    const info3List = [
        { label: '开盘价', key: 'open' },
        { label: '收盘价', key: 'match' },
        { label: '最高价', key: 'high' },
        { label: '最低价', key: 'low' },
        { label: '昨收价', key: 'preClose' },
        { label: '涨跌幅', key: 'match' },
        { label: '成交量', key: 'volume' },
        { label: '成交额', key: 'turnover' },
        { label: '涨停价', key: 'highLimited' },
        { label: '跌停价', key: 'lowLimited' },
    ]
    return (
        <>
            <PageHeader
                title={'交易'}
                subTitle={_.get(subAccountInfo, 'subAccountName', '')}
                onBack={() => props.history?.goBack()}
                style={{ backgroundColor: 'white' }}
            ></PageHeader>

            <div className="contentView" id="trading">
                <Card>
                    <Row className="headView" gutter={[8, 8]}>
                        <Col {...header_col} className="headViewCol">
                            <Statistic
                                title={'总资产'}
                                value={assetData.totalAssets || '0'}
                                valueStyle={common_static_style}
                            />
                        </Col>
                        <Col {...header_col} className="headViewCol">
                            <Statistic
                                title={'可用资金'}
                                value={assetData.totalBalance || '0'}
                                valueStyle={common_static_style}
                            />
                        </Col>
                        <Col {...header_col} className="headViewCol">
                            <Statistic
                                title={"总收益"}
                                value={assetData.totalRevenue}
                                valueStyle={{ ...common_static_style, color: renderValueColor(assetData.totalRevenue) }}
                            />
                        </Col>
                        <Col {...header_col} className="headViewCol">
                            <Statistic
                                title={"净值"}
                                value={assetData.netValue}
                                valueStyle={{ ...common_static_style, color: renderValueColor(assetData.netValue) }}
                            />
                        </Col>
                        <Col {...header_col} className="headViewCol">
                            <Statistic
                                title={"日收益"}
                                value={assetData.dailyRevenue}
                                valueStyle={{ ...common_static_style, color: renderValueColor(assetData.dailyRevenue) }}
                                prefix={renderValuePrefix(assetData.dailyRevenue)}
                            />
                        </Col>
                        <Col {...header_col} className="headViewCol">
                            <Statistic
                                title={"日收益率"}
                                value={parseFloat(assetData.dailyRevenueRate).toFixed(2) + '%'}
                                valueStyle={{ ...common_static_style, color: renderValueColor(assetData.dailyRevenueRate) }}
                                prefix={renderValuePrefix(assetData.dailyRevenueRate)}
                            />
                        </Col>
                    </Row>
                </Card>
                <br />
                <Card>
                    <Row>
                        <Col xxl={8} xl={8} lg={12} md={24} sm={24}>
                            <Tabs defaultActiveKey={active} onChange={callback}>
                                <TabPane tab="买入" key="1">
                                    <Row>
                                        <Form form={form} name="control-hooks" >
                                            <Form.Item label="股票名称或代码">
                                                <AutoComplete
                                                    options={options}
                                                    style={{ width: 200 }}
                                                    onSelect={onSelect}
                                                    placeholder="请输入股票名称或代码"
                                                    defaultValue={record.tsCode ? record.tsCode + '  ' + record.stockName : record.stockCode + '  ' + record.stockName}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item label="报价类型">
                                                <Select
                                                    placeholder="请选择报价类型"
                                                    defaultValue="市价单"
                                                >
                                                    <Option value="市价单">市价单</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="price" label="委托价">
                                                <Row>
                                                    <InputNumber disabled={true} value={Value} min={0} step={0.01} />
                                                </Row>
                                            </Form.Item>
                                            <Form.Item name="quantity" label="委托数量">
                                                <Row>
                                                    <InputNumber value={number} onChange={setNumber} min={100} step={100} />
                                                    <Button
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<PlusOutlined />}
                                                        onClick={() => {
                                                            setNumber(number + 100);
                                                            if (number >= 100) {
                                                                setisDisabled(false);
                                                            }
                                                        }} />
                                                    <Button
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<MinusOutlined />}
                                                        disabled={isDisabled}
                                                        onClick={() => {
                                                            setNumber(number - 100);
                                                            if (number === 200) {
                                                                setisDisabled(true);
                                                            }
                                                        }} />
                                                </Row>
                                            </Form.Item>
                                            <Form.Item label="拆单方式" rules={[{ required: true }]}>
                                                <Select
                                                    placeholder="请选择拆单方式"
                                                    defaultValue="不拆单"
                                                >
                                                    <Option value="不拆单">不拆单</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                                <Button type="primary" onClick={orderBuy}>下单</Button>
                                            </Form.Item>
                                        </Form>
                                    </Row>
                                </TabPane>
                                <TabPane tab="卖出" key="2">
                                    <Row>
                                        <Form form={form} name="control-hooks" >
                                            <Form.Item label="股票名称或代码">
                                                <AutoComplete
                                                    options={optionsSell}
                                                    style={{ width: 200 }}
                                                    onSelect={onSelect}
                                                    placeholder="请输入股票名称或代码"
                                                    defaultValue={record.tsCode ? record.tsCode + '  ' + record.stockName : record.stockCode + '  ' + record.stockName}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item label="报价类型">
                                                <Select
                                                    placeholder="请选择报价类型"
                                                    defaultValue="市价单"
                                                >
                                                    <Option value="市价单">市价单</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="price" label="委托价">
                                                <Row>
                                                    <InputNumber disabled={true} value={Value} min={0} step={0.01} />
                                                </Row>
                                            </Form.Item>
                                            <Form.Item name="quantity" label="委托数量">
                                                <Row>
                                                    <InputNumber value={number} onChange={setNumber} min={100} step={100} />
                                                    <Button
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<PlusOutlined />}
                                                        onClick={() => {
                                                            setNumber(number + 100);
                                                            if (number >= 100) {
                                                                setisDisabled(false);
                                                            }
                                                        }} />
                                                    <Button
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<MinusOutlined />}
                                                        disabled={isDisabled}
                                                        onClick={() => {
                                                            setNumber(number - 100);
                                                            if (number === 200) {
                                                                setisDisabled(true);
                                                            }
                                                        }} />
                                                </Row>
                                            </Form.Item>
                                            <Form.Item label="拆单方式" rules={[{ required: true }]}>
                                                <Select
                                                    placeholder="请选择拆单方式"
                                                    defaultValue="不拆单"
                                                >
                                                    <Option value="不拆单">不拆单</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                                <Button type="primary" onClick={orderSell}>下单</Button>
                                            </Form.Item>
                                        </Form>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Col>
                        <Col xxl={8} xl={8} lg={12} md={24} sm={24}>
                            <Space size="middle">
                                <span className="headCardTetx">{record.tsCode ? record.tsCode : record.stockCode}</span>
                                <span className="headCardTetx">{record.stockName}</span>
                            </Space>
                            <Divider />
                            <Space size="large">
                                <Col>昨收<span className="headCardTetx" style={{ marginLeft: 10 }}>{listData.preClose}</span></Col>
                                <Col>涨跌幅<span className="headCardTetx" style={{ marginLeft: 10 }}>{(((listData.match - listData.preClose) / listData.preClose * 100).toFixed(2)) + '%'} </span></Col>
                            </Space>
                            <Divider />
                            <Row>
                                <Space size="large">
                                    <Col>卖5</Col>
                                    {containsETF === 1 ? parseFloat(askPrice[4]).toFixed(3) : parseFloat(askPrice[4]).toFixed(2)}
                                    {askVol[4]}
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>卖4</Col>
                                    {containsETF === 1 ? parseFloat(askPrice[3]).toFixed(3) : parseFloat(askPrice[3]).toFixed(2)}
                                    {askVol[3]}
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>卖3</Col>
                                    {containsETF === 1 ? parseFloat(askPrice[2]).toFixed(3) : parseFloat(askPrice[2]).toFixed(2)}
                                    {askVol[2]}
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>卖2</Col>
                                    {containsETF === 1 ? parseFloat(askPrice[1]).toFixed(3) : parseFloat(askPrice[1]).toFixed(2)}
                                    {askVol[1]}
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>卖1</Col>
                                    {containsETF === 1 ? parseFloat(askPrice[0]).toFixed(3) : parseFloat(askPrice[0]).toFixed(2)}
                                    {askVol[0]}
                                </Space>
                            </Row>
                            <Divider />
                            <Row>
                                当前报价<span className="headCardTetx" style={{ marginLeft: 10 }}>{parseFloat(listData.match).toFixed(3)}</span>
                            </Row>
                            <Divider />
                            <Row>
                                <Space size="large">
                                    <Col>买1</Col>
                                    <Col>{containsETF === 1 ? parseFloat(bidPrice[0]).toFixed(3) : parseFloat(bidPrice[0]).toFixed(2)}</Col>
                                    <Col>{bidVol[0]}</Col>
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>买2</Col>
                                    <Col>{containsETF === 1 ? parseFloat(bidPrice[1]).toFixed(3) : parseFloat(bidPrice[1]).toFixed(2)}</Col>
                                    <Col>{bidVol[1]}</Col>
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>买3</Col>
                                    <Col>{containsETF === 1 ? parseFloat(bidPrice[2]).toFixed(3) : parseFloat(bidPrice[2]).toFixed(2)}</Col>
                                    <Col>{bidVol[2]}</Col>
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>买4</Col>
                                    <Col>{containsETF === 1 ? parseFloat(bidPrice[3]).toFixed(3) : parseFloat(bidPrice[3]).toFixed(2)}</Col>
                                    <Col>{bidVol[3]}</Col>
                                </Space>
                            </Row>
                            <Row>
                                <Space size="large">
                                    <Col>买5</Col>
                                    <Col>{containsETF === 1 ? parseFloat(bidPrice[4]).toFixed(3) : parseFloat(bidPrice[4]).toFixed(2)}</Col>
                                    <Col>{bidVol[4]}</Col>
                                </Space>
                            </Row>
                            <Divider />
                            <Space size="large">
                                <Col>涨停<span className="headCardTetx" style={{ marginLeft: 10 }}>{listData.highLimited}</span></Col>
                                <Col>跌停<span className="headCardTetx" style={{ marginLeft: 10 }}>{listData.lowLimited}</span></Col>
                            </Space>
                        </Col>
                        <Col xxl={8} xl={8} lg={12} md={12} sm={24} style={{ paddingLeft: 12, marginTop: isMobile ? 15 : 0 }}>
                            {isMobile ?
                                <Descriptions title={''} column={2} >
                                    {info3List.map((item, index) => {
                                        let show = _.get(listData, item.key, '')
                                        if (item.key === 'match') {
                                            show = (((listData.match - listData.preClose) / listData.preClose * 100).toFixed(2)) + '%'
                                        }
                                        return <Descriptions.Item key={item.key + index} label={item.label}>
                                            {show}
                                        </Descriptions.Item>
                                    })}
                                </Descriptions>
                                : <Row>
                                    <Form form={form} name="control-hooks" >
                                        {info3List.map((item, index) => {
                                            let show = _.get(listData, item.key, '')
                                            if (item.key === 'match') {
                                                show = (((listData.match - listData.preClose) / listData.preClose * 100).toFixed(2)) + '%'
                                            }
                                            return <Form.Item key={item.key + index} label={item.label}>
                                                {show}
                                            </Form.Item>
                                        })}
                                    </Form>
                                </Row>}
                        </Col>
                    </Row>
                </Card>
                <br />
                <Card title="交易记录" bordered={true} extra={
                    isMobile ? null : <Row justify="end" align="middle">
                        <span style={{ marginRight: 10 }}>选择日期:</span>
                        <RangePicker onChange={selectTime} />
                        <Search placeholder="请输入股票代码/名称" allowClear enterButton style={{ width: 230, marginLeft: 10 }} onSearch={selectRecord} />
                    </Row>
                }>
                    {isMobile && <Row justify="end" align="middle" style={{ marginBottom: 10 }}>
                        <span style={{ marginRight: 10 }}>选择日期:</span>
                        <RangePicker onChange={selectTime} />
                        <Search placeholder="请输入股票代码/名称" allowClear enterButton style={{ width: 230, marginLeft: 10 }} onSearch={selectRecord} />
                    </Row>}
                    <Table
                        rowKey="clientOrderId"
                        dataSource={orderList}
                        columns={entrust}
                        scroll={{ x: 1100 }}
                        bordered
                        pagination={{
                            pageSize: 10,
                            onChange: e => { setcurrentPage(e) }
                        }}
                    />
                </Card>
            </div>
        </>

    )
}