import React, { useState, useEffect } from 'react';
import { Card, message, Tabs, PageHeader } from 'antd';
import axios from 'axios';
import EchartsInfo from './comp/EchartsInfo';
import EchartsInfo8 from './comp/EchartsInfo8';
import EchartsInfo7 from './comp/EchartsInfo7';
import {
  showSubAccountData, showManagerAssets, getFundManagerSubAccount
} from '@/api/workbench';
import {
  mShowSubAccountHistory, mShowContribution, mPersonalDisplayProductLevel,
  mStocksDisplayProductLevel, mPlateDisplaySubAccountLevel
} from '@/api/userSystem';
import { isAllStockHoliday } from '@/api/strategy';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMediaQuery } from 'react-responsive';
import MAccountTable from './ManComp/mAccountTable';
import MAssetsHeader from './ManComp/mAssetsHeader';
import Mtrader from './ManComp/mTrader';
import moment from 'moment';
import _ from 'lodash';

let timer1 = null;
let timer2 = null;
let timer3 = null;

const { TabPane } = Tabs;
const TODAY_TIME = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

export default function ManagerListDetail(props) {
  const [subAccountInfo] = useState(props.location.state.subAccountInfo);
  const [allAccount] = useState(props.location.state.allAccount);
  const [assetData, setassetData] = useState({});
  const [subAssest, setSubAsset] = useState({});
  const [time, settime] = useState({
    '1': [],
    '2': [moment().format('YYYY-MM-DD'), moment().add('days', 1).format('YYYY-MM-DD')],
    "3": [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  });
  // 收益率
  const [chartsData, setchartsData] = useState([]);
  const [chartsXData, setchartsXData] = useState([]);
  const [pointDate, setpointDate] = useState([]);
  const [dataType, setDataType] = useState('TODAY');
  //盈亏
  const [chartsData1, setchartsData1] = useState([]);
  const [chartsXData1, setchartsXData1] = useState([]);
  const [chart8Name, setChart8Name] = useState([]);// 盈亏名称
  //仓位
  const [pieChartData, setpieChartData] = useState([]);
  const [series, setseries] = useState([]);
  const [legend, setlegend] = useState([]);
  const [tabKey, setTabKey] = useState('1');

  const [newTabs, setNewTabs] = useState({ '1': '1' })
  const [hasSubAccount, setHasSubAccount] = useState([]);
  const mobxStore = useLocalObservable(() => store);
  const [isSubListdetail] = useState(_.get(props, 'location.state.traderDest', '') === 'trader_desk' ? true : false)
  const [loadingNew, setLoadingNew] = useState(false)

  const [tradeDate, setTradeDate] = useState(true); // 是否是交易日
  const [globParams, setGlobParams] = useState({ '1': {}, '2': {}, '3': {} })// 预加载参数，优化获取时间功能
  const [updateCount, setUpdateCount] = useState(0)
  const [axiosSource] = useState(axios.CancelToken.source());
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const validateResponse = (res = {}) => {
    let getCode = _.get(res, 'code', '');
    setLoadingNew(false)
    if (getCode === '200') {
      return true
    } else if (getCode === '400') {
      if (res.data) {
        message.warning(_.get(res, 'data', '无数据！'))
      } else if (res.message) {
        message.warning(_.get(res, 'message', '无数据！'))
      }
      return false
    } else {
      return false
    }
  }

  async function _initGetData() {
    const data = await showSubAccountData({ subAccountId: subAccountInfo.id });
    if (validateResponse(data)) {
      setassetData(data.data);
    }
  }

  async function _getSubAsset() {
    const data = await showManagerAssets({ subAccountId: subAccountInfo.id });
    // console.log('_getSubAsset', data)
    if (validateResponse(data)) {
      setSubAsset(data.data);
    }
  }

  async function _getManagerSubAccount() {
    if (subAccountInfo.traderId) {
      const data = await getFundManagerSubAccount(subAccountInfo.traderId);
      if (validateResponse(data)) {
        const getAccount = _.filter(_.get(data, 'data', []), o => o.id === subAccountInfo.id)
        const getSubList = _.get(_.head(getAccount), 'subAccountList', [])
        if (_.size(getSubList) > 0) {
          _getSubAsset();
        }
        setHasSubAccount(getSubList);
      }
    }
  }
  // 获取收益率曲线
  async function _getShowHistory(dt) {
    setLoadingNew(true)
    const getDataType = dt || dataType;
    const data1 = await mShowSubAccountHistory({ dateType: getDataType, subAccountId: subAccountInfo.id });
    //  console.log('_getShowHistory', data1)
    if (validateResponse(data1)) {
      if (!data1.data.pointList) {
        setchartsData([]);
        setchartsXData([]);
        return;
      }
      if (getDataType === 'TODAY') {
        let a = Date.parse(new Date('1970-1-1 9:30:00'));
        let b = Date.parse(new Date('1970-1-1 13:00:00'));
        let c = [];
        for (var i = 0; i < 121; i++) {
          c.push(moment(new Date(a)).format('HH:mm'));
          a += 60000;
        }
        for (var j = 0; j < 121; j++) {
          c.push(moment(new Date(b)).format('HH:mm'));
          b += 60000;
        }
        let arr = [];
        let timeList = data1.data.timeList;
        let pointList = data1.data.pointList;
        if (pointList.length !== 0) {
          for (let i = 0; i < pointList.length; i++) {
            arr.push([timeList[i], Number(pointList[i]).toFixed(4)]);
          }
        }
        //settime(c);
        setchartsData(arr);
        setchartsXData(timeList);
        setpointDate(pointList);
      } else {
        let arr = [];
        let pointList = data1.data.pointList;
        if (pointList.length !== 0) {
          for (let i = 0; i < pointList.length; i++) {
            arr.push(Number(pointList[i]).toFixed(4));
          }
        }
        setchartsData(arr);
        setchartsXData(data1.data.timeList);
        setpointDate(pointList);
      }
    }
  }

  async function _subContribution(type, value, prevGet) {
    if (!prevGet) {
      setLoadingNew(true)
    }

    let params = {
      subAccountId: subAccountInfo.id,
      beginDate: type ? value[0] : _.get(time['2'], '[0]'),
      endDate: type ? value[1] : _.get(time['2'], '[1]'),
    }

    let lastParams = globParams;
    if (_.get(lastParams, '2.beginDate', '') === params.beginDate && _.get(lastParams, '2.endDate', '') === params.endDate) {
      setUpdateCount(updateCount + 1)
      setLoadingNew(false)
      return
    }
    lastParams['2'] = { beginDate: params.beginDate, endDate: params.endDate };
    setGlobParams(lastParams);

    const data = await mShowContribution(params);
    if (validateResponse(data)) {
      let result = _.get(data, 'data.subAccountHistoryList', []);
      let dataKeys = _.head(result) ? Object.keys(_.head(result)) : [];
      let arr = [];
      if (_.size(dataKeys) > 0) {
        dataKeys.map(keyname => {
          let a = {};
          a.name = keyname;
          a.type = 'line';
          a.data = result.map(r => r[keyname] || 0);
          a.symbol = 'none';
          arr.push(a);
        })
      }
      setchartsXData1(_.get(data, 'data.timeList', []));
      setchartsData1(arr);
      setChart8Name(_.get(data, 'data.subAccountIdNameMap', {}))
    }
  }

  async function chartsGoBack8(value) {
    let tempTime = time;
    tempTime['2'] = value;
    settime(tempTime);
    _subContribution('changeTime', value);
  }

  //仓位部分 
  async function _changeTab(value1, value2, prevGet) {
    let tempTime = time;
    tempTime['3'] = value2;
    settime(tempTime);
    if (!prevGet) {
      setLoadingNew(true)
    }

    let lastParams = globParams;
    if (_.get(lastParams, '3.beginDate', '') === value2[0]
      && _.get(lastParams, '3.endDate', '') === value2[1]
      && _.get(lastParams, '3.tab', '') === value1
    ) {
      setLoadingNew(false)
      return
    }
    lastParams['3'] = { beginDate: value2[0], endDate: value2[1], tab: value1 };
    setGlobParams(lastParams);

    if (value1 === '1') {
      const data = await mPersonalDisplayProductLevel({
        subAccountId: subAccountInfo.id,
        beginDate: value2[0],
        endDate: value2[1]
      });

      if (validateResponse(data)) {
        let arr = [];
        let brr = [];
        let crr = [{ name: '总仓位' }, { name: '现金' }];
        let a = ['product', ..._.get(data, 'data.timeList', [])];
        let b = ['总仓位', ..._.get(data, 'data.personalPositionList', [])];
        let c = ['现金', ..._.get(data, 'data.moneyList', [])];
        arr.push(a, b, c);
        let obj1 = { name: '总仓位', type: 'line', symbol: 'none', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } };
        let obj3 = { name: '现金', type: 'line', symbol: 'none', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } };
        let obj2 = {
          type: 'pie', id: 'pie', radius: '30%', center: ['50%', '25%'], emphasis: { focus: 'self' }, label: { formatter: '{b}: {@2012} ({d}%)' },
          encode: { itemName: 'product', value: a[1], tooltip: a[1] }
        };
        brr.push(obj1, obj3, obj2);
        setpieChartData(arr);
        setseries(brr);
        setlegend(crr);
      }
    } else if (value1 === '2') {
      const data = await mPlateDisplaySubAccountLevel({ subAccountId: subAccountInfo.id, beginDate: value2[0], endDate: value2[1] });
      if (validateResponse(data)) {
        const plateList = _.get(data, 'data.data.platePositionList', []);
        const getHeadData = _.head(plateList) || {};
        const getTimeList = _.get(data, 'data.data.timeList', [])
        let a = ['product', ...getTimeList]
        let arr = [a];
        let brr = [];
        let crr = [];

        Object.keys(getHeadData).map((n, i) => {
          crr[i] = { name: n };
          arr[i + 1] = [n];
          brr.push({ name: n, type: 'line', symbol: 'none', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } });
        });

        plateList.map((item, index) => {
          Object.keys(item).map((k, kIndex) => {
            arr[kIndex + 1].push(item[k]);
          })
        })

        let obj2 = {
          type: 'pie', id: 'pie', radius: '30%', center: ['50%', '25%'], emphasis: { focus: 'self' }, label: { formatter: '{b}: {@2012} ({d}%)' },
          encode: { itemName: 'product', value: a[1], tooltip: a[1] }
        };
        brr.push(obj2);
        setpieChartData(arr);
        setseries(brr);
        setlegend(crr);
      }
    } else if (value1 === '3') {
      const data = await mStocksDisplayProductLevel({ subAccountId: subAccountInfo.id, beginDate: value2[0], endDate: value2[1] });
      if (validateResponse(data)) {
        const plateList = _.get(data, 'data.data.platePositionList', []);
        const getHeadData = _.head(plateList) || {};
        const getTimeList = _.get(data, 'data.data.timeList', [])
        let a = ['product', ...getTimeList]
        let arr = [a];
        let brr = [];
        let crr = [];

        Object.keys(getHeadData).map((n, i) => {
          crr[i] = { name: n };
          arr[i + 1] = [n];
          brr.push({ name: n, type: 'line', symbol: 'none', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } });
        });
        plateList.map((item, index) => {
          Object.keys(item).map((k, kIndex) => {
            arr[kIndex + 1].push(item[k]);
          })
        })
        let obj2 = {
          type: 'pie', id: 'pie', radius: '30%', center: ['50%', '25%'], emphasis: { focus: 'self' }, label: { formatter: '{b}: {@2012} ({d}%)' },
          encode: { itemName: 'product', value: a[1], tooltip: a[1] }
        };
        brr.push(obj2);
        setpieChartData(arr);
        setseries(brr);
        setlegend(crr);
      }
    }

    setTabKey(value1);
  }

  async function _checkLastTraderTime() {
    let holiRes = await isAllStockHoliday({
      beginDate: TODAY_TIME[0],
      endDate: TODAY_TIME[1]
    })

    let getDate = TODAY_TIME;
    if (_.get(holiRes, 'code', '') === '200') {
      if (_.get(holiRes, 'data.isAllHoliday') === true) {
        const getLastTradeDate = _.get(holiRes, 'data.preTradeDay', null)
        if (getLastTradeDate) {
          getDate = [getLastTradeDate, getLastTradeDate]
        }
        setTradeDate(false);
      }
    }
    let tempTime = time;
    tempTime['2'] = getDate;
    tempTime['3'] = getDate;
    settime(tempTime)
    //预先加载部分数据
    _subContribution('initDate', getDate, 'prevGet')
    _changeTab('1', getDate, 'prevGet');
  }

  useEffect(() => {
    _initGetData();
    _getShowHistory();
    _intervalTimer2();

    _checkLastTraderTime();

    //console.log('subAccountInfo', subAccountInfo);
    // console.log('allAccount', allAccount);
    const getSubList = _.get(subAccountInfo, 'subAccountList', []);
    const isHasSubAccount = _.size(getSubList) > 0 ? true : false;
    // 基金管理人可查看子账户
    if (mobxStore.userInfo.role === 'fund_manager') {
      _getManagerSubAccount()
    } else {
      setHasSubAccount(getSubList);
    }

    _getSubAsset();

    timer3 = setInterval(() => {
      _getSubAsset();
    }, 60000)

    return () => {
      axiosSource.cancel()
      _clearIntervalFunction();
      return;
    }
  }, [])

  function _clearIntervalFunction() {
    timer1 && clearInterval(timer1)
    timer2 && clearInterval(timer2);
    timer3 && clearInterval(timer3);
  }

  function _intervalTimer2() {
    if (tradeDate) {
      timer2 = setInterval(
        () => {
          _getShowHistory();
        }, 10000)
    }
  }

  function _clearTimer2() {
    timer2 && clearInterval(timer2);
  }

  function _goShares() {
    const getInfo = _.head(_.filter(allAccount, o => o.subAccountId === subAccountInfo.id));
    if (getInfo) {
      props.history.push({
        pathname: '/home/listsDetails',
        state: { subAccountInfo: getInfo }
      });
    }
  }

  async function callback(value) {
    if (value === '1') {
      _cutDateType('1');
    } else if (value === '2') {
      _subContribution(null);
      _clearTimer2();
    } else if (value === '3') {
      _changeTab('1', time['3'])
      _clearTimer2();
    }
  }

  // 切换图表时间
  async function _cutDateType(e) {
    setNewTabs({ '1': e })

    if (e === '1') {
      _intervalTimer2();
    } else {
      _clearTimer2();
    }

    let newDataType = null
    switch (e) {
      case '1':
        newDataType = 'TODAY';
        break;
      case '2':
        newDataType = 'ONE_WEEK';
        break;
      case '3':
        newDataType = 'ONE_MONTH';
        break;
      case '4':
        newDataType = 'THREE_MONTH';
        break;
      case '5':
        newDataType = 'SIX_MONTH';
        break;
      case '6':
        newDataType = 'ONE_YEAR';
        break;
      case '7':
        newDataType = 'THREE_YEAR';
        break;
    }
    setDataType(newDataType)
    _getShowHistory(newDataType)
  }

  return (
    <div className="contentView" id="listsDetails">
      {isMobile &&
        <PageHeader
          title={_.get(subAccountInfo, 'subAccountName', '')}
          onBack={() => {
            props.history.push({
              pathname: '/home/tradersDesk',
            });
          }}
          style={{ backgroundColor: 'white', marginBottom: 10 }}
        ></PageHeader>
      }

      <MAssetsHeader
        role={mobxStore.userInfo.role}
        hasSubAccount={hasSubAccount}
        subAssest={subAssest}
        subAccountInfo={subAccountInfo}
        assetData={assetData}
        isSubListdetail={isSubListdetail}
        goShares={_goShares}
      />
      <br />

      <Card>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="收益率" key="1">
            <EchartsInfo
              chartsData={chartsData}
              chartsXData={chartsXData}
              pointDate={pointDate}
              dataSet={false}
              cutDateType={_cutDateType}
              currentTabs={_.get(newTabs, '1')}
              loading={loadingNew}
            />
          </TabPane>
          <TabPane tab="子账户盈亏分布" key="2">
            <EchartsInfo8
              title='子账户盈亏分布'
              chartsData8={chartsData1}
              chartsXData8={chartsXData1}
              chartsGoBack8={chartsGoBack8}
              nameObj={chart8Name}
              time2={_.get(time, '2', [])}
              loading={loadingNew}
              isUpdate={updateCount}
            />
          </TabPane>
          <TabPane tab="持仓分布" key="3">
            <EchartsInfo7
              pieChartData={pieChartData}
              series={series}
              legend={legend}
              activeTab={tabKey}
              changeTab={_changeTab}
              time3={_.get(time, '3', [])}
              loading={loadingNew}
            />
          </TabPane>
        </Tabs>
      </Card>

      <br />
      <MAccountTable
        role={mobxStore.userInfo.role}
        hasSubAccount={hasSubAccount}
        subAccountInfo={subAccountInfo}
        newProps={props}
        goBack={() => { _getManagerSubAccount() }}
      />
      <br />

      <Mtrader
        subAccountInfo={subAccountInfo}
      />
    </div>
  )
}