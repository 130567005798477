import React, { useState, useEffect } from 'react';
import {
  message,
  Table,
  Card,
  Tag,
  Button,
  Space,
  Modal,
  DatePicker
} from 'antd';
import moment from 'moment';
import { executeTransfer, rejectTransfer } from '@/api/userSystem';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CryptoJS from 'crypto-js'
import _ from 'lodash';

const { RangePicker } = DatePicker;

const RecordTable = ({
  role,
  withdrawalData,
  flowData,
  flowDate,
  loading,
  newProps,

  onRefresh,
  onTimeChange
}) => {
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [isAdmin] = useState(role === 'admin' ? true : false);

  const withColumn = [
    {
      title: '提现编号',
      dataIndex: 'transferNo',
      align: 'left',
      key: 'transferNo',
    },
    {
      title: '提现金额',
      dataIndex: 'amount',
      align: 'left',
      key: 'amount',
    },
    {
      title: '账号名称',
      dataIndex: 'account',
      align: 'left',
      key: 'account',
    },
    {
      title: '转账方式',
      dataIndex: 'transferType',
      align: 'left',
      key: 'transferType',
      render: (text, record) => text === 'alipay' ? '支付宝' : '微信'
    },
    {
      title: '申请时间',
      dataIndex: 'operationDate',
      align: 'left',
      key: 'operationDate',
      render: (text, record) => (
        moment(text).format('YYYY-MM-DD HH:mm')
      )
    },
    {
      title: '通过时间',
      dataIndex: 'approvalDate',
      align: 'left',
      key: 'approvalDate',
      render: (text, record) => {
        if (record.state === 1) {
          return moment(text).format('YYYY-MM-DD HH:mm')
        } else {
          return ''
        }
      }
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'left',
      key: 'state',
      render: (text, record) => {
        if (text === 1) {
          return <Tag color={'success'}>通过</Tag>
        } else if (text === 0) {
          return <Tag color={'processing'}>审批中</Tag>
        } else if (text === 2) {
          return <Tag color={'error'}>拒绝</Tag>
        }
      }
    },

  ];

  const adminOptionColumn = [
    {
      title: '操作',
      align: 'left',
      key: 'option',
      render: (text, record) => <Button type='link' onClick={() => goCheckBalance(record)}>
        明细
      </Button>
    },
  ]

  const flowColumn = [
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      align: 'left',
      key: 'goodsName',
    },
    {
      title: '支付用户账号',
      dataIndex: 'purchaseUserAccount',
      align: 'left',
      key: 'purchaseUserAccount',
    },
    {
      title: '提现金额',
      dataIndex: 'amount',
      align: 'left',
      key: 'amount',
      render: (text, record) => _.round(text, 2)
    },
    {
      title: '日支付金额',
      dataIndex: 'payAmount',
      align: 'left',
      key: 'payAmount',
      render: (text, record) => _.round(text, 2)
    },
    {
      title: '增加后余额',
      dataIndex: 'currentBalance',
      align: 'left',
      key: 'currentBalance',
      render: (text, record) => _.round(text, 2)
    },
    {
      title: '记录时间',
      dataIndex: 'addDate',
      align: 'left',
      key: 'addDate',
      render: (text, record) => (
        moment(text).format('YYYY-MM-DD HH:mm')
      )
    },
  ];

  function goCheckBalance(record) {
    console.log(record)
    newProps.history.push({
      pathname: '/home/CheckBalance',
      state: {
        balance: {
          date: moment(_.get(record, 'operationDate')).format('YYYY-MM-DD'),
          id: _.get(record, 'memberId'),
          name: _.get(record, 'account', '')
        }
      }
    });
  }

  const rowSelection = {
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys) => {
      //console.log('selectedRowKeys changed: ', selectedRowKeys);
      setSelectRowKey(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.state !== 0,
    }),
  };

  async function _executeTransfer() {
    let md5sign = `withdrawalList${_.join(selectRowKey, '')}tamp_service_inner_key`
    let params = {
      withdrawalList: selectRowKey,
      sign: CryptoJS.MD5(_.toLower(md5sign)).toString()
    }

    const res = await executeTransfer(params);
    if (_.get(res, 'code') === '200') {
      message.success(_.get(res, 'message', ''))
      setSelectRowKey([])
      onRefresh();
    } else {
      message.warning(_.get(res, 'message', '操作失败'))
    }
  }
  async function _rejectTransfer() {
    let params = {
      withdrawalList: selectRowKey,
    }

    const res = await rejectTransfer(params);
    if (_.get(res, 'code') === '200') {
      message.success(_.get(res, 'message', ''))
      setSelectRowKey([])
      onRefresh();
    } else {
      message.warning(_.get(res, 'message', '操作失败'))
    }
  }

  const renderExtra = () => {
    const newRangeValue = !flowDate[0] || !flowDate[1] ? null : [moment(flowDate[0]), moment(flowDate[1])];
    return <RangePicker
      value={newRangeValue}
      format="YYYY-MM-DD"
      onCalendarChange={(date, dateStrings) => {
        onTimeChange(dateStrings);
      }}
    />
  }

  return (
    <div style={{ marginTop: 15, padding: 10 }}>
      <Card
        title='提现记录'
        size='small'
        extra={isAdmin ?
          <Space>
            <Button
              type='primary'
              onClick={() => _executeTransfer()}
              disabled={_.size(selectRowKey) === 0 ? true : false}>
              确定
            </Button>
            <Button
              type='primary'
              danger
              onClick={() => {
                Modal.confirm({
                  title: '是否拒绝！',
                  icon: <ExclamationCircleOutlined />,
                  content: '',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => {
                    _rejectTransfer()
                  },
                  onCancel() { },
                });
              }}
              disabled={_.size(selectRowKey) === 0 ? true : false}>
              拒绝
            </Button>
          </Space>
          : null}
      >
        <Table
          rowKey={'id'}
          dataSource={withdrawalData}
          columns={isAdmin ? [...withColumn, ...adminOptionColumn] : withColumn}
          size="middle"
          loading={_.get(loading, 'withdrawal', false)}
          rowSelection={isAdmin ? rowSelection : null}
        />
      </Card>

      {!isAdmin && <div style={{ marginTop: 10 }}>
        <Card
          title='流水记录'
          size='small'
          extra={renderExtra()}
        >
          <Table
            rowKey={'id'}
            dataSource={flowData}
            columns={flowColumn}
            size="middle"
            loading={_.get(loading, 'record', false)}
          />
        </Card>
      </div>}
    </div>
  )
}

export default RecordTable;