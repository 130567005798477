import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Cascader, Spin, message, Typography, Segmented, Divider, Button, Empty } from 'antd';
import { AccountBookOutlined, SyncOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useReactive, useUpdateEffect, useUnmount, useMount } from 'ahooks';
import { showSubAccountHistory, showSingleProductHistory, showBatchProductHistory, showBatchSubAccountHistory } from '@/api/workbench';
import { showSingleProductHistoryVir } from '@/api/details';
import { VSTRING, isValidArray, isValidObj, isValidNumber, labelValues } from '@/utils/utils2';
import {
  COMMON_CHART, PRE_DAY_CN, EXTRA_BAR, INTERVAL_KEYS, EXTRA_AVG, UPDOWN_COLOR, STATIC_RATE_COLOR,
  isFullTimeRange, calRate, calRateFormula, multiplication, arraysMinus,
  renderSlice, handleRetreat, createBaseLine, handleDaliyRates, calMaLine
} from '@/view/common/Components/chartsInfoUtils';
import { EXTRA_COMPARE } from '@/view/common/Components/chartsInfoUtils2';
import { MainSlider, RateDiffModal, ExtraMonthTable } from '@/view/common/Components/main_widget';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');
const GET_TYPE = {
  'account': 'subAccountId', 'product': 'productId', 'virtual': 'productId', 'fund': 'jid'
};
const PAGEKEY_SHORT = { 'A': 'account', 'P': 'product', 'V': 'virtual' };
const dateOptions = [labelValues(['周', 'ONE_WEEK']),
labelValues(['月', 'ONE_MONTH']), labelValues(['季', 'THREE_MONTH']), labelValues(['半年', 'SIX_MONTH']),
labelValues(['年', 'ONE_YEAR']), labelValues(['三年', 'THREE_YEAR']), labelValues(['全部', 'ALL'])
];
let timer = null; let timer2 = null; let timer3 = null;
// 创建option
const createAccountState = (alls, virs) => {
  let virObj = {}; let virOptions = [];
  if (isValidArray(virs)) {
    virs.map(itm => {
      virOptions.push({ 'label': itm.name, 'value': VSTRING + itm.id });
      _.set(virObj, VSTRING + itm.id, itm.name);
    })
  }
  return {
    'productObj': _.assign(_.get(alls, 'productObj'), virObj),
    'accObj': _.get(alls, 'accObj'),
    'accountOptions': _.get(alls, 'accountTwo'),
    'productOptions': _.concat(
      _.get(alls, 'productTwo'),
      [{ label: '虚拟产品', value: 'vir', children: virOptions }]
    ),
  }
};
//V字符的删减与判断
const trimId = (id) => parseInt(_.trim(id, VSTRING));
const isVid = (id) => _.includes(id, VSTRING);
/**
 *  超额分析，功能一部分与产品对比相同，获取数据等；计算出超额与收益率超额统计部分相同；
 */
export default function ExtraCompare(props) {
  const mobxStore = useLocalObservable(() => store);
  const [all_product_acc, set_all_product_acc] = useState(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
  const [accState, setAccState] = useState(createAccountState(all_product_acc, _.get(props, 'virtualProduct', [])));
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]); // slider直接读取的value，中间处理set赋值后不做任何处理，避免处理数据是的差错而改变
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]); // 完整时间数据
  const [fullSeries, setFullSeries] = useState([]);
  const [fullExtra, setFullExtra] = useState({});
  const [rateDatas, setRateDatas] = useState({});
  const [checkboxItem, setCheckBoxItem] = useState([]);
  const extraState = useReactive({
    dateType: 'ALL', getType: '', account: '', product: '', isExtra: false, propId: 0,
    idxSymbol: _.get(props, 'idxPms.symbol'), cloading: false, type: 'ranges',
  });
  const [option, setoption] = useState(EXTRA_COMPARE);
  const [daliyRates, setDaliyRates] = useState({});
  const getPageKeys = _.get(props, 'pageKey', '');

  // tab切换获取当前收益率数据
  // useEffect(() => {
  //   if (_.get(props, 'idxPms.rate', 0)) {
  //     extraState.diffStatus = true;
  //   };
  //   _updatePage();
  // }, [props.active]);
  useMount(() => {
    _updatePage();
  });

  // 更新页面，根据pagekey获取对应的数据
  function _updatePage() {
    let is_get = false; let is_acc_get = false;
    if (props.active === 'EXTRA_COM' && getPageKeys === PAGEKEY_SHORT['A']) {
      _getRates(PAGEKEY_SHORT['A']);
      is_get = true;
      is_acc_get = isValidObj(_.get(accState, 'accObj')) && isValidArray(_.get(accState, 'accountOptions')) ? false : true;
    }
    if (props.active === 'EXTRA_COM' && _.includes(['product', 'virtual'], getPageKeys)) {
      _getRates(PAGEKEY_SHORT['P']);
      is_get = true;
      is_acc_get = isValidObj(_.get(accState, 'productObj')) && isValidArray(_.get(accState, 'productOptions')) ? false : true;
    }
    //账户改为mobx全局获取
    if (is_get && is_acc_get) {
      mobxStore._getProductAccount();
      // 延时获取更新
      timer = setTimeout(() => {
        let newProductAcc = JSON.parse(JSON.stringify(mobxStore.allProductAcc));
        set_all_product_acc(newProductAcc);
        setAccState(createAccountState(newProductAcc, _.get(props, 'virtualProduct', [])));
      }, 2000);
    };
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer2 = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2000);
    }
  };
  /* 
    获取账户/产品收益率; 获取类型： 单一获取，初始加载，批量获取；
      */
  async function _getRates(type = '', gtype = '', id = 0) {
    const isSingle = gtype !== 'batch';
    // promise需要的获取单一产品的方法； 普通产品接口和虚拟产品接口
    const virtualGet = async (id, pms, isVir) => {
      const singleGet = isVir ? await showSingleProductHistoryVir(pms) : await showSingleProductHistory(pms)
      // 返回格式与批量返回相同 {id:{data数据}}
      // 如果是单一获取，则返回数据; 虚拟统一返回虚拟字段
      return new Promise(resolve => resolve(
        isSingle ? _.assign(_.get(singleGet, 'data', {}), { 'is_virtual': isVir })
          : { [id]: _.get(singleGet, 'data', {}), 'is_virtual': isVir }
      ));
    };
    // 处理接口参数； virtualProduct只在虚拟产品页面才有
    const getVirOptions = _.get(props, 'virtualProduct', []);
    const virIds = getVirOptions.map(itm => VSTRING + itm.id);
    const getParamsFromProps = type === PAGEKEY_SHORT['A'] ? _.get(props, 'accountInfo', {}) : _.get(props, 'productInfo', {});
    if (isValidObj(getParamsFromProps)) {
      const idFromProps = _.get(getParamsFromProps, GET_TYPE[type]);
      extraState.propId = isVid(idFromProps) ? trimId(idFromProps) : idFromProps;
      let params = {
        'dateType': extraState.dateType,
        'date': _.get(getParamsFromProps, 'dateToday', TODAY),
        [GET_TYPE[type]]: isVid(idFromProps) ? trimId(idFromProps) : idFromProps
      };
      if (isValidNumber(id)) {
        _.set(params, GET_TYPE[type], id);
      };
      // 判断是否批量
      let isNorBatch = gtype === 'batch' ? true : false;
      let needProGet = false; // 是否使用promise进行获取
      let proAll = []; // promise 数组
      // 当前选项中是否包含虚拟账户;或基金账户
      const virArray = isValidArray(checkboxItem) ? checkboxItem.map(c => {
        const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
        return _.includes(virIds, kId);
      }) : [];
      //三种情况需要使用promise进行获取
      if (isSingle && _.includes(virIds, id)) {
        // id是虚拟产品
        proAll.push(virtualGet(id, params, true));
        needProGet = true;
      } else if (gtype === 'batch' && _.includes(virArray, true)) {
        // 批量（切换时间区间）；并且账户包含虚拟产品
        checkboxItem.map(c => {
          const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
          const is_virtual = _.includes(virIds, kId);
          let singlePms = { ...params };
          if (isValidNumber(kId)) {
            _.set(singlePms, GET_TYPE[type], kId);
          } else if (isVid(kId)) {
            _.set(singlePms, GET_TYPE[type], trimId(kId));
          }
          proAll.push(virtualGet(kId, singlePms, is_virtual));
          needProGet = true;
        });
      } else if (!id && _.includes(virIds, idFromProps) && isSingle) {
        // 首次进入或updatePage，并且当前对比产品id是虚拟产品; 
        //【bug-fix】在single下符合首次加载；今日无数据时，选择其他产品，切换会该方法；重新获取单一虚拟产品；
        proAll.push(virtualGet(idFromProps, params, true));
        needProGet = true;
      }
      // 普通批量时删除虚拟的id
      if (isNorBatch) {
        let newKeyIds = [];
        checkboxItem.map(c => {
          newKeyIds.push(c.keyId)
        });
        _.set(params, GET_TYPE[type], _.join(newKeyIds, ','));
      };
      // 清除或者在已经2个的情况下再次选择，则覆盖掉另外一个产品id进行批量获取；
      if ((gtype === 'clear' || extraState.isExtra) && !isNorBatch) {
        isNorBatch = true;
        _.set(params, GET_TYPE[type], _.join(isValidNumber(id) ? [idFromProps, id] : [idFromProps], ','));
      };

      let res = {};
      const isAccGet = type === PAGEKEY_SHORT['A'] ? true : false;
      if (isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchSubAccountHistory(params) : await showSubAccountHistory(params);
      } else if (!isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchProductHistory(params) : await showSingleProductHistory(params);
      }
      // 批量用单一接口获取数据，将使用response的统一格式进行处理
      if (needProGet) {
        const allResult = await Promise.all(proAll);
        let resData = {}
        allResult.map(result => {
          resData = _.assign(resData, result);
        });
        res = { 'code': '200', 'data': resData };
      };
      if (_.get(res, 'code', '') === '200') {
        handleResData(
          res,
          _.assign(params, { 'id': _.get(params, GET_TYPE[type]), 'isBatch': isNorBatch }),
        );
      }
    }
  };
  // 统一处理返回数据
  function handleResData(res, pms) {
    const is_batch = _.get(pms, 'isBatch');
    let initTemp = is_batch ? {} : _.cloneDeep(rateDatas);
    const getData = _.get(res, 'data', {});
    // console.log('处理', is_batch, getData)
    // 处理批量数据
    if (is_batch && isValidObj(getData)) {
      _.keys(getData).map(ids => {
        const sub_data = _.get(getData, ids, {});
        if (isValidObj(sub_data) && isValidArray(_.get(sub_data, 'timeList'))) {
          // 虚拟vid重新增加v字符
          const rates = handleRateData(sub_data, pms.dateType);
          _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + ids : ids, rates);
        }
      })
    } else {
      // 单独增加的数据进行累加赋值
      if (!isValidObj(getData) || !isValidArray(_.get(getData, 'timeList'))) {
        message.info('暂无数据!');
        return;
      }
      const rates = handleRateData(getData, pms.dateType);
      _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + pms.id : pms.id, rates);
    }
    let temp = sliceUnmatchDates(initTemp, pms.dateType); // 裁剪数据
    // console.log('initTemp', initTemp)
    // 指数计算；使用main图表计算逻辑，计算指数曲线
    const newTimes = _.get(temp, 'times');
    // 设置统一时间及slider
    setTimeArr(newTimes.map((n, i) => i));
    setTimeNameArr(newTimes);
    setRateDatas(temp);
    setUpdate(_.round(update + 0.1, 1));
    extraState.getType = pms.dateType;
  }
  // 对完整对象进行处理
  function sliceUnmatchDates(fullData = {}, dateType = '') {
    let final = {};
    let dataSize = 0;
    // 找到最大的时间数据
    let maxSize = 0; let maxTimeList = [];
    _.keys(fullData).map(ids => {
      const get_size = _.get(fullData, `${ids}.timeSize`, -1);
      if (maxSize === 0 || get_size > maxSize) {
        maxSize = get_size;
        dataSize = _.size(_.get(fullData, `${ids}.pointList`, []));
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      };
    });
    // 再次遍历数据,赋值final
    _.keys(fullData).map(ids => {
      final[ids] = _.cloneDeep(_.get(fullData, ids));
      //小于最大时间数据进行处理
      if (_.get(fullData, `${ids}.timeSize`, 0) !== maxSize) {
        const isValidShort = isValidObj(_.get(fullData, ids)) && 'short_time' in _.get(fullData, ids) ? true : false
        // 短时间轴(含前一交易日);[前一,9-10,9-11...] ; 
        //【bug-fix】:一直使用timeList字段，会在大于2条线对比下，使用修复过的时间进行对比，导致数据前移，对不上；使用short_time初始时间进行计算
        const getShortTime = isValidShort ? _.get(fullData, `${ids}.short_time`, []) : _.get(fullData, `${ids}.timeList`, []);        //【bug-fix】 用长时间轴遍历，短时间轴有数据返回对应自己时间的index值;
        let sindex_array = []; let miss_arr = []; // 记录数组方便找到最小值
        let timeIndex = maxTimeList.map((t, i) => {
          const sindex = getShortTime.indexOf(t);
          // 【bug-fix】twocompare对sindex有-1操作，因为pointNet操作，超额则删掉改方法
          if (sindex > -1) {
            sindex_array.push(sindex);
          } else { //打印时用，保存缺失的日期..
            miss_arr.push({ t, i });
          }
          return sindex; // 生成长轴时间数组，对应短轴的数据位置；例：[-1,7,8,9...,-2,-2,-2,-2]  -1 =前一，-2为未找到
        });
        let daliy_rate = [];
        // 重新计算短轴时间数据，【bug-fix】短轴时间可早于长轴时间，所以需要重新计算
        let shortNets = timeIndex.map(ti => {
          daliy_rate.push(ti > -1 ? _.get(fullData, `${ids}.daliyRate[${ti}]`) : 0);
          return ti > -1 ? _.get(fullData, `${ids}.pointsNet[${ti}]`) : '-';
        });
        let shortStart = _.min(sindex_array) > 0 ? _.get(fullData, `${ids}.pointsNet[${_.min(sindex_array) - 1}]`) : _.get(fullData, `${ids}.preNet`);
        //【bug-fix】 实时点位不对称，preNet实时为0，所以计算错误；该情况默认start=pointNet第一位净值数据
        if (!shortStart && dateType === 'TODAY') {
          shortStart = _.get(fullData, `${ids}.pointsNet[0]`);
        }
        shortNets[0] = shortStart; // 前一交易日 = shortStart的值
        _.set(final, `${ids}.pointList`, calRate(shortNets, shortStart));
        _.set(final, `${ids}.pointRetreat`, handleRetreat(shortNets));
        _.set(final, `${ids}.timeList`, maxTimeList);
        _.set(final, `${ids}.timeIndex`, timeIndex);
        _.set(final, `${ids}.short_time`, getShortTime);// 获取到的初始时间数据
        _.set(final, `${ids}.short_net`, shortNets);
        _.set(final, `${ids}.miss_arr`, miss_arr);
        _.set(final, `${ids}.daliyRate`, daliy_rate);
      }
    });
    final.times = maxTimeList;
    final.dataSize = dataSize;
    return final;
  }
  //处理获取的收益率数据
  function handleRateData(datas = {}, dateType = '') {
    const pointNetList = _.get(datas, 'netValueList', []); // 返回的净值数据
    const preNetVal = _.get(datas, 'preNetValue', 0); // 返回的净值数据
    const getTimeList = _.get(datas, 'timeList', []);
    const isToday = dateType === 'TODAY' ? true : false;
    // 返回的data,object ; 【新】新增虚拟字段
    let dataObj = { 'is_virtual': _.get(datas, 'is_virtual') };
    const retreat = handleRetreat(pointNetList); // 回撤数据
    const dayliRate = pointNetList.map((p, i) => {
      return _.round(parseFloat(calRateFormula(p, i === 0 ? preNetVal : pointNetList[i - 1])) / 100, 6)
    });
    dataObj.retreat = isToday ? retreat : _.concat([0], retreat);
    dataObj.timeList = isToday ? getTimeList : _.concat([PRE_DAY_CN], getTimeList);
    dataObj.preNet = isToday ? 0 : preNetVal;
    dataObj.timeSize = _.size(getTimeList);
    _.set(dataObj, 'daliyRate', isToday ? dayliRate : _.concat([0], dayliRate));
    _.set(dataObj, 'pointsNet', pointNetList);
    _.set(dataObj, 'start_date', getTimeList[0]);
    return dataObj;
  };

  useUnmount(() => {
    timer && clearTimeout(timer);
    timer2 && clearTimeout(timer2);
    timer3 && clearTimeout(timer3);
  });

  useEffect(() => {
    let myChart = props.myChart; let myChart4 = props.myChart; let myChart5 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('extra_compare'));
    myChart4 = echarts.init(document.getElementById('extra_compare_down'));
    myChart5 = echarts.init(document.getElementById('extra_compare_pie'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const pcdtMap = _.get(accState, 'productObj'); const accMap = _.get(accState, 'accObj');
    let newOption = _.cloneDeep(option);
    let newSeires = []; let newExtra = [];
    let legend = []; let count = 0;
    // 当前对比count及rate数据最大=2，所以排序及颜色需要固定
    // console.log(rateDatas)
    if (isValidObj(rateDatas)) {
      _.keys(rateDatas).map(keyId => {
        if (!_.includes(['dataSize', 'times'], keyId)) {
          const getName = getPageKeys === PAGEKEY_SHORT['A'] ? _.get(accMap, keyId) : _.get(pcdtMap, keyId);
          const getDaliyRate = _.get(rateDatas, `${keyId}.daliyRate`, []);
          const isFirst = String(extraState.propId) === keyId ? true : false;
          const rcolor = STATIC_RATE_COLOR[isFirst ? 0 : 1];
          const sobj = createBaseLine(rcolor, {
            'name': getName, keyId,
            'order': isFirst ? 1 : 2,
            'orgData': getDaliyRate.map(n => _.round(n * 100, 4)),
            'data': multiplication(getDaliyRate, 4)
          });
          newSeires.push(sobj);
          legend.push({
            'name': getName,
            'fullName': getName,
            keyId
          });
          count++;
        };
      });
      newSeires = _.orderBy(newSeires, ['order'], ['asc']);
      if (count === 2) {
        const extras = calExtraData(newSeires);
        newExtra = [extras];
        extraState.isExtra = true;
        setFullExtra(extras);
        const extraOption = calDalilyExtra(extras.orgData, _.get(rateDatas, 'times', []));
        myChart4.setOption(extraOption.option4, true); myChart5.setOption(extraOption.option5, true);
      } else {
        myChart4.setOption({}, true); myChart5.setOption({}, true);
      }
    }
    // 赋值slider的滑动数据
    if (_.last(timeArr)) {
      setSliderValue([0, _.last(timeArr)]);
      setSliderValue2([0, _.last(timeArr)]);
    }
    // 更新checkbox；默认全选
    setCheckBoxItem(legend);

    newOption.xAxis.data = _.get(rateDatas, 'times', []);
    newOption.series = _.concat(newSeires, newExtra);
    setFullSeries(_.concat(newSeires, newExtra));

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize(); myChart4.resize(); myChart5.resize();
    timer3 = setTimeout(() => {
      myChart4.resize(); myChart5.resize();
    }, 300);
  }, [update]);
  //slider滑动调用，更新数据；
  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart4 = props.myChart; let myChart5 = props.myChart;
    myChart = echarts.init(document.getElementById('extra_compare'));
    myChart4 = echarts.init(document.getElementById('extra_compare_down'));
    myChart5 = echarts.init(document.getElementById('extra_compare_pie'));
    // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    const isFullRange = isFullTimeRange(sliderValue, timeArr);
    let newOption = _.cloneDeep(option);
    let newExtra = []; let newTimes = [];
    if (isFullRange) {
      const fullTimes = _.cloneDeep(timeNameArr);
      newOption.series = fullSeries;
      newOption.xAxis.data = fullTimes;
      newExtra = _.get(fullExtra, 'orgData');
      newTimes = fullTimes;
    } else {
      const sliceTimes = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
      let sdata = [];
      fullSeries.map(sir => {
        const keyId = _.get(sir, 'keyId', '');
        const getDaliyRate = _.get(rateDatas, `${keyId}.daliyRate`, []);
        const sliceDaliy = renderSlice(getDaliyRate, sliderValue[0], sliderValue[1]);
        const sliceRate = multiplication(sliceDaliy, 4);
        sdata.push({ ...sir, 'data': sliceRate, 'orgData': sliceDaliy.map(n => _.round(n * 100, 4)) });
      });
      if (extraState.isExtra) {
        const extras = calExtraData(sdata);
        newExtra = _.get(extras, 'orgData');
        sdata.push(extras);
      };
      newTimes = sliceTimes;
      newOption.series = sdata;
      newOption.xAxis.data = sliceTimes;
    };
    const extraOption = calDalilyExtra(newExtra, newTimes);
    myChart4.setOption(extraOption.option4, true); myChart5.setOption(extraOption.option5, true);
    // console.log(newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize(); myChart4.resize(); myChart5.resize();
  }, [update2]);
  // 计算超额曲线
  function calExtraData(seires) {
    const extraVal = arraysMinus(_.get(seires, '[0].data', []), _.get(seires, '[1].data', []));
    const extraOrg = arraysMinus(_.get(seires, '[0].orgData', []), _.get(seires, '[1].orgData', []));
    const extra = createBaseLine('#ffc845', {
      'name': '产品超额', keyId: 'pro_extra', 'order': 3,
      'data': extraVal, 'orgData': extraOrg
    });
    return extra;
  };
  // 计算超额统计数据及图表计算内容
  function calDalilyExtra(dataSource = [], times = []) {
    let newOption4 = _.cloneDeep(COMMON_CHART.extraBar);
    let newOption5 = extraState.type !== 'ranges' ? _.cloneDeep(EXTRA_BAR) : _.cloneDeep(COMMON_CHART.extraPie);
    const newDaliy = handleDaliyRates(
      dataSource,
      [],
      times,
      tradeDates,
      false, false
    );
    newOption4.series[0].data = _.get(newDaliy, 'bar4');
    newOption4.series[0].name = '超额';
    newOption4.series[1] = createBaseLine('#879494', {
      'name': '日均',
      'data': calMaLine(_.get(newDaliy, 'bar4').map(n => n.value), 5, 'rate')
    });
    newOption4.xAxis.data = times;
    newOption4.series[0].label.show = _.size(times) > 60 ? false : true; // 时间区间过长可以不显示文字
    let final5 = []; let axis5 = []; let val5 = [];
    // 增加周、月饼图功能；
    if (extraState.type !== 'ranges') {
      _.keys(_.get(newDaliy, extraState.type, {})).map(keys => {
        const gval = _.get(newDaliy, `${extraState.type}.${keys}`, 0);
        let colorIdx = _.findIndex(INTERVAL_KEYS, o => gval > _.get(o, 'min') && gval < _.get(o, 'max'));
        let obj5 = {
          'value': gval,
          'itemStyle': { 'color': _.get(UPDOWN_COLOR, colorIdx !== -1 ? INTERVAL_KEYS[colorIdx]['colorPath'] : 'flat') }
        };
        final5.push(obj5);
        axis5.push(keys);
        val5.push(gval);
      });
      newOption5.series[0].data = final5;
      newOption5.series[1] = createBaseLine('#879494', {
        'name': EXTRA_AVG[extraState.type][0] + '均',
        'data': calMaLine(val5, EXTRA_AVG[extraState.type][1], 'rate')
      });
      newOption5.xAxis.data = axis5;
      newOption5.series[0].label.show = _.size(final5) > 25 ? false : true; // 时间区间过长可以不显示文字
    } else {
      newOption5.series[0].data = _.get(newDaliy, 'pies');
    };
    setDaliyRates(newDaliy);
    return { 'option4': newOption4, 'option5': newOption5 };
  }

  function updateThisChart() {
    setUpdate2(_.round(update2 + 0.1, 1));
  }
  // slider完成后
  function onSliderFinish(v) {
    setSliderValue(v);
    setSliderValue2(v);
    updateThisChart();
  };
  // type: account/product/virtual
  function accountChange(type, v) {
    // product state字段不同，其他相同
    extraState[type] = v;
    _getRates(type, 'single', type === PAGEKEY_SHORT['A'] ? v : _.last(v));
  };
  // console.log(checkboxItem)
  const charts_style = { width: '100%', height: 385 };
  const selectBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', paddingLeft: 4, paddingRight: 4 };
  const cascaderProps = { style: { width: 355 }, size: 'small', bordered: false };
  const accountVal = { 'account': extraState.account, 'product': extraState.product, 'virtual': extraState.virtual }
  const nameInfos = getPageKeys === PAGEKEY_SHORT['A'] ? _.get(_.get(accState, 'accObj'), extraState.propId) : _.get(_.get(accState, 'productObj'), extraState.propId);
  return (
    <>
      <Row>
        <Col span={24}>
          <Space>
            <Segmented size='small'
              options={dateOptions}
              value={extraState.dateType}
              onChange={(v) => {
                extraState.dateType = v;
                if (_.size(checkboxItem) >= 1) { // 超过
                  _getRates(getPageKeys, 'batch');
                } else { //【bug-fix】 bt无今日数据，故checkitem=0,切换回来后需要重新更新数据
                  _updatePage();
                }
              }}
            />
            <div style={{ ...selectBarStyle }}>
              <Space size='small'>
                <AccountBookOutlined />
                <Text>{nameInfos}</Text>
              </Space>
            </div>
            <Divider type='vertical' />
            <div style={selectBarStyle}>
              <AccountBookOutlined />
              {getPageKeys === PAGEKEY_SHORT['A'] ? <Cascader
                {...cascaderProps}
                value={_.get(accountVal, getPageKeys, '')}
                options={_.get(accState, 'accountOptions')}
                onChange={(v) => accountChange('account', v)}
              /> : <Cascader  {...cascaderProps}
                value={_.get(accountVal, getPageKeys, '')}
                options={_.get(accState, 'productOptions')}
                onChange={(v) => accountChange(getPageKeys, v)}
              />}
            </div>
            <Button icon={<SyncOutlined />} type='text' size='small'
              onClick={() => {
                extraState.isExtra = false;
                setFullExtra({});
                setDaliyRates({});
                _getRates(getPageKeys, 'clear');
              }}
            />
          </Space>
        </Col>
      </Row >

      <Spin spinning={extraState.cloading}>
        <div style={{ display: 'flex' }}>
          <div id="extra_compare" style={charts_style} />
        </div>
      </Spin>

      <MainSlider
        isNotToday={extraState.getType !== 'TODAY' ? true : false}
        isFull={isFullTimeRange(sliderValue, timeArr)}
        timeNameArray={timeNameArr}
        timeArray={timeArr}
        empComp={<div style={{ height: 32 }}></div>}
        value={sliderValue}
        svalue={sliderValue2}
        onSliderChange={(v) => setSliderValue2(v)}
        onSliderAfterChange={(v) => onSliderFinish(v)}
        onReset={() => onSliderFinish([0, _.last(timeArr)])}
      />

      <div style={{ display: extraState.isExtra ? 'flex' : 'none', marginTop: 25 }}>
        <div id="extra_compare_down" style={{ width: '100%', height: 350 }} />
      </div>
      <div style={{ display: extraState.isExtra ? 'flex' : 'none', marginBottom: 15 }}>
        <div id="extra_compare_pie" style={{ width: '100%', height: 250 }} />
      </div>

      {!extraState.isExtra && <div style={{ height: 550, paddingTop: 68 }}>
        <Empty />
      </div>}

      <ExtraMonthTable
        isShow
        noExtra
        extras={daliyRates}
        onTypeChange={(key, v) => {
          extraState[key] = v;
          updateThisChart();
        }}
      />

      {
        getPageKeys !== PAGEKEY_SHORT['A'] && <RateDiffModal
          isShow={extraState.diffShow}
          onCancel={() => extraState.diffShow = false}
          onConfirm={(value) => {
            extraState.diffRate = _.get(value, 'diff') / 100;
            extraState.diffStatus = !extraState.diffStatus;
            _getRates('product', 'batch');
            extraState.diffShow = false
          }}
        />
      }
    </>
  )
}