import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, Modal, message, Typography, Image, Space } from 'antd';
import { CheckSquareOutlined, RedoOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import { getCaptcha } from '@/api/auto';
import UUID from 'uuidjs';
import _ from 'lodash';

const { Text } = Typography;

export default function PhoneVerify(props) {
    const [loading, setloading] = useState(false);
    const [disableMsgBtn, setDisableMsgBtn] = useState(false);
    const [disableTime, setDisableTime] = useState(60);
    const interVal = useRef(null);
    const [form] = Form.useForm();
    const [captchaVal, setCaptchaVal] = useState(null);

    useEffect(() => {
        interVal.current = {
            time: 60,
            index: null,
        }
        return () => {
            clearInterval(interVal.current.index);
        }
    }, []);

    useUpdateEffect(() => {
        if (props.visible) {
            _getCaptcha();
        }
        return () => { };
    }, [props.vCount]);

    // 获取图片验证码
    async function _getCaptcha() {
        const uuid = UUID.generate();
        // const res = await getCaptcha(uuid);
        setCaptchaVal(uuid);
    }

    async function _getSmsCode() {
        if (disableMsgBtn) {
            return message.warning('您点击的太快了！');
        }
        const phone = form.getFieldValue('phone');
        const captcha = form.getFieldValue('captcha');
        if (!captcha) {
            return message.error('请输入图片验证码！');
        }
        setloading(true);
        let params = {
            phone: phone,
            captchaToken: captchaVal,
            captcha: captcha,
        }
        const data = await props.getCode(params);
        setloading(false);
        if (data.code === '200') {
            message.success(data.message);
            setDisableMsgBtn(true);
            runDisableTime();
        } else {
            message.error(data.message)
        }
    }
    function runDisableTime() {
        interVal.current.index = setInterval(() => {
            if (interVal.current.time <= 1) {
                clearInterval(interVal.current.index);
                setDisableMsgBtn(false);
                interVal.current.time = 60;
                setDisableTime(60);
            }
            interVal.current.time -= 1;
            setDisableTime(t => t - 1);
        }, 1000);
    }

    function handleCancel() {
        props.phoneHandleCancel();
        form.resetFields();
    }
    async function onFinish(values) {
        props.gobackValues(values);
    }

    const confirmBtn = _.get(props, 'pageKey', '') === 'paysetting' ? '确定' : '确认基金经理本人'

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={handleCancel}
                centered
                footer={null}
            >
                <div style={{ marginBottom: 22 }}><Text strong>验证手机号</Text></div>
                <Form
                    form={form}
                    initialValues={{ phone: props.userPhone ? props.userPhone : '' }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label=""
                        name='phone'
                        rules={[
                            { required: true, message: '请输入手机号！', },
                            {
                                validator: (_, value) => {
                                    if (value) {
                                        if ((/^1[3456789]\d{9}$/.test(value))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('请输入正确的手机号！'));
                                    }
                                }
                            }
                        ]}
                    >
                        <Input placeholder="请输入手机号" size="large" disabled={props.userPhone} />
                    </Form.Item>
                    
                    <Form.Item
                        label=""
                        name='captcha'
                        rules={[{ required: true, message: '请输入图片验证码！', }]}
                    >
                        <Space>
                            <Input placeholder="图片验证码" size="large" autoComplete={'off'} />
                            <Image width={180} height={38} src={global.verifyUrl + captchaVal} preview={false} />
                            <Button icon={<RedoOutlined />} type='text' onClick={() => _getCaptcha()} />
                        </Space>
                    </Form.Item>

                    <Form.Item
                        label=""
                        name='phoneCode'
                        rules={[{ required: true, message: '请输入验证码！', }]}
                    >
                        <Input.Search
                            placeholder="验证码"
                            enterButton={disableMsgBtn ? disableTime + '秒' : '获取验证码'}
                            size="large"
                            loading={loading}
                            onSearch={_getSmsCode}
                            autoComplete={'off'}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button icon={<CheckSquareOutlined />} type="primary" htmlType="submit" size="large" style={{ width: '100%', marginTop: 30 }}>
                            {confirmBtn}
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
        </>
    )
}