import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, List, message, DatePicker, Select, Input, Divider, Space } from 'antd';
import {
  mShowTraderTrends, mShowTraderPerformance
} from '@/api/workbench';
import moment from 'moment';
import ExportJsonExcel from 'js-export-excel';
import axios from 'axios';
import _ from 'lodash';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const Mtrader = ({ subAccountInfo }) => {
  const [tarderPerform, settarderPerform] = useState([]);
  const [tradeList, setTradeList] = useState([]);
  const [tradeForm, setTradeForm] = useState({
    date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  });

  async function _showTrader() {
    const data = await mShowTraderPerformance(subAccountInfo.id);
    if (data.code === '200') {
      if (data.data !== null) {
        settarderPerform(data.data)
      }
    }
  }


  useEffect(() => {
    _showTrader();

    return () => { return; }
  }, [])

  function handleFormChange(val, key) {
    let tempForm = _.cloneDeep(tradeForm);
    tempForm[key] = val;
    setTradeForm(tempForm);
    showTrends(tempForm)
  }

  async function showTrends(form) {
    let params = {
      beginDate: _.get(form, 'date[0]', ''),
      endDate: _.get(form, 'date[1]', ''),
      tradeDirection: _.get(form, 'tradeDirection', ''),
      traderName: _.get(form, 'traderName', ''),
      subAccountId: subAccountInfo.id
    }

    const data = await mShowTraderTrends(params);
    if (data.code === '200') {
      setTradeList(data.data);
    } else {
      message.error(data.message);
    }
  }

  async function getLoad() {
    axios.get(global.api + '/traderManager/exportTraderPerformance?subAccountId=' + subAccountInfo.id, { responseType: "blob" }).then(res => {
      if (res.type !== 'application/json') {
        const url = window.URL.createObjectURL(new Blob([res],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = '文件.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        message.error("文件下载失败");
        return;
      }
    }).catch(err => {
      console.log(err);
    })
  }

  function exportToExcel() {
    var data = [];
    tradeList.map(item => {
      var a = {};
      var arr = item.split('，');
      a.name = arr[0].substr(0, arr[0].indexOf('委'));
      // a.name = arr[0].substr(0,arr[0].indexOf('买')) || arr[0].substr(0,arr[0].indexOf('卖'));
      a.direction = arr[0].slice((arr[0].indexOf('了') - 2), arr[0].indexOf('了'));
      a.num = arr[0].slice(arr[0].indexOf('了') + 1, arr[0].indexOf('股') + 1);
      a.ming = arr[0].slice(arr[0].indexOf('股') + 1, arr[0].indexOf('（'));
      a.code = arr[0].slice(arr[0].indexOf('股') + 1).replace(/[^\d]/g, ' ');
      a.entrustPrice = arr[1].slice(arr[1].indexOf(':') + 1);
      a.clinchPrice = arr[3].slice(arr[3].indexOf(':') + 1);
      a.clinchAmount = arr[4].slice(arr[4].indexOf(':') + 1);
      if (arr[0].indexOf('卖出') !== -1) {
        a.realRate = arr[5].substr(arr[5].indexOf('：') + 1);
        a.stockRate = arr[6].substr(arr[6].indexOf('：') + 1);
        a.excessRate = arr[7].substr(arr[7].indexOf('：') + 1);
        a.productName = arr[8].substr(0, arr[8].indexOf('\t') + 1);
        a.securitiesAccountName = arr[8].substr(arr[8].indexOf('\t') + 1, arr[8].indexOf('\t'));
        a.time = arr[8].substr(arr[8].lastIndexOf('\t') + 1);
      } else {
        a.realRate = null;
        a.stockRate = null;
        a.excessRate = null;
        a.productName = arr[5].substr(0, arr[5].indexOf('\t') + 1);
        a.securitiesAccountName = arr[5].substr(arr[5].indexOf('\t') + 1, arr[5].indexOf('\t'));
        a.time = arr[5].substr(arr[5].lastIndexOf('\t') + 1);
      }
      // a.ming = arr[0].slice(arr[0].indexOf('手')+1).replace(/[0-9]/g, '');
      data.push(a);
      return item;
    })
    var option = {};
    let dataTable = [];
    if (data) {
      for (let i in data) {
        if (data) {
          let obj = {
            '姓名': data[i].name,
            '方向': data[i].direction,
            '数量': data[i].num,
            '名称': data[i].ming,
            '代码': data[i].code,
            '委托价': data[i].entrustPrice,
            '成交均价': data[i].clinchPrice,
            '成交金额': data[i].clinchAmount,
            '日内实际收益率': data[i].realRate,
            '日内个股收益率': data[i].stockRate,
            '超额收益率': data[i].excessRate,
            '产品名称': data[i].productName,
            '证券账户名称': data[i].securitiesAccountName,
            '时间': data[i].time
          }
          dataTable.push(obj);
        }
      }
    }
    option.fileName = '交易员动态'  //导出的Excel文件名
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: ['姓名', '方向', '数量', '名称', '代码', '委托价', '成交均价', '成交金额', '日内实际收益率', '日内个股收益率', '超额收益率', '产品名称', '证券账户名称', '时间'],
        sheetHeader: ['姓名', '方向', '数量', '名称', '代码', '委托价', '成交均价', '成交金额', '日内实际收益率', '日内个股收益率', '超额收益率', '产品名称', '证券账户名称', '时间'],
      }
    ]
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          style={{ minHeight: 745 }}
          title="交易员动态"
        >
          <div>
            <Row justify="start" align="middle" gutter={[8, 8]}>
              <Col sm={24} md={12} xl={10} xxl={8}>
                <Space direction='row' align='center'>
                  <span style={{ marginRight: 10, marginLeft: 20 }}>选择日期:</span>
                  <RangePicker
                    format="YYYY-MM-DD"
                    value={[moment(_.get(tradeForm, 'date[0]')), moment(_.get(tradeForm, 'date[1]'))]}
                    onCalendarChange={(date, dateStrings) => handleFormChange(dateStrings, 'date')}
                  />
                </Space>
              </Col>
              <Col sm={24} md={12} xl={4} xxl={4}>
                <Select
                  style={{ width: 110, marginRight: 10 }}
                  placeholder='方向'
                  onChange={(v) => handleFormChange(v, 'tradeDirection')}
                >
                  <Option value="">全部</Option>
                  <Option value="BUY">买入</Option>
                  <Option value="SELL">卖出</Option>
                </Select>
              </Col>
              <Col sm={24} md={12} xl={6} xxl={8}>
                <Space direction='row' align='center'>
                  <span style={{ marginRight: 10, marginTop: 10 }}>交易员:</span>
                  <Search placeholder="请输入交易员" allowClear enterButton style={{ width: 185, marginTop: 10 }} onSearch={value => {
                    handleFormChange(value, 'traderName')
                  }} />
                </Space>
              </Col>
              <Col sm={24} md={12} xl={4} xxl={4}>
                <Button type='primary' style={{ marginLeft: 10, marginTop: 10 }} onClick={exportToExcel}>导出</Button>
              </Col>
            </Row>
          </div>
          <Divider />
          <List
            itemLayout="horizontal"
            dataSource={tradeList}
            pagination={{
              defaultPageSize: 10,
              hideOnSinglePage: true
            }}
            renderItem={item => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
        </Card>
      </Col>
      <Col span={24} style={{ marginTop: 12 }}>
        <Card title="交易员业绩" extra={
          <Button type='primary' style={{ marginTop: 10, marginLeft: 10 }} onClick={getLoad}>导出</Button>
        }
        >
          {
            tarderPerform.length !== 0 &&
            <List
              itemLayout="horizontal"
              dataSource={tarderPerform}
              pagination={{
                pageSize: 3,
              }}
              renderItem={item => (
                <List.Item>{item}</List.Item>
              )}
            />
          }
        </Card>
      </Col>

    </Row>
  )
}

export default Mtrader