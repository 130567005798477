import axios from '@/utils/axios';


/**
 *  股票-查询股票池及股票查看
 */
export function getStockPoolStocks(data) {
    return axios.post(global.api + '/stock/showStockPoolStocks', data);
}
/**
 *  股票-获取股票首页列表
 */
export function getStockScreeningList(data) {
    return axios.post(global.api + '/stock/showStockScreeningStocks', data);
}
/**
 *  股票-删除股票池
 */
export function delStockPoollist(data) {
    return axios.post(global.api + '/stock/deleteStockPool', data);
}
/**
 *  股票-删除股票池下的单个股票
 */
export function delStockPoolStocks(data) {
    return axios.post(global.api + '/stock/deleteStockPoolStocks', data);
}
/**
 *  股票-添加股票池
 */
export function addStockPool(data) {
    return axios.post(global.api + '/stock/addStockPool', data);
}
/**
 *  股票-获取企业性质
 */
export function getCompanyAttribute(data) {
    return axios.get(global.api + '/stock/getCompanyAttribute', data);
}
/**
 *  股票-获取板块列表
 */
export function getPlate(data) {
    return axios.get(global.api + '/stock/getPlate', data);
}
/**
 *  股票-筛选股票
 */
export function filterStock(data) {
    return axios.post(global.api + '/stock/filter', data);
}
/**
 *  股票-添加股票至指定股票池
 */
export function addStocks(data) {
    return axios.post(global.api + '/stock/addStocks', data);
}
/**
 *  股票-查询股票池黑名单
 */
export function showStockPoolBlack(data) {
    return axios.post(global.api + '/stock/showStockPoolBlack', data);
}
/**
 *  股票-更新股票池黑名单
 */
export function updStockPoolBlack(data) {
    return axios.post(global.api + '/stock/updStockPoolBlack', data);
}
/**
 *  股票-股票交易-买入
 */
export function buy(data) {
    return axios.post(global.api + '/trade/buy', data);
}
/**
 *  股票-股票交易-卖出
 */
export function sell(data) {
    return axios.post(global.api + '/trade/sell', data);
}
/**
 *  股票-获取实时股票行情
 */
export function market(data) {
    return axios.get(global.api + '/stock/market', { params: data });
}
/**
 *  股票-查询股票信息
 */
export function showStockInfo(data) {
    return axios.post(global.api + '/stock/showStockInfo', data);
}
/**
 *  股票-获取订单列表
 */
export function showList(data) {
    return axios.post(global.api + '/trade/showList', data);
}
export function getAllTrade(data) {
    return axios.post(global.api + '/product/getAllTrade', data);
}
export function showExcessRateHistory(data) {
    return axios.post(global.api + '/trade/showExcessRateHistory', data);
}
export function showSpecificExcessRateHistory(data) {
    return axios.post(global.api + '/trade/showSpecificExcessRateHistory', data);
}
export function getLatestTrade(params) {
    return axios.post(global.api + '/trade/getLatestTrade', params);
}
/**
 *  股票-撤单
 */
export function cancel(data) {
    return axios.get(global.api + '/trade/cancel', { params: data });
}
/**
 *  股票-基金经理添加股票池
 */
export function addFundManagerStockPool(data) {
    return axios.post(global.api + '/fundManager/addFundManagerStockPool', data);
}
/**
 *  股票-获取当前子账户所有可交易股票信息
 */
export function getCurrentStocks(data) {
    return axios.get(global.api + '/stock/getCurrentStocks', { params: data });
}
/**
 *  股票-删除股票池
 */
export function deleteStockPool(data) {
    return axios.post(global.api + '/stock/deleteStockPool', data);
}
// 查看指数成分股 20220322
export function showIndexStocks(params) {
    return axios.post(global.api + '/stock/showIndexStocks', params);
}
// 查看会员股票池
export function showMemberStockPools() {
    return axios.get(global.api + '/stock/showMemberStockPools');
}

/**
 *  股票-获取当前子账户所有可卖出股票信息
 */
export function getCurrentPositionStocks(data) {
    return axios.get(global.api + '/stock/getCurrentPositionStocks', { params: data });
}
/**
 *  股票-获取实时k线
 */
export function kline(data) {
    return axios.get(global.api + '/stock/kLine', { params: data });
}
/**
 *  股票-查询ETF
 */
export function showETF(data) {
    return axios.post(global.api + '/stock/showETF', data);
}
// 查询
export function searchIndexStocks(params) {
    return axios.post(global.api + '/stock/searchIndexStocks', params);
}
//流入流出统计表
export function transaction(params) {
    return axios.post(global.api + '/stock/transaction', params);
}

//___________________________________________________________________________________

/**
 *  期货-得到特定期货订单数据
 */
export function getInstrumentOrder(data) {
    return axios.post(global.api + '/javaCtpCalFund/getInstrumentOrder', data);
}
/**
 *  期货 - 得到特定期货持仓数据
 */
export function getInstrumentAsset(data) {
    return axios.post(global.api + '/javaCtpCalFund/getInstrumentAsset', data);
}
/**
 *  期货-开仓
 */
export function opens(data) {
    return axios.post(global.api + '/ctpTradeCal/open', data);
}
/**
 *  期货-平仓
 */
export function closes(data) {
    return axios.post(global.api + '/ctpTradeCal/close', data);
}
/**
 *  期货-得到子账号的计算信息
 */
export function getSubAccountCal(data) {
    return axios.post(global.api + '/javaCtpCalFund/getSubAccountCal', data);
}
/**
 *  期货-得到行情
 */
export function getTick(data) {
    return axios.post(global.api + '/ctpMarketCal/getTick', data);
}
/**
 *  期货-查询所有用户下子账户总资产
 */
export function showFutureAssetsAndRevenue(data) {
    return axios.get(global.api + '/future/trade/showAssetsAndRevenue', { params: data });
}
/**
 *  期货-首页查询所有子账号信息
 */
export function showAllSubAccountCal(data) {
    return axios.get(global.api + '/future/trade/showAllSubAccountCal', { params: data });
}
/**
 *  期货-得到子账号期货池
 */
export function showFuturePool(data) {
    return axios.post(global.api + '/ctpTradeCal/showFuturePool', data);
}
/**
 *  股票-获取托管配置信息
 */
export function getAutoTradingRule(data) {
    return axios.get(global.api + '/subAccount/getAutoTradingRule', { params: data });
}
/**
 *  股票-取消自动托管
 */
export function cancelAutoTrading(data) {
    return axios.get(global.api + '/subAccount/cancelAutoTrading', { params: data });
}
/**
 *  股票-设置托管配置信息
 */
export function setAutoTradingRule(data) {
    return axios.post(global.api + '/subAccount/setAutoTradingRule', data);
}
/**
 *  期货-返回账号收益率
 */
export function calAccoutPtofitRate(data) {
    return axios.post(global.api + '/javaCtpCalFund/calAccoutPtofitRate', data);
}
/**
 *  期货-返回账号资金使用率
 */
export function calCapitalRate(data) {
    return axios.post(global.api + '/javaCtpCalFund/calCapitalRate', data);
}
/**
 *  期货-返回账号交易收益率
 */
export function calTradeProfitRate(data) {
    return axios.post(global.api + '/javaCtpCalFund/calTradeProfitRate', data);
}
/**
 *  期货-统计模块
 */
export function calOrderAnalysis(data) {
    return axios.post(global.api + '/javaCtpCalFund/calOrderAnalysis', data);
}
// k线
export function getKLineDetail(data) {
    return axios.post(global.api + '/stock/kLineDetail', data);
}