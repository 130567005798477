import React from 'react';
import { Typography, Tag, Row, Col, Space, Select, Button, Checkbox, DatePicker, Switch, Divider } from 'antd';
import { LeftOutlined, RightOutlined, CloseOutlined, BranchesOutlined, MoreOutlined } from '@ant-design/icons';
import { CHECK_TAG_ARRAY, SORT_BTN } from './chartsInfoUtils';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { CheckableTag } = Tag;
const { Option } = Select;

const MainBar = ({
  isRealtime = false,
  isVisitor = false,
  isDiffRate = false,
  pageKeys = '',
  pmsDateValue = '',
  dateType = '',
  validRanges = [],
  rateObj = {},
  switchs = [],
  checkboxItem = [],
  checkboxValues = [],
  configStatus = false,

  onSingleDateChange,
  tagChange,
  subGroupChange,
  subValueChange,
  selectChange,
  configChange,
}) => {
  const isListPage = pageKeys === 'lists' ? true : false;
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 16, paddingLeft: 8, paddingRight: 8 };
  const renderPickerDate = pmsDateValue !== '' ? moment(pmsDateValue) : null;
  const isUpdownOrTwoDay = _.includes(switchs, 'updown') || _.includes(switchs, 'two_day') ? true : false;
  const disabledDate = (current) => { //getValidArray中未出现的时间数据不显示，区第一位和最后一位作为区间；最后一位获取时处理为当天
    if (_.size(validRanges) === 0 || !_.last(validRanges) || !_.head(validRanges)) {
      return false;
    }
    const tooLate = current.diff(_.last(validRanges), 'days') > 0;
    const tooEarly = current.diff(_.head(validRanges), 'days') < 0;
    return !!tooEarly || !!tooLate;
  };
  const validIdx = validRanges.indexOf(pmsDateValue); // 当前日期在数组中的index
  const disLeft = validIdx <= 0 ? true : false; // 到数组最左侧禁止点击‘前一日’按钮
  const disRight = validIdx === (_.size(validRanges) - 1) && validIdx !== -1 ? true : false; // 到数组最右侧禁止点击‘后一日’按钮
  const PRO_CHECKS = _.filter(CHECK_TAG_ARRAY, o => _.includes(['updown', 'two_day', 'updown_plus', 'two_day_plus'], o.key));
  const newCheckTag = isListPage ? CHECK_TAG_ARRAY : PRO_CHECKS;
  // 配置数据关闭按钮
  const configCloseBtn = <div className='config-close-btn' >
    <Space size='small'>
      <div className='open' onClick={() => configChange('value-open')}>
        <Text strong style={{ color: 'white' }}>配置</Text>
      </div>
      <div className='close' onClick={() => configChange('value-close')}>
        <CloseOutlined style={{ color: 'white' }} />
      </div>
    </Space>
  </div>

  return <>
    <Row style={{ textAlign: 'left', height: 35, marginBottom: 6 }} justify='space-between'>
      <Col span={12}>
        <Space size='small'>
          {isRealtime && isListPage && <Button
            size='small'
            icon={<LeftOutlined />}
            type='text'
            disabled={disLeft}
            onClick={() => {
              onSingleDateChange(validRanges[validIdx - 1])
            }}
          />}
          {isRealtime && !isListPage && <Text strong style={{ fontSize: 16, marginLeft: 15 }}>
            {pmsDateValue}
          </Text>}
          {isRealtime && isListPage && <DatePicker
            size='small'
            disabledDate={disabledDate}
            value={renderPickerDate}
            onChange={(date, dateString) => {
              onSingleDateChange(dateString);
            }}
          />}
          {isRealtime && isListPage && <Button
            size='small'
            icon={<RightOutlined />}
            type='text'
            disabled={disRight}
            onClick={() => {
              onSingleDateChange(validRanges[validIdx + 1])
            }}
          />}
        </Space>
        {isRealtime && <Divider type='vertical' style={{ marginLeft: 12, marginRight: 12 }} />}
        <Space size='small'>
          <Text type='secondary'>收益率</Text>
          <Text strong style={{ color: '#5470c6' }}>{_.get(rateObj, 'rate', '')}</Text>
          <Text type='secondary'>指数</Text>
          <Text strong style={{ color: '#B5495B' }}>{_.get(rateObj, 'idx', '')}</Text>
          <Text type='secondary'>超额</Text>
          <Text strong style={{ color: '#ffa600' }}>{_.get(rateObj, 'extra', '')}</Text>
        </Space>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Space size='small'>
          {!isVisitor && !isListPage && <Button icon={<BranchesOutlined />} size='small'
            type={isDiffRate ? 'primary' : 'default'}
            disabled={configStatus}
            onClick={() => configChange('diff-modal')}>
            实测差异
          </Button>}
          {dateType === 'ALL' && pageKeys !== 'virtual' && <>
            {!isVisitor && !isListPage && !configStatus && <Button icon={<MoreOutlined />} size='small'
              onClick={() => configChange('modal-show')}>
              配置
            </Button>}
            {configStatus && configCloseBtn}
          </>}
          {isListPage && !isVisitor && <CheckableTag
            checked={_.includes(switchs, 'updown_stock')}
            onChange={(checked) => tagChange(checked, 'updown_stock')}
          >
            {'涨停票'}
          </CheckableTag>}
          {!isVisitor && <div style={{ padding: isListPage ? 6 : 2, background: '#f9f9f9', borderRadius: 4, textAlign: 'left' }}>
            <Select bordered={false} size='small' mode='tags' style={{ width: 300 }} placeholder='收益率'
              onChange={(values) => selectChange(values)}
            >
              {newCheckTag.map(c => <Option key={c.key} value={c.key}>{c.name}</Option>)}
            </Select>
          </div>}
        </Space>
      </Col>
    </Row>
    {_.size(checkboxItem) > 0 && <Row gutter={[8, 8]} style={controlBarStyle}>
      <Col span={24} style={{ paddingTop: 6 }}>
        <Space>
          <Text>快捷方式：</Text>
          {isUpdownOrTwoDay ? SORT_BTN.map((s, i) => {
            let subBtnProps = { size: 'small', type: 'primary', ghost: true };
            if (s.key === 'all' || s.key === 'clear') {
              subBtnProps = { size: 'small', type: s.type };
            }
            return <Button key={'sortBtn' + i} {...subBtnProps} onClick={() => subGroupChange(s.key)}>
              {s.title}
            </Button>
          }) : <></>}
          {isUpdownOrTwoDay ? <Divider type='vertical' /> : <></>}
          <Switch
            checkedChildren="超额"
            unCheckedChildren="超额"
            checked={_.includes(switchs, 'extra_switch')}
            onChange={(checked) => tagChange(checked, 'extra_switch')}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Checkbox.Group
          style={{ width: '100%' }}
          value={checkboxValues}
          onChange={subValueChange}
        >
          <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
            {checkboxItem.map((itm, i) => {
              return <Col span={3} key={i}>
                <Checkbox value={_.get(itm, 'key', '')}>{_.get(itm, 'name')}</Checkbox>
              </Col>
            })}
          </Row>
        </Checkbox.Group>
      </Col>
    </Row>}
  </>
}

export default MainBar;