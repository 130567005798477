import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, InputNumber, message, Radio, AutoComplete } from 'antd';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { getVerifPhoneCode, getUserPhone } from '@/api/auto';
import { addSecuritiesAccount, getAllTraders, isRepeatSub } from '@/api/details';
import { addManagerSubAccount } from '@/api/workbench';
import { useMount, useUnmount } from 'ahooks';
import { validateResponse } from '@/utils/utils';
import PhoneVerify from '@/view/common/PhoneVerify';
import _ from 'lodash';

const index_code_array = [
  { label: '沪深300', value: '000300' },
  { label: '中证1000', value: '000852' },
  { label: '中证500', value: '000905' },
  { label: '上证50', value: '000016' },
]
const { Option } = AutoComplete;

export default function AddProductDetails(props) {
  const mobxStore = useLocalObservable(() => store);
  const [newUserInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)));
  const [visible, setvisible] = useState(false);
  const [phoneVisible, setphoneVisible] = useState(false);
  const [vCount, setvCount] = useState(0);
  const [userPhone, setuserPhone] = useState(null);
  const [traderList, settraderList] = useState([]);
  const [traderOptions, settraderOptions] = useState([]);
  const [isDddTrader, setisDddTrader] = useState(2); // 1 添加交易员 2 不添加
  const [accountIn, setaccountIn] = useState(null);
  const [getTraderId, setgetTraderId] = useState();
  const [form] = Form.useForm();
  let isUnmont = false;

  useMount(() => {
    _getPhone();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  async function _getPhone() {
    //const data = await getUserPhone({ params: { email: userInfo.email } });
    const data = await getUserPhone();
    if (validateResponse(data, isUnmont)) {
      setuserPhone(data.data.phone);
    }
  }

  function handleCancel() {
    setisDddTrader(2);
    settraderList([]);
    settraderOptions([]);
    setvisible(false);
    form.resetFields();
  }
  // 选择证券账户获取交易员
  async function _accountChange(key) {
    let id = props.listData[key].id;
    setaccountIn(props.listData[key]);
    const data = await getAllTraders({ id });
    // console.log('交易员', data)
    if (data.code === '200') {
      settraderList(data.data.result);
      let arr = data.data.result;
      let brr = [];
      if (arr.length !== 0) {
        arr.map(item => {
          let a = { label: item.tradername, value: item.tradername + ' (交易员ID:' + item.traderid + ')' }
          brr.push(a);
          return item;
        })
      }
      settraderOptions(brr);
    }
  }
  async function _getTrader(id) {
    if (id) {
      const data = await getAllTraders({ id });
      if (data.code === '200') {
        settraderList(data.data.result);
        let arr = data.data.result;
        let brr = [];
        if (arr.length !== 0) {
          arr.map(item => {
            let a = { label: item.tradername, value: item.tradername + ' (交易员ID:' + item.traderid + ')' }
            brr.push(a);
            return item;
          })
        }
        settraderOptions(brr);
      }
    }
  }
  // 选择是否添加交易员
  function _addTraderChange(e) {
    setisDddTrader(e.target.value);
  }

  async function handleOk() {
    try {
      const values = await form.validateFields();
      // 先验证手机号
      setphoneVisible(true);
      setvCount(vCount + 1)
    } catch (e) {
      return;
    }
  }

  // 接收验证手机号后返回来的code
  async function _phoneVerify(valuesCode) {
    const values = await form.getFieldValue();
    values.traderId = getTraderId;
    // console.log(values);
    let params = {
      ...values,
      alertLine: 0.01,
      closeLine: 0.01,
      phone: valuesCode.phone,
      code: valuesCode.phoneCode,
      indexCode: values.indexCode,
      // securitiesAccountId: props.listData[values.account].id,
      // sec_availableBalance: accountIn.availableBalance,
      // type: accountIn.type,
      // sec_totalAssets: accountIn.totalAsset
    }
    if (values.isAddTrader === 2) delete params.traderId;

    if (newUserInfo.role !== 'fund_manager') {
      params.alertLine = 0.01;
      params.closeLine = 0.01;
    }

    // return console.log(1111,params);
    if (_.get(props, 'pageKey', '') === 'traderDesk') {
      params.subAccountId = _.get(props, 'detailValue.id', '')
      params.securitiesAccountId = _.get(props, 'detailValue.securitiesAccountId', '')
      params.type = _.get(props, 'detailValue.assetsType', '')

      const data = await addManagerSubAccount(params);
      //console.log(props.pageKey, data);
      if (data.code === '200') {
        message.success('保存成功');
        props.goBack();
        handleCancel();
        setphoneVisible(false);
      } else {
        message.error(data.message);
      }
    } else {
      params.securitiesAccountId = props.listData[values.account].id
      params.subAccountId = props.listData[values.account].id
      params.sec_availableBalance = accountIn.availableBalance
      params.type = accountIn.type

      const data2 = await addSecuritiesAccount(params);
      //console.log('addSecuritiesAccount', data2);
      if (data2.code === '200') {
        message.success('保存成功');
        props.goBack();
        handleCancel();
        setphoneVisible(false);
      } else {
        message.error(data2.message);
      }
    }

  }
  function subTraderId(value) {
    if (value !== '') {
      let arr = value.split(' ');
      setgetTraderId(Number(arr[1].replace(/[^0-9]/g, '')));
    }
  }

  const indexOption = index_code_array.map((n, i) => <Option key={n.value + i} value={n.value}>{n.label}</Option>)
  const isTraderDesk = _.get(props, 'pageKey', '') === 'traderDesk' ? true : false

  return (
    <Observer>
      {() => (
        <>
          {/* <Button
                        type="dashed"
                        className="colorGrey"
                        style={{ width: '100%', marginTop: 20 }}
                        onClick={() => setvisible(true)}
                    >
                        + 添加
                    </Button> */}
          <Button
            style={{ textAlign: 'center' }}
            type="link"
            size='small'
            onClick={() => {
              setvisible(true)
              if (newUserInfo.role === 'trader_manager') {
                const getId = _.get(props, 'detailValue.id', null);
                _getTrader(getId)
              }

              if (props.onShow) {
                props.onShow()
              }
            }}
          >
            + 添加子账户
          </Button>

          <PhoneVerify
            vCount={vCount}
            visible={phoneVisible}
            phoneHandleCancel={() => setphoneVisible(false)}
            gobackValues={_phoneVerify}
            getCode={getVerifPhoneCode}
            userPhone={userPhone}
          />

          <Modal
            title="添加交易账户"
            width={800}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={false}
          >
            <Form
              initialValues={{ alertLine: 0.98, closeLine: 0.899, isAddTrader: 2, autoTrade: false }}
              form={form}
            >

              {isTraderDesk ?
                <Form.Item
                  label="证券账户"
                  name="account"
                >
                  <Input
                    placeholder="请输入子账户名称!"
                    disabled={true}
                    defaultValue={_.get(props, 'detailValue.name', '')}
                  />
                </Form.Item>
                : <Form.Item
                  label="证券账户"
                  name="account"
                  rules={[{ required: true, message: '请选择证券账户!' }]}
                >
                  <Select
                    placeholder="请选择证券账户"
                    onChange={_accountChange}
                  >
                    {
                      props.listData.map((item, key) => {
                        return (
                          <Option key={key} value={key}>
                            {item.customerNumber}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>}

              <Form.Item
                label="子账户名称"
                name="name"
                // rules={[{ required: true, message: '请输入子账户名称!' }]}
                validateTrigger='onBlur'
                rules={[isTraderDesk ? {} : {
                  validator: async (_, value) => {
                    if (value) {

                      const key = form.getFieldValue('account');
                      let id = props.listData[key].id;
                      const data = await isRepeatSub({ name: value, id });
                      if (data.code === '200') {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(data.message));
                    } else {
                      return Promise.reject(new Error('请输入子账户名称'));
                    }
                  }
                }]}
              >
                <Input
                  placeholder="请输入子账户名称!"
                //disabled={!traderList.length}
                />
              </Form.Item>

              <Form.Item
                label="账户基准"
                name='indexCode'
              // rules={[{ required: true, message: '请输入账户基准', }]}
              >
                <Select>
                  {indexOption}
                </Select>
              </Form.Item>

              <Form.Item
                label="是否添加交易员"
                name="isAddTrader"
              >
                <Radio.Group onChange={_addTraderChange}>
                  <Radio value={1}>添加</Radio>
                  <Radio value={2}>不添加</Radio>
                </Radio.Group>
              </Form.Item>

              {
                isDddTrader === 1 &&
                <Form.Item
                  label="请选择交易员"
                  name="traderId"
                  rules={[{ required: true, message: '请选择交易员!' }]}
                >
                  <AutoComplete
                    options={traderOptions}
                    onChange={subTraderId}
                    placeholder="请输入交易员"
                    filterOption={(inputValue, option) =>
                      option.value.indexOf(inputValue) !== -1
                    }
                  />
                </Form.Item>
              }

              <Form.Item
                label="总资产"
                name="sub_totalAssets"
                validateTrigger='onBlur'
                rules={[{
                  validator: (_, value) => {
                    let getBalance

                    if (props.maxBalance) {
                      getBalance = props.maxBalance
                    } else {
                      getBalance = parseInt(accountIn.availableBalance)
                    }

                    if (!value) {
                      return Promise.reject(new Error('请输入总资产！'));
                    } else {
                      if (value > getBalance) {
                        return Promise.reject(new Error('可用资金不足'));
                      }
                      return Promise.resolve();
                    }
                  },
                  required: true,
                }]}
              >
                <InputNumber min={1} />
              </Form.Item>


              {newUserInfo.role === 'fund_manager' && <Form.Item
                label="预警线"
                name="alertLine"
                rules={[{ required: true, message: '请选择预警线' }]}
              >
                <InputNumber step='0.01' />
              </Form.Item>}
              {newUserInfo.role === 'fund_manager' && <Form.Item
                label="平仓线"
                name="closeLine"
                rules={[{ required: true, message: '请选择平仓线' }]}
              >
                <InputNumber step='0.01' />
              </Form.Item>}

              <Form.Item
                label="是否自动交易"
                name="autoTrade"
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>是</Radio.Button>
                  <Radio.Button value={false}>否</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.autoTrade !== currentValues.autoTrade}
              >
                {({ getFieldValue }) =>
                  getFieldValue('autoTrade') === true ? (
                    <Form.Item label="持仓天数" name="createDays">
                      <InputNumber
                        step={1}
                        style={{ width: 160 }}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.autoTrade !== currentValues.autoTrade}
              >
                {({ getFieldValue }) =>
                  getFieldValue('autoTrade') === true ? (
                    <Form.Item label="自动卖出比例" name="autoSellScale">
                      <InputNumber
                        step={0.1}
                        style={{ width: 160 }}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.autoTrade !== currentValues.autoTrade}
              >
                {({ getFieldValue }) =>
                  getFieldValue('autoTrade') === true ? (
                    <Form.Item label="每日买入数量" name="perDayNums">
                      <InputNumber
                        step={1}
                        style={{ width: 160 }}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </Form>
          </Modal>
        </>

      )}
    </Observer>

  )
}