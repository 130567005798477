import ListsDetails from '@/view/Home/AccountAdm/ListsDetails/ListsDetails';
import ProductDetails from '@/view/Home/AccountAdm/ProductDetails/ProductDetails';
import TradersDesk from '@/view/Home/AccountAdm/TradersDesk/TradersDesk';
import AddProduct from '@/view/Home/AccountAdm/page/AddProduct';
import FundManagerNews from '@/view/Home/AccountAdm/page/FundManagerNews';
import FundManagerNewsRisk from '@/view/Home/AccountAdm/page/FundManagerNewsRisk';
import SubAccount from '@/view/Home/AccountAdm/page/SubAccount';
import StockScreening from '@/view/Home/StockAdm/StockScreening/StockScreening';
import Trading from '@/view/Home/StockAdm/StockScreening/Trading';
import AssetsShow from '@/view/Home/AccountAdm/AssetsShow/AssetsShow';
// 资产改版页面
import AssetDesk from '../view/Home/AccountAdm/AssetDesk/AssetDesk';
// 全局回测
import Testback from '../view/Home/Testback/Testback';
import TestForm from '../view/Home/Testback/TestForm';
// 合并计算产品
import VirtualPage from '@/view/Home/CombindAdm/VirtualPage';
import AddVirtualProduct from '@/view/Home/AccountAdm/page/AddVirtualProduct';
import VirtualProduct from '@/view/Home/CombindAdm/Product';
import { renderRoutes } from './rutils';

const routeMainConfig = [
  renderRoutes("/home/listsDetails", "我的子账户", ListsDetails),
  renderRoutes("/home/subAccount", "产品列表", SubAccount),
  renderRoutes("/home/ProductDetails", "产品详情", ProductDetails),
  renderRoutes("/home/tradersDesk", "交易员工作台", TradersDesk),
  renderRoutes("/home/AddProduct", "添加产品", AddProduct),
  renderRoutes("/home/fundManagerNews", "基金经理消息列表", FundManagerNews),
  renderRoutes("/home/fundManagerNewsRisk", "风控通知", FundManagerNewsRisk),
  renderRoutes("/home/StockScreening", "股票池", StockScreening),
  renderRoutes("/home/Trading", "交易", Trading),
  renderRoutes("/home/AssetsShow", "基金经理资产总览", AssetsShow),
  renderRoutes("/home/assets", "新资产页面", AssetDesk),
  renderRoutes("/home/testback", "全局回测", Testback, { key: 'testback' }),
  renderRoutes("/home/backform", "全局回测表单", TestForm, { key: 'backform' }),
  renderRoutes("/home/combindProduct", "合并计算产品", VirtualPage),
  renderRoutes("/home/addVirtuals", "虚拟产品", AddVirtualProduct),
  renderRoutes("/home/vitualProduct", "虚拟产品详情", VirtualProduct),
];

export default routeMainConfig;