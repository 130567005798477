import { Tabs, Space, PageHeader, Dropdown, Menu, Button, Tooltip, Typography } from 'antd';
import { AccountBookOutlined, MoreOutlined } from '@ant-design/icons';
import { TAB_KEYS } from '@/utils/utils';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Text } = Typography;
// 切换最近使用过的产品或账户; 
//【新】tamp取消编辑，产品默认加载所以，子账户加载产品下所以子账户
const TAB_OBJ = {
  'products': { name: 'productName', idKey: 'productId' },
  'lists': { name: 'subAccountName', idKey: 'subAccountId' },
}
const RecentTabs = ({
  active = '',
  pageKey = '',
  items = [],
  id = 0,

  onItemChange,
  // onEditChange
}) => {
  const curentIdKey = _.get(TAB_OBJ, `${pageKey}.idKey`);
  const curentNameKey = _.get(TAB_OBJ, `${pageKey}.name`);

  // function onTabEdit(targetKey, action) {
  //   if (action !== 'add') {
  //     let temp = _.cloneDeep(items);
  //     _.remove(temp, o => _.get(o, curentIdKey, '') === parseInt(targetKey));
  //     onEditChange(temp);
  //   }
  // }
  // 切换最近的产品或账户
  function onTabChange(activeKey) {
    let findex = _.findIndex(items, o => _.get(o, curentIdKey, '') === parseInt(activeKey));
    if (findex !== -1) {
      onItemChange(activeKey, items[findex]);
    }
  }
  const panelChildren = _.size(items) > 0 ? items.map(n => {
    return <TabPane
      tab={_.get(n, curentNameKey, '')}
      key={_.get(n, curentIdKey, 0)}
      closable={_.get(n, curentIdKey, 0) === id ? false : true}
    ></TabPane>
  }) : null;

  return <Tabs
    type='card'
    size='small'
    onChange={onTabChange}
    activeKey={active}
  // onEdit={onTabEdit}
  // hideAdd
  >
    {panelChildren}
  </Tabs>
}

// 详情页tabs的图表和名称
const DetailTabsIcon = ({ keys }) => {
  return <Space>
    {_.get(TAB_KEYS, `${keys}.icon`)}
    <span>{_.get(TAB_KEYS, `${keys}.name`)}</span>
  </Space>
}

// 子账户快速访问的extra,封装pageHeader
const NewPageHeader = ({
  title = '',
  subTitle = '',
  screenWidth = 0,
  listData = {},
  children,

  onBack,
  onJump,
}) => {

  function renderHeaderExtra() {
    // 屏幕大于1500显示5个，其他为3个
    const accItem = screenWidth > 1500 ? 5 : 3;
    // console.log('screenWidth', screenWidth)
    let final = []; let more = [];
    const getTableList = _.get(listData, 'table', []);
    const getSubAccount = _.get(listData, 'table[0].subAccountList', []);
    // console.log('getSubAccount', getSubAccount)
    if (_.size(getTableList) === 1) {
      final = [<span key='Extratitle'>子账户:</span>];
      // const nameSize = _.size(getSubAccount) === 1 ? 7 : 4;
      getSubAccount.map((s, i) => {
        const getName = _.get(s, 'name', '');
        //const newName = _.size(getName) > nameSize ? getName.substring(0, nameSize) + '...' : getName;
        const subItems = <Tooltip title={getName} placement='bottom' key={'t' + getName + i}>
          <Button key={getName + i} type='link' size='small'
            icon={<AccountBookOutlined />}
            onClick={() => onJump(s)}
          >
            {getName}
          </Button>
        </Tooltip>;

        if (i < accItem) {
          final.push(subItems);
        } else {
          more.push(s);
        }
      })
    }
    // 折叠多出来的子账户
    let accMenu = null;
    if (_.size(more) > 0) {
      accMenu = (
        <Menu
          triggerSubMenuAction='hover'
          onClick={(e) => {
            let findex = _.findIndex(getSubAccount, o => o.id === parseInt(e.key))
            onJump(getSubAccount[findex]);
          }}>
          {more.map(item => <Menu.Item key={item.id}>{item.name}</Menu.Item>)}
        </Menu>
      );
      final.push(<Dropdown overlay={accMenu} trigger={['hover']} key='dropdown'>
        <Button size='small' type='text' icon={<MoreOutlined />} />
      </Dropdown>)
    }
    return final;
  }

  const headerProps = { title, subTitle, onBack };
  return <PageHeader {...headerProps}
    style={{ backgroundColor: 'white' }}
    extra={renderHeaderExtra()}
  >
    {children}
  </PageHeader>
};

export {
  RecentTabs,
  DetailTabsIcon,
  NewPageHeader
}