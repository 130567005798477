import React, { useState } from 'react';
import { AutoComplete, Spin, Space, message } from 'antd';
import { findFund } from '@/api/details';
import { isEmptyStringV2 } from '@/utils/utils';
import _ from 'lodash';

let timer = null;
// 搜索私募名称
const FundSearch = ({
  width = 135,
  size = 'small',

  onFundChange
}) => {
  const [fundOptions, setFundOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stValue, setStValue] = useState('');

  async function _findFund(txt) {
    if (isEmptyStringV2(txt)) {
      return
    }

    try {
      setLoading(true);
      const data = await findFund(txt);
      if (_.get(data, 'code', '') === '200') {
        const newStockData = _.get(data, 'data', []).map(n => { return { 'label': n.jjjc, 'value': n.jjdm } });
        setFundOptions(newStockData);
      } else {
        message.error('获取出错！')
      }
      setLoading(false);
    } catch (error) {
      message.error('获取出错！')
    }
  }

  function onSearch(searchText) {
    setStValue(searchText);
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      _findFund(searchText)
    }, 1200);
  }

  function onSelect(data) {
    let fIndex = _.findIndex(fundOptions, o => o.value === data);
    if (fIndex !== -1) {
      onFundChange(fundOptions[fIndex]);
    }
  }

  return (
    <Space>
      <AutoComplete
        size={size}
        options={fundOptions}
        style={{ width: width }}
        onSearch={onSearch}
        onSelect={onSelect}
        placeholder="输入私募名称"
        value={stValue}
      />
      <Spin spinning={loading} size='small' />
    </Space>
  )
}


export default FundSearch;