import React, { useState } from 'react';
import { Card, Col, Row, Spin, Descriptions, Tooltip, Space, Button, Typography, Segmented, Divider } from 'antd';
import { EllipsisOutlined, ProjectFilled, SortAscendingOutlined, RedoOutlined } from '@ant-design/icons';
import { autoCol, RateText, renderUpDownRate2 } from '@/utils/utils'; // PRODUCT_STRATEGY_TYPE
import { labelValues } from '@/utils/utils2';
import { useReactive } from 'ahooks';
import _ from 'lodash';

const { Text } = Typography;
// 排序缩写对应完整字段名，例如时间和收益率在获取时提前进行number类型的处理
const SORT_KEY = {
  'total': 'totalRevenueRate_float',
  'day': 'dailyRevenueRate_float',
  'avg': 'avgExcess',
  'ten': 'tenDayAvgExcess',
  'acc': 'accumulateExcess',
  'timekey': 'timekey',
  'tday': 'tradingDays',
};
const HOME_DESC_ITEMS = [
  ['总资产', 'totalAssets', 'row'],
  ['总收益率', 'totalRevenueRate', 'rate', 'total', '总'],
  ['今日收益率', 'dailyRevenueRate', 'rate', 'day', '今日'],
  ['六月超额', 'avgExcess', 'rate', 'avg', '六月超'],
  ['十日超额', 'tenDayAvgExcess', 'rate', 'ten', '十日超'],
  ['累计超额', 'accumulateExcess', 'rate', 'acc', '累计超'],
  ['开始日期', 'beginDate', 'date'],
  ['最新日期', 'latestDate', 'date', 'timekey', '最新'],
  ['交易天数', 'tradingDays', 'date', 'tday', '交易天数'],
];
const SORT_OPTIONS = _.filter(HOME_DESC_ITEMS, o => _.size(o) === 5);
const SORT_CHANGE_OBJ = { 'up': 'down', 'down': 'up' };// 改变升降序时，需要赋值相反的状态
const CATE_OPTIONS = [labelValues(['每日更新', 'results']), labelValues(['回测中', 'testing'])];

let sortInitState = {};
_.keys(SORT_KEY).map(keyname => sortInitState[SORT_KEY[keyname]] = 'down');
/**
 * 首页卡片展示
 * 1.排序功能说明：根据选择赋值sortType，排序某个字段的值，并根据SORTKEY创建初始sortState里面的state，状态分为 up || down
 * 2. 首次选择，sortRate()根据缩写名称，找到sortState里面的状态[默认为down]，并赋值相反的值[up]，并将sortType赋值为选择的key
 * 3.赋值，setOrderItem(通过orderBy对数据进行排序),isOrder代表是否排序，用于重置
 */
const HomeCard = ({ homeItem, loading, goProductDetails, goSubAccount }) => {
  const [orderItem, setOrderItem] = useState([]);
  const [isOrder, setIsOrder] = useState(false);
  const sortState = useReactive({
    ...sortInitState,
    'sortType': '',
    'cateVal': CATE_OPTIONS[0].value,
  });
  const cateHomeItem = _.get(homeItem, sortState.cateVal, []);
  // 切换sort的状态和sortType
  const sortRate = (key) => {
    const sort_full_key = SORT_KEY[key];
    const curSortType = _.get(sortState, sort_full_key);
    sortState[sort_full_key] = SORT_CHANGE_OBJ[curSortType];
    sortState.sortType = key;
    setOrderItem(_.orderBy(cateHomeItem, [sort_full_key], [sortState[sort_full_key] === 'up' ? 'desc' : 'asc']));
    setIsOrder(true);
  };
  // 根据up.down状态显示不同颜色
  const renderSortColor = (type) => {
    const isSortType = sortState[SORT_KEY[type]]
    let updownColor = isSortType === 'up' ? 'red' : 'green';
    return isOrder && type === sortState.sortType ? updownColor : 'black';
  }

  const contentBtnProps = { size: 'small', type: 'text' };
  const newHomeItem = isOrder ? orderItem : cateHomeItem;
  const lastNameCalHeight = _.size(_.get(_.last(newHomeItem), 'productName', '')) > 25 ? 255 : 220;
  return (
    <Card bodyStyle={{ padding: '8px 16px 16px 16px' }}>
      <Spin spinning={loading}>
        <Row>
          <Col span={8}>
            <Segmented size='small' options={CATE_OPTIONS} value={sortState.cateVal}
              onChange={(v) => sortState.cateVal = v}
            />
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            {_.size(cateHomeItem) > 0 && <Space>
              <Space size='small'>
                {SORT_OPTIONS.map(n => {
                  const s_color = renderSortColor(n[3]);
                  return <Button key={n[1]} size='small' type='text'
                    icon={<SortAscendingOutlined style={{ color: s_color }} />}
                    onClick={() => sortRate(n[3])}
                  >
                    <Text style={{ color: s_color }}>{n[4]}</Text>
                  </Button>
                })}
                <Button {...contentBtnProps} disabled={!isOrder} icon={<RedoOutlined />}
                  onClick={() => {
                    setIsOrder(false);
                    sortState.sortType = '';
                  }} />
              </Space>
              <div style={{ cursor: 'pointer' }} onClick={goSubAccount}>
                <Tooltip title='查看更多'>
                  <EllipsisOutlined />
                </Tooltip>
              </div>
            </Space>}
          </Col>
        </Row>
      </Spin>

      <Divider style={{ margin: `12px 0` }} />

      <Row wrap={true} gutter={[8, 8]}>
        {
          _.size(newHomeItem) > 0
          &&
          newHomeItem.map(item => {
            const getTotalRevenue = _.get(item, 'totalRevenueRate', 0);
            const totalIconColor = renderUpDownRate2(getTotalRevenue);
            return (
              <Col key={item.productId} {...autoCol([6])}>
                <div
                  className='astsCard'
                  onClick={() => goProductDetails(item)}
                >
                  <Descriptions
                    title={<Text style={{ whiteSpace: 'pre-wrap' }}>{item.productName}</Text>}
                    extra={<ProjectFilled style={{ fontSize: 18, color: totalIconColor.color }} />}
                    column={{ ...autoCol([2, 1, 1]) }}
                    size='small'
                  >
                    {HOME_DESC_ITEMS.map(k => {
                      let des_props = { key: k[1], label: <Text type='secondary'>{k[0]}</Text> }
                      if (k[2] === 'row') {
                        des_props.span = 2;
                      }
                      const itemVal = _.get(item, k[1], null); // style={{ color: k[1] === 'latestDate' ? '#1890ff' : 'black' }}
                      return <Descriptions.Item {...des_props}>
                        {k[2] === 'rate' ? <RateText rate={itemVal} /> : <Text strong>{itemVal || ''}</Text>}
                      </Descriptions.Item>
                    })}
                    {/* <Descriptions.Item span={2} label={<Text type='secondary'>{'产品交易类型'}</Text>}>
                      <Text strong>{PRODUCT_STRATEGY_TYPE[_.get(item, 'strategyType', 1)]}</Text>
                    </Descriptions.Item> */}
                  </Descriptions>
                </div>
              </Col>
            )
          })
        }
        <Col {...autoCol([6])}>
          <div
            className='astsCard_empty'
            onClick={goSubAccount}
            style={{ 'minHeight': lastNameCalHeight + 'px', 'lineHeight': (lastNameCalHeight - 30) + 'px' }}
          >
            <Text type='secondary'>更多</Text>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default HomeCard;