import React from 'react';
import { Typography, message } from 'antd';
import {
  ArrowUpOutlined, ArrowDownOutlined, GoldenFilled, ProjectFilled,
  FundFilled, SlidersFilled, PieChartFilled, SwitcherFilled, BuildFilled,
  PullRequestOutlined, FunnelPlotFilled, DatabaseFilled, ControlFilled,
  ClockCircleFilled, FileTextFilled
} from '@ant-design/icons';
import { isValidString } from './utils2'
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

const HOME_PRODUCT_NUMBER = 20; // 产品卡片；包含最后 更多的操作按钮

const TAB_KEYS = {
  'MAIN_RATE': { name: '收益率', key: 'MAIN_RATE', icon: <FundFilled /> },
  'ATTRIBUTION': { name: '收益归因', key: 'ATTRIBUTION', icon: <PullRequestOutlined /> },
  'CONTRIBUTION': { name: '子账户盈亏分布', key: 'CONTRIBUTION', icon: <SlidersFilled /> },
  'CONTRIBUTION2': { name: '个股盈亏分布', key: 'CONTRIBUTION2', icon: <SlidersFilled /> },
  'DISTRIBUTION': { name: '持仓分布', key: 'DISTRIBUTION', icon: <PieChartFilled /> },
  'SIGNAL': { name: '个股交易信号', key: 'SIGNAL', icon: <FunnelPlotFilled /> },
  'COUNTING': { name: '交易次数和股票数', key: 'COUNTING', icon: <DatabaseFilled /> },
  'EARNING': { name: '个股收益比较', key: 'EARNING', icon: <ControlFilled /> },
  'PRO_COM': { name: '产品对比', key: 'PRO_COM', icon: <SwitcherFilled /> },
  'ACC_COM': { name: '账户对比', key: 'ACC_COM', icon: <SwitcherFilled /> },
  'BT_COM': { name: '回测对比', key: 'BT_COM', icon: <BuildFilled /> },
  'PRO_LEVEL': { name: '重仓分布', key: 'PRO_LEVEL', icon: <GoldenFilled /> },
  'RATE_K': { name: '收益率K', key: 'RATE_K', icon: <ProjectFilled /> },
  'REVIEW': { name: '历史持仓复盘', key: 'REVIEW', icon: <ClockCircleFilled /> },
  'EXTRA_COM': { name: '超额分析', key: 'EXTRA_COM', icon: <FileTextFilled /> },
};

const RES_STATUS = { '200': true, '400': false, '401': false };
function _validateResponse(res, empType) {
  const getCode = _.get(res, 'code', '');
  if (RES_STATUS[getCode]) {
    return _.get(res, 'data', empType || null);
  } else {
    return empType
  }
}

function validateResponse(res = {}, unmont = false) {
  let valid = false;
  if (_.get(res, 'code', '') === '200') {
    valid = true;
  } else if (_.get(res, 'code', '') === '400') {
    if (isValidString(res.data)) {
      message.warning(_.get(res, 'data', '无数据！'))
    } else {
      message.warning(_.get(res, 'message', '无数据！'))
    }
  } else {
    message.info(_.get(res, 'message', '获取失败!'));
  }
  if (unmont) { // 已经卸载则验证不通过
    valid = false;
  }
  return valid;
}

function isEmptyStringV2(value) {
  if (value == "undefined" || !value || !/[^\s]/.test(value)) {
    return true;
  } else {
    return false;
  }
}

// 大额资金中文简写
const carryObject = {
  1: '万',
  2: '亿',
  3: '万亿'
}
const handleCarry = (number, currentCarry) => {
  return number >= 10000 || number <= -10000 ?
    _.round(number / 10000, 1) + carryObject[currentCarry + 1]
    : number + carryObject[currentCarry]
}
function renderHugeFund(value) {
  const newValue = _.isNumber(value) ? value : parseInt(value);
  if (newValue > 100000000 || newValue < -100000000) {
    return handleCarry(_.round(newValue / 100000000, 2), 2)
  } else if (newValue > 10000 || newValue < -10000) {
    return handleCarry(_.round(newValue / 10000, 2), 1)
  } else {
    return newValue
  }
}

const MOMENT_TODAY = moment().format('YYYY-MM-DD');

const PRODUCT_STRATEGY_TYPE = {
  1: '量化多头策略',
  2: '指数增强策略',
  3: '期货CTA策略',
  4: '市场中性策略',
  5: '多空灵活策略',
  6: '参与新股发行策略',
  7: '量化套利策略',
  8: '日内回转策略',
  9: '其他策略',
}

function findTenItemDay(dataArray, number = 10) {
  let index = 0;
  let count = 0;
  if (_.isArray(dataArray)) {
    for (let i = 0; i < dataArray.length; i++) {
      if (count > number) {
        break;
      }
      count = count + dataArray[i].records;
      index = i
    }
  }

  return {
    date: _.get(dataArray, `[${index}].day`, null),
    page: index + 1
  };
}

const IMG_URL = 'http://39.103.203.59';
const RAMDOM_IMG_URL = 'https://joeschmoe.io/api/v1/random';
const UPLOAD_FILE_URL = 'http://39.103.203.59:8870/api/file/upload'

const handleMomentDate = (time) => {
  return time ? moment(time).format('YYYY-MM-DD HH:mm') : '无';
}

const renderDescValue = (value) => {
  let final = '';
  if (!value) {
    final = '0'
  } else if (value === -1) {
    final = '不受限'
  } else {
    final = String(value);
  }
  return final;
}

// 收益率颜色 + 百分号处理
const updownColorKey = {
  zero: 'black',
  positive: 'red',
  negative: 'green'
}
// 根据rate返回带颜色或【%】的收益率string
const RateText = ({
  rate = 0,
  carry = 2,
  noPrefix = null,
  restStyle = { fontWeight: 600 },
  rType = 'number'
}) => {
  let renderRate = rate ? _.round(parseFloat(rate), carry).toFixed(carry) : 0;
  // 替换【,】的string
  if (rType === 'replace' && _.isString(rate)) {
    let repNumber = rate.replaceAll(',', '');
    renderRate = _.round(parseFloat(repNumber), carry).toFixed(carry)
  }
  let ud = '';
  if (renderRate === 0) {
    ud = 'zero';
  } else if (renderRate > 0) {
    ud = 'positive';
  } else if (renderRate < 0) {
    ud = 'negative'
  }
  // 重新生成待【,】进位的字符串； utils2里面拆分出来单独可使用的function
  if (rType === 'replace') {
    let splitNumber = _.reverse(_.split(renderRate, ''));
    let c = 0;
    let isDim = splitNumber.indexOf('.') !== -1 ? true : false;
    let stringArray = [];
    splitNumber.map(n => {
      let num = n !== '.' ? parseInt(n) : null;
      if (n === '.') {
        isDim = false;
      }
      if (c === 3 && !isDim && n !== '-') {
        c = 0;
        stringArray.push(',');
      }
      if (num !== null && !isDim) {
        c = c + 1;
      }
      stringArray.push(n);
    })
    renderRate = _.join(_.reverse(stringArray), '');
    if (_.includes(renderRate, '.00')) {
      renderRate = renderRate.replaceAll('.00', '')
    }
  }
  return <Text style={{ color: updownColorKey[ud] || 'black', ...restStyle }}>{renderRate + (noPrefix ? '' : '%')}</Text>
}

const renderUpDownRate2 = (rate = 0, prefix = true, restStyle = {}) => {
  const renderRate = rate ? _.round(parseFloat(rate), 2).toFixed(2) : 0;
  let ud = '';
  if (renderRate === 0) {
    ud = 'zero';
  } else if (renderRate > 0) {
    ud = 'positive';
  } else if (renderRate < 0) {
    ud = 'negative'
  }
  const finalRate = renderRate + (prefix ? '%' : '');
  return {
    text: <Text style={{ color: updownColorKey[ud] || 'black', ...restStyle }}>{finalRate}</Text>,
    color: updownColorKey[ud] || 'black',
    type: ud,
    purTxt: finalRate
  }
}
// 生成标准表格columns；typeArray=[需要增加单列条件的Index,type的值]
const createBasicColums = (colList = [], typeArray = []) => {
  return _.size(colList) > 0 ? colList.map((itemArray, i) => {
    if (_.size(typeArray) > 0 && typeArray.indexOf(i) !== -1) {
      return singleColumns(itemArray, _.last(typeArray))
    }
    return singleColumns(itemArray)
  }) : []
}
// 创建单一列的column信息
const ALIGN_KEYS = ['left', 'center', 'right']; // 单列数据中最后一位默认是align，如需要配置该行其他数据，可以通过非对齐字段进行判断。配合type使用
const SORT_KEYS = ['descend', 'ascend'];
const singleColumns = (pmArray = ['', ''], type = '') => {
  // pmArray 创建单列数据的参数数组; 根据使用频率进行排列及拓展；
  //0：名称 1:key字段 2宽度 3对齐（可用0占位默认值）4:默认排序
  const pmGet = (pathArr) => _.get(pmArray, pathArr, '');
  let obj = {
    title: pmGet(['0']),
    dataIndex: pmGet(['1']),
    key: pmGet(['1']),
    align: pmArray[3] && ALIGN_KEYS.indexOf(pmGet(['3'])) !== -1 ? pmGet(['3']) : ALIGN_KEYS[0]
  }
  if (pmArray[2]) {
    obj.width = pmGet(['2']);
  }
  if (pmArray[4] && _.isArray(pmGet(['4']))) {
    const getFourth = pmGet(['4']);
    obj.sorter = (a, b) => a[pmGet(['4', '1'])] - b[pmGet(['4', '1'])];
    obj.sortDirections = SORT_KEYS;
    if (SORT_KEYS.indexOf(pmGet(['4', '0'])) !== -1) {
      obj.defaultSortOrder = _.head(getFourth);
    }
  }
  // type 生成些标准需求且复用率高的列表渲染内容，更复杂无需在此继续增加type
  if (type === 'dateTime') {
    obj.render = function (text, record) { return text ? moment(text).format('YYYY-MM-DD HH:mm') : '' }
  } else if (type === 'updown') {
    const isRate = _.last(pmArray) === 'rate' ? false : true; // 收益字段，array最后一位是rate，则添加百分号
    obj.render = function (text, record) { return <RateText rate={text} noPrefix={isRate} /> }
  } else if (type === 'updown_replace') { // assets页面需要替换【，】
    const isRate = _.last(pmArray) === 'rate' ? false : true;
    obj.render = function (text, record) { return <RateText rate={text} noPrefix={isRate} restStyle={{}} rType='replace' /> }
  } else if (type === 'strong') {
    obj.render = function (text, record) { return <Text strong>{text}</Text> }
  } else if (type === 'strongPrice') {
    obj.render = function (text, record) {
      const priceVal = text ? _.round(parseFloat(text), 2) : 0;
      return <Text strong>{priceVal}</Text>
    }
  }
  return obj
}

// 自适应：默认中屏幕12，小屏幕24沾满一行
const autoCol = (span = [0, null, null]) => {
  return { xxl: span[0], xl: span[0], lg: span[1] || 12, md: span[2] || 24, sm: span[2] || 24, xs: span[2] || 24, flex: 'auto' }
}

// 渲染 涨红跌绿 颜色
function renderValueColor(value, vType = 'pure', colors = { up: 'red', down: 'green', zero: 'black' }) {
  let pureValue = vType === 'replace' ? _.replace(value || '', ',', '') : value;
  const floatValue = pureValue ? parseFloat(pureValue) : 0;
  if (floatValue > 0) {
    return colors.up;
  } else if (floatValue < 0) {
    return colors.down;
  } else {
    return colors.zero;
  }
}

function renderValuePrefix(value) {
  const floatValue = value ? parseFloat(value) : 0;
  if (floatValue > 0) {
    return <ArrowUpOutlined style={{ color: 'red' }} />
  } else if (floatValue < 0) {
    return <ArrowDownOutlined style={{ color: 'green' }} />
  } else {
    return null
  }
}

const colWidth = (cols = []) => {
  return _.size(cols) > 0 ? _.sum(cols.map(c => c.width)) : 0
}

export {
  // 静态常量
  IMG_URL, RAMDOM_IMG_URL, UPLOAD_FILE_URL,
  PRODUCT_STRATEGY_TYPE,
  MOMENT_TODAY,
  HOME_PRODUCT_NUMBER,
  TAB_KEYS,
  // 方法
  _validateResponse, validateResponse,
  findTenItemDay,
  renderHugeFund,
  isEmptyStringV2,
  renderDescValue,
  handleMomentDate,
  renderUpDownRate2,
  singleColumns, createBasicColums,
  autoCol,
  renderValuePrefix, renderValueColor,
  colWidth,
  // 组件
  RateText,
}