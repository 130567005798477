import Login from '@/view/Login/Login';
import Register from '@/view/Login/Register';
import ForgetPassword from '@/view/Login/ForgetPassword';
import EmailActivation from '@/view/Login/EmailActivation';
import EmailActivationFail from '@/view/Login/EmailActivationFail';
import Home from '@/view/Home/Home';
// 路由分类
import routeSubConfig from './subRoute';
import routeMainConfig from './mainRoute';
import _ from 'lodash';

export const routeConfig = [
    {
        path: "/login",
        name: "登陆",
        exact: true,
        component: Login,
    },
    {
        path: "/register",
        name: "注册",
        exact: true,
        component: Register,
    },
    {
        path: "/forgetPassword",
        name: "忘记密码",
        exact: true,
        component: ForgetPassword,
    },
    {
        path: "/emailActivation",
        name: "邮箱激活成功",
        exact: true,
        component: EmailActivation,
    },
    {
        path: "/emailActivationFail",
        name: "邮箱激活失败",
        exact: true,
        component: EmailActivationFail,
    },
    {
        path: "/home",
        name: "首页",
        component: Home,
        routes: _.concat(routeMainConfig, routeSubConfig)
    }
]