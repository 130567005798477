import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Table, message, DatePicker, Modal, Space, List, Typography } from 'antd';
import { getInstrumentOrder, getInstrumentAsset, getSubAccountCal, calAccoutPtofitRate, calCapitalRate, calTradeProfitRate, calOrderAnalysis } from '@/api/stock';
import { useLocalObservable } from 'mobx-react';
import { useUnmount } from 'ahooks';
import { store } from '@/store/mobx';
import moment from 'moment';
import EchartsInfo9 from '../comp/EchartsInfo9';
import _ from 'lodash';

const { Text } = Typography;
let timer = null;

export default function ListsDetails(props) {
    const [subAccountInfo] = useState(props.location.state.subAccountInfo);
    const [assetData, setassetData] = useState({});
    const [orderList, setorderList] = useState([]);
    const [fundList, setfundList] = useState([]);
    const mobxStore = useLocalObservable(() => store);
    const [currentPage, setcurrentPage] = useState(1);
    const [currentPageBottom, setcurrentPageBottom] = useState(1);
    const [chartsData9Left, setchartsData9Left] = useState([]);
    const [barData, setbarData] = useState([]);
    const [lineData, setlineData] = useState([]);
    const [chartsXData9, setchartsXData9] = useState([]);
    const [hasBar, sethasBar] = useState(false);
    const [startAndEndDate, setstartAndEndDate] = useState([moment().subtract('months', 1).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
    const [activeKey, setactiveKey] = useState('1');
    const [analysisData, setAnalysisData] = useState([]);
    const [aVisible, setaVisible] = useState({ show: false, data: null });
    const { RangePicker } = DatePicker;

    const tabList = [
        {
            key: '1',
            tab: '账号收益率',
        },
        {
            key: '2',
            tab: '交易收益率',
        },
        {
            key: '3',
            tab: '资金使用率',
        },
        {
            key: '4',
            tab: '期货统计',
        },
    ];
    const columns = [
        {
            title: '序号',
            render: (text, record, index) => { return (currentPageBottom - 1) * 5 + index + 1 },
        },
        {
            title: '交易所简称',
            dataIndex: 'exchange',
        },
        {
            title: '期货代码',
            dataIndex: 'instrumentId',
        },
        {
            title: '价格',
            dataIndex: 'price',
        },
        {
            title: '手数',
            dataIndex: 'volume',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            sorter: (a, b) => {
                let aTime = new Date(a.gmtCreate).getTime();
                let bTime = new Date(b.gmtCreate).getTime();
                return aTime - bTime;
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text) => {
                return (moment(text).format('YYYY-MM-DD HH:mm:ss'))
            }
        },
        {
            title: '方向',
            dataIndex: 'direction',
            render: (text) => {
                return (text === '0' ? <span style={{ color: 'red' }}>做多</span> : <span style={{ color: 'green' }}>做空</span>)
            }
        },
        {
            title: '开平',
            dataIndex: 'offsetFlag',
            render: (text) => {
                return (text === '0' ? <span>开仓</span> : <span>平仓</span>)
            }
        },
        {
            title: '平单盈利',
            dataIndex: 'futureProfit',
        },
    ]
    const fundColumns = [
        {
            title: '序号',
            render: (text, record, index) => { return (currentPage - 1) * 5 + index + 1 },
        },
        {
            title: '期货名',
            dataIndex: 'instrumentId',
        },
        {
            title: '交易所简称',
            dataIndex: 'exchangeId',
        },
        {
            title: '开仓均价',
            dataIndex: 'futureOpenAvgPrice',
            render: (text) => {
                return <span>{parseFloat(text).toFixed(2)}</span>
            }
        },
        {
            title: '方向',
            dataIndex: 'direction',
            render: (text) => {
                return (text === '0' ? <span style={{ color: 'red' }}>{'做多'}</span> : <span style={{ color: 'green' }}>{'做空'}</span>)
            }
        },
        {
            title: '总仓',
            dataIndex: 'totalPosition',
        },
        {
            title: '今仓',
            dataIndex: 'todayPosition',
        },
        {
            title: '昨仓',
            dataIndex: 'yesterdayPosition',
        },
        {
            title: '保证金',
            dataIndex: 'occupyMargin',
            render: (text) => {
                return <span>{parseFloat(text).toFixed(2)}</span>
            }
        },
        {
            title: '浮动盈亏',
            dataIndex: 'floatingProfitAndLoss',
            render: (text) => {
                return (Number(text) > 0 ? <span style={{ color: 'red' }}>{text.toFixed(2)}</span> : Number(text) < 0 ? <span style={{ color: 'green' }}>{text.toFixed(2)}</span> : <span>{text}</span>)
            }
        },
        {
            title: '持仓盈亏',
            dataIndex: 'keepPositionProfitAndLoss',
            render: (text) => {
                return (Number(text) > 0 ? <span style={{ color: 'red' }}>{text.toFixed(2)}</span> : Number(text) < 0 ? <span style={{ color: 'green' }}>{text.toFixed(2)}</span> : <span>{text}</span>)
            }
        },
    ]
    const analysisColumns = [
        {
            title: '合约',
            render: (record) => _.get(record, 'orderAnalysis.instrumentId', '')
        },
        {
            title: '总交易单数',
            render: (record) => _.get(record, 'orderAnalysis.totalTradeNum', '')
        },
        {
            title: '总交易手数',
            render: (record) => _.get(record, 'orderAnalysis.totalLot', '')
        },
        {
            title: '总收益',
            render: (record) => _.get(record, 'orderAnalysis.profit', '')
        },
        {
            title: '平均单次利润',
            render: (record) => _.get(record, 'orderAnalysis.avgSingleProfit', '')
        },
        {
            title: '平均每手利润',
            render: (record) => _.get(record, 'orderAnalysis.avgSingleLotProfit', '')
        },
        {
            title: '操作',
            dataIndex: 'option',
            render: (text, record) => (
                <Space size={'middle'}>
                    <Button type='link' onClick={() => setaVisible({
                        show: true,
                        data: _.get(record, 'orderAnalysis.longOrderList', []),
                        title: '多头'
                    })}>
                        查看多头
                    </Button>
                    <Button type='link' onClick={() => setaVisible({
                        show: true,
                        data: _.get(record, 'orderAnalysis.shortOrderList', []),
                        title: '空头'
                    })}>
                        查看空头
                    </Button>
                </Space>
            )
        },
    ]

    const subListColumns = [
        {
            title: '^^最大盈利',
            dataIndex: 'maxProfit',
        },
        {
            title: '^^最大亏损',
            dataIndex: 'maxLoss',
        },
        {
            title: '全部^^单数',
            dataIndex: 'totalTradeNum',
        },
        {
            title: '全部^^手数',
            dataIndex: 'totalLot',
        },
        {
            title: '全部^^盈利',
            dataIndex: 'totalProfit',
        },
        {
            title: '全部^^每单盈利',
            dataIndex: 'totalAvgSignalProfit',
        },
        {
            title: '全部^^每手盈利',
            dataIndex: 'totalAvgLotProfit',
        },
        {
            title: '^^盈利单数',
            dataIndex: 'proTradeNum',
        },
        {
            title: '^^盈利手数',
            dataIndex: 'proLot',
        },
        {
            title: '^^盈利利润',
            dataIndex: 'proProfit',
        },
        {
            title: '^^盈利平均每单盈利',
            dataIndex: 'proAvgSignalProfit',
        },
        {
            title: '^^亏损单数',
            dataIndex: 'lossTradeNum',
        },
        {
            title: '^^亏损手数',
            dataIndex: 'lossLot',
        },
        {
            title: '^^盈利平均每手盈利',
            dataIndex: 'lossProfit',
        },
        {
            title: '^^利润',
            dataIndex: 'lossProfit',
        },
        {
            title: '^^亏损平均每单',
            dataIndex: 'lossAvgSignalProfit',
        },
        {
            title: '^^亏损平均每手',
            dataIndex: 'lossAvgLotProfit',
        },
    ]

    useEffect(() => {
        if (!subAccountInfo.subAccountId) {
            subAccountInfo.subAccountId = subAccountInfo.id;
        }

        //获取账户资产信息
        async function getAssetDate() {
            const data = await getSubAccountCal({ subAccountId: subAccountInfo.subAccountId });
            if (_.get(data, 'code', '') === '200') {
                setassetData(data.data);
            } else {
                message.error(data.message);
            }
        }
        //获取账户收益率
        async function showAccountRate() {
            if (mobxStore.userInfo.role === 'fund_manager') {
                const data = await calAccoutPtofitRate({ subAccountId: subAccountInfo.subAccountId, startDate: startAndEndDate[0], endDate: startAndEndDate[1] });
                if (data.code === '200') {
                    let arr = [];
                    let brr = [];
                    if (data.data.accoutPtofitRateDate.length !== 0) {
                        for (let i = 0; i < data.data.accoutPtofitRateDate.length; i++) {
                            arr.push(moment(data.data.accoutPtofitRateDate[i]).format('YYYY-MM-DD'));
                            brr.push((parseFloat(data.data.accoutPtofitRate[i]) * 100).toFixed(2));
                        }
                    }
                    setchartsXData9(arr);
                    setchartsData9Left(brr);
                }
            }
        }
        //获取持仓信息，去除掉总仓为0的数据
        async function _getPositionReturn() {
            const data = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
            if (data.code === '200') {
                let arr = [];
                if (data.data !== null && data.data.length !== 0) {
                    data.data.map(item => {
                        if (item.totalPosition !== 0) {
                            arr.push(item);
                        }
                        return item;
                    })
                }
                setfundList(arr);
            } else {
                message.error(data.message);
            }
        }
        //获取交易记录
        async function showTransTable() {
            const data = await getInstrumentOrder({ subAccountId: subAccountInfo.subAccountId });
            if (data.code === '200') {
                setorderList(data.data);
            } else {
                message.error(data.message);
            }
        }

        getAssetDate();
        _getPositionReturn();
        showTransTable();
        showAccountRate();
        timer = setInterval(
            async () => {
                if (!subAccountInfo.subAccountId) {
                    subAccountInfo.subAccountId = subAccountInfo.id;
                }
                if (document.getElementById("FutureList") === null) {
                    clearInterval(timer);
                    return;
                }
                getAssetDate();
                _getPositionReturn();
            }, 10000)

    }, [setassetData])

    useUnmount(() => {
        timer && clearInterval(timer)
    });

    function _goShares() {
        props.history.push({
            pathname: '/home/FutureScreening',
            state: { subAccountInfo: subAccountInfo }
        });
    }
    async function selectTime(date, dateString) {
        let params = {
            subAccountId: subAccountInfo.subAccountId,
            beginTime: dateString[0],
            endTime: dateString[1],
        }
        const data = await getInstrumentOrder(params);
        if (data.code === '200') {
            setorderList(data.data);
        } else {
            message.error(data.message);
        }
    }
    //切换标签栏触发的事件
    async function changeTab(value) {
        setactiveKey(value);
        if (value === '1') {
            const data = await calAccoutPtofitRate({ subAccountId: subAccountInfo.subAccountId, startDate: startAndEndDate[0], endDate: startAndEndDate[1] });
            if (data.code === '200') {
                let arr = [];
                let brr = [];
                if (data.data.accoutPtofitRateDate.length !== 0) {
                    for (let i = 0; i < data.data.accoutPtofitRateDate.length; i++) {
                        arr.push(moment(data.data.accoutPtofitRateDate[i]).format('YYYY-MM-DD'));
                        brr.push((parseFloat(data.data.accoutPtofitRate[i]) * 100).toFixed(2));
                    }
                }
                setchartsXData9(arr);
                setchartsData9Left(brr);
                sethasBar(false);
            }
        } else if (value === '2') {
            const data = await calTradeProfitRate({ subAccountId: subAccountInfo.subAccountId, startDate: startAndEndDate[0], endDate: startAndEndDate[1] });
            if (data.code === '200') {
                let arr = [];
                let brr = [];
                if (data.data.timeDate.length !== 0) {
                    for (let i = 0; i < data.data.timeDate.length; i++) {
                        arr.push(moment(data.data.timeDate[i]).format('YYYY-MM-DD'));
                        brr.push((data.data.tradeProfitRate[i] * 100).toFixed(2));
                    }
                }
                setchartsXData9(arr);
                setchartsData9Left(brr);
                sethasBar(false);
            }
        } else if (value === '3') {
            const data = await calCapitalRate({ subAccountId: subAccountInfo.subAccountId, startDate: '2021-12-01', endDate: '2021-12-22' });
            if (data.code === '200') {
                let arr = [];
                let brr = [];
                let crr = [];
                if (data.data.timeDate.length !== 0) {
                    for (let i = 0; i < data.data.timeDate.length; i++) {
                        arr.push(moment(data.data.timeDate[i]).format('YYYY-MM-DD'));
                        brr.push((data.data.capitalRate[i] * 100).toFixed(2));
                        crr.push(data.data.totalAsset[i].toFixed(2));
                    }
                }
                setchartsXData9(arr);
                setbarData(crr);
                setlineData(brr);
                sethasBar(true);
            }
        } else if (value === '4') {
            // 期货统计
            const data = await calOrderAnalysis({ subAccountId: subAccountInfo.subAccountId, startDate: startAndEndDate[0], endDate: startAndEndDate[1] })
            //console.log('期货统计', data)
            if (data && _.get(data, 'code', '') === '200') {
                let getData = _.get(data, 'data', []);
                setAnalysisData(_.isArray(getData) ? getData : [])
            } else {
                setAnalysisData([])
            }
        }
    }
    function handleChangeDate(e, dateString) {
        setstartAndEndDate(dateString);
        changeTab(activeKey, dateString);
    }

    const colSpan = mobxStore.userInfo.role === 'trader' ? [7, 6, 6] : [10, 7, 7];
    return (
        <div className="contentView" id="FutureList">
            <Row gutter={8}>
                <Col span={colSpan[0]}>
                    <Card title={subAccountInfo.name ? subAccountInfo.name + '资产' : subAccountInfo.subAccountName + '资产'} bordered={true} className="headCard">
                        <span className="headCardTetx" ><h3 className="headCardTetx">{assetData.totalAssets ? parseFloat(assetData.totalAssets).toFixed(2) : 0}</h3></span>
                        <Row>
                            <div className="headCardT">
                                <span className="colorGrey">可用资金</span>
                                <h3 className="headCardTetx">{assetData.totalBalance ? parseFloat(assetData.totalBalance).toFixed(2) : 0}</h3>
                            </div>
                            <div className="headCardT" style={{ marginLeft: 30 }}>
                                <span className="colorGrey">保证金</span>
                                <h3 className="headCardTetx">{assetData.frozenBalance ? parseFloat(assetData.frozenBalance).toFixed(2) : 0}</h3>
                            </div>
                        </Row>
                    </Card>
                </Col>
                <Col span={colSpan[1]}>
                    <Card bordered={true} className="headCard">
                        <div className="headCardT">
                            <span className="colorGrey">总收益</span>
                            <h3 className="headCardTetx">{assetData.totalRevenue === null || parseFloat(assetData.totalRevenue) === 0 ? 0 : parseFloat(assetData.totalRevenue) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.totalRevenue).toFixed(2)}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.totalRevenue).toFixed(2)}</span>}</h3>
                        </div>
                        <div className="headCardB" >
                            <span className="colorGrey">总收益率</span>
                            <h3 className="headCardTetx">{assetData.totalRevenueRate === null || parseFloat(assetData.totalRevenueRate) === 0 ? '0%' : parseFloat(assetData.totalRevenueRate) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.totalRevenueRate * 100).toFixed(2) + '%'}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.totalRevenueRate * 100).toFixed(2) + '%'}</span>}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={colSpan[2]}>
                    <Card bordered={true} className="headCard">
                        <div className="headCardT">
                            <span className="colorGrey">日收益</span>
                            <h3 className="headCardTetx">{assetData.dailyRevenue === null || parseFloat(assetData.dailyRevenue) === 0 ? 0 : parseFloat(assetData.dailyRevenue) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.dailyRevenue).toFixed(2)}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.dailyRevenue).toFixed(2)}</span>}</h3>
                        </div>
                        <div className="headCardB">
                            <span className="colorGrey">日收益率</span>
                            <h3 className="headCardTetx">{assetData.dailyRevenueRate === null || parseFloat(assetData.dailyRevenueRate) === 0 ? '0%' : parseFloat(assetData.dailyRevenueRate) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.dailyRevenueRate * 100).toFixed(2) + '%'}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.dailyRevenueRate * 100).toFixed(2) + '%'}</span>}</h3>
                        </div>
                    </Card>
                </Col>
                {
                    mobxStore.userInfo.role === 'trader' &&
                    <Col span={5}>
                        <Card bordered={true} className="headCard headCard4">
                            <Row justify="center">
                                <Button type="primary" size="large" onClick={_goShares}>交易</Button>
                            </Row>
                        </Card>
                    </Col>
                }
                {/* {
                    mobxStore.userInfo.role === 'fund_manager' &&
                    <Col span={5}>
                        <Card bordered={true} className="headCard headCard4">
                            <Row justify="center">
                                <Slider
                                    style={{ width: '80%' }}
                                    onChange={(value) => setsliderValue(value)}
                                    value={typeof sliderValue === 'number' ? sliderValue : 0}
                                />
                                <span>{sliderValue + '%'}</span>
                            </Row>
                            <Row justify="center">
                                <span>总资产 {parseFloat(assetData.totalAssets).toFixed(4) || '0'}万元</span>
                            </Row>
                            <br/>
                            <Row justify="center">
                                <Button type="primary" >提交</Button>
                            </Row>
                        </Card>
                    </Col>
                } */}
            </Row>
            {
                mobxStore.userInfo.role === 'fund_manager' &&
                <>
                    <br />
                    <Card
                        tabList={tabList}
                        activeTabKey={activeKey}
                        onTabChange={e => { changeTab(e, startAndEndDate) }}
                        tabBarExtraContent={
                            <Row justify='end'>
                                <span style={{ marginRight: 10, marginTop: 5 }}>选择日期:</span>
                                <RangePicker defaultValue={[moment().subtract('months', 1), moment()]} format="YYYY-MM-DD" onChange={handleChangeDate} />
                            </Row>
                        }
                    >
                        {activeKey === '4' ? <>
                            <Table
                                rowKey="instrumentId"
                                dataSource={analysisData}
                                columns={analysisColumns}
                                bordered
                            />
                        </> : <EchartsInfo9
                            chartsData9Left={chartsData9Left}
                            barData={barData}
                            lineData={lineData}
                            hasBar={hasBar}
                            chartsXData9={chartsXData9}
                        />}

                    </Card>
                </>
            }
            <br />
            <Card title="当前持仓" bordered={true} >
                <Table
                    rowKey={(record) => { return record.instrumentId + record.direction }}
                    dataSource={fundList}
                    columns={fundColumns}
                    bordered
                    pagination={{
                        defaultPageSize: 5,
                        onChange: e => { setcurrentPage(e) }
                    }}
                />
            </Card>
            <br />
            <Card title="交易记录" bordered={true} extra={
                <Row justify="end" align="middle">
                    <span style={{ marginRight: 10 }}>选择日期:</span>
                    <RangePicker onChange={selectTime} />
                </Row>
            }>
                <Table
                    rowKey="id"
                    dataSource={orderList}
                    columns={columns}
                    bordered
                    pagination={{
                        defaultPageSize: 5,
                        onChange: e => { setcurrentPageBottom(e) }
                    }}
                />
            </Card>

            <Modal
                title={_.get(aVisible, 'title', '标题')}
                visible={_.get(aVisible, 'show', false)}
                footer={null}
                onCancel={() => setaVisible({ show: false, data: null })}
            >
                {/* <Table
                    dataSource={[_.get(aVisible, 'data', {})]}
                    columns={_.get(aVisible, 'show', false) ? subListColumns.map(c => {
                        let newTitle = _.replace(c.title, '^^', _.get(aVisible, 'title', ''));
                        return { ...c, title: newTitle }
                    }) : []}
                    bordered
                /> */}
                <List
                    header={null}
                    footer={null}
                    bordered
                    dataSource={_.get(aVisible, 'show', false) ? subListColumns.map(c => {
                        let newTitle = _.replace(c.title, '^^', _.get(aVisible, 'title', ''));
                        return { ...c, title: newTitle }
                    }) : []}
                    renderItem={item => (
                        <List.Item>
                            {item.title + ' : '}<Text strong>{_.get(aVisible, 'data.' + item.dataIndex, {})}</Text>
                        </List.Item>
                    )}
                />
            </Modal>
        </div>
    )
}