import React from 'react';
import { Row, Col, Cascader, Space, Checkbox, Segmented, Button, Typography, Select, Tag } from 'antd';
import { ClearOutlined, AccountBookOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { labelValues, isValidNumber } from '@/utils/utils2';
import { IndexSelect } from './main_widget';
import FundSearch from './FundSearch';
import _ from 'lodash';

const { Text } = Typography;
const { CheckableTag } = Tag;
const dateOptions = [labelValues(['今日', 'TODAY']), labelValues(['周', 'ONE_WEEK']),
labelValues(['月', 'ONE_MONTH']), labelValues(['季', 'THREE_MONTH']), labelValues(['半年', 'SIX_MONTH']),
labelValues(['年', 'ONE_YEAR']), labelValues(['三年', 'THREE_YEAR']), labelValues(['全部', 'ALL'])
];
const stepOptions = [3, 5, 10, 20].map(n => labelValues([String(n), String(n)]));
const maOptions = [5, 10, 20].map(n => labelValues([String(n) + '日', String(n)]));
const vOptions = [labelValues(['量', 'vol']), labelValues(['融', 'margin'])];
// 账户产品对比图表的bar组件
const TwoBars = ({
  typeVal = '',
  accountVal = {},
  accountOptions = {},
  idxSymbol = '',
  isClearDisable = false,
  isAccount = false,
  checkVal = [],
  checkItems = [],
  stepVal = '',
  compareObj = {},
  maVal = 0,
  volType = '',
  pageKey = '',
  Vstring = '',
  diffStatus = false,

  onTypeChange,
  onAccountChange,
  onIdxChange,
  onClear,
  onCheckChange,
  onCompare,
  onStepChange,
  onMaChange,
  onVolTypeChange,
  onDelItem,
  onDiffChange,
}) => {
  const selectBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', paddingLeft: 4, paddingRight: 4 };
  const cascaderProps = { style: { width: 355 }, size: 'small', bordered: false };
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginTop: 8, marginBottom: 8, paddingLeft: 8, paddingRight: 8, minHeight: 35 };
  const disCompare = _.size(checkVal) !== 2 || _.includes(['ONE_WEEK', 'TODAY'], typeVal);
  const barColLeft = { xxl: 18, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 };
  const barColRight = { xxl: 6, xl: 6, lg: 6, md: 12, sm: 12, xs: 12 };
  return <>
    <Row gutter={[8, 8]}>
      <Col {...barColLeft}>
        <Space>
          <Segmented size='small' options={dateOptions} value={typeVal} onChange={onTypeChange} />

          <div style={selectBarStyle}>
            <AccountBookOutlined />
            {isAccount ? <Cascader
              {...cascaderProps}
              value={_.get(accountVal, 'account', '')}
              options={_.get(accountOptions, 'accountOptions')}
              onChange={(v) => onAccountChange('account', v)}
            /> : <Cascader  {...cascaderProps}
              value={_.get(accountVal, pageKey, '')}
              options={_.get(accountOptions, 'productOptions')}
              onChange={(v) => onAccountChange(pageKey, v)}
            />}
          </div>

          {typeVal !== 'TODAY' && !isAccount && <Text type='secondary'>{'基金'}</Text>}
          {typeVal !== 'TODAY' && !isAccount && <FundSearch
            onFundChange={(v) => onAccountChange('fund', v)}
          />}

          <Text type='secondary'>{'指'}</Text>
          <IndexSelect active={typeVal} idv={idxSymbol} onIndexChange={onIdxChange} />

          <div>
            {!isAccount && pageKey !== 'virtual' && <Select size='small' disabled={disCompare} options={stepOptions}
              value={stepVal} onChange={onStepChange}
            />}
            {!isAccount && pageKey !== 'virtual' && <Button size='small' type='primary' ghost
              disabled={disCompare} onClick={onCompare}
            >
              对比
            </Button>}
          </div>
          {!isAccount && <Button size='small' type={diffStatus ? 'primary' : 'default'}
            onClick={onDiffChange}
          >
            差异
          </Button>}

          <Button size='small' type='text' ghost danger icon={<ClearOutlined />}
            style={{ marginLeft: 4 }}
            disabled={isClearDisable}
            onClick={onClear}
          />
        </Space>
      </Col>
      <Col {...barColRight}>
        <Space>
          <Segmented size='small' options={vOptions} value={volType} onChange={onVolTypeChange} />

          {volType === 'vol' && <div style={selectBarStyle}>
            {maOptions.map((ma, i) => <CheckableTag
              key={ma.value}
              checked={_.includes(maVal, ma.value)}
              onChange={(checked) => onMaChange(i, checked ? ma.value : 0)}
            >
              {ma.label}
            </CheckableTag>)}
          </div>}
        </Space>
      </Col>
    </Row>


    <div style={controlBarStyle}>
      <Checkbox.Group
        style={{ width: '100%' }} value={checkVal} onChange={onCheckChange}
      >
        <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
          {checkItems.map((itm, i) => {
            const textProps = _.get(compareObj, 'show') ? { style: { color: _.get(compareObj, `${itm.keyId}.color`, 'black') } } : {}
            const get_kid = _.get(itm, 'keyId', '');
            return <Col span={3} key={i}>
              <Space size='small'>
                <Checkbox value={get_kid}>
                  <Text {...textProps}>{_.get(itm, 'name')}</Text>
                  {_.get(itm, 'isrdiff', false) && <Tag color="#005b7b">差异</Tag>}
                </Checkbox>
                {!_.includes(get_kid, Vstring) && !isValidNumber(parseInt(get_kid)) && <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => onDelItem(itm)}
                >
                  <CloseCircleOutlined style={{ color: '#a1a1a1' }} />
                </div>}
              </Space>
            </Col>
          })}
        </Row>
      </Checkbox.Group>
    </div>
  </>
}

export default TwoBars;