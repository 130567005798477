import React, { useEffect, useState } from 'react';
import { Card, Col, Row, List, Slider, Button, Input, Cascader, Modal, message, InputNumber, Tooltip, Select, Table, Tag } from 'antd';
import { getBalanceFlow, getTransBalanceFlow, postCashTransfer, getAccountSubs, changeTransfer } from '@/api/workbench';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import PhoneVerify from '@/view/common/PhoneVerify';
import { getVerifPhoneCode, getUserPhone, postVerifyPhone } from '@/api/auto';
import { showProductSecuritiesAndSubHistory } from '@/api/details';
import _ from 'lodash';

const { Option } = Select;
export default function FundAdm(props) {
    const mobxStore = useLocalObservable(() => store);

    const [list, setlist] = useState([]);
    const [listBottom, setlistBottom] = useState([]);
    const [subList, setsubList] = useState(JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
    const [subListBottom, setsubListBottom] = useState([]);
    const [subData, setsubData] = useState(null);
    const [subDataBottom, setsubDataBottom] = useState(null);
    const [cascaderValue, setcascaderValue] = useState([]);
    const [cascaderValueLeft, setcascaderValueLeft] = useState([]);
    const [cascaderValueBottom, setcascaderValueBottom] = useState([]);
    const [cascaderValueBottomLeft, setcascaderValueBottomLeft] = useState([]);
    const [sliderValue, setsliderValue] = useState(0); // 进度条
    const [sliderValueBottom, setsliderValueBottom] = useState(0); // 进度条
    const [sliderFunds, setsliderFunds] = useState(0); // 资金划转证券账户总资产
    const [sliderFundsConfirm, setsliderFundsConfirm] = useState(0); // 资金确认证券账户总资产
    const [sliderSubAccount, setsliderSubAccount] = useState(0); // 子账户总资产
    const [valueBottom, setvalueBottom] = useState(0);
    const [enDisabled, setenDisabled] = useState(true);
    const [phoneVisible, setphoneVisible] = useState(false);
    const [vCount, setvCount] = useState(0);
    const [userPhone, setuserPhone] = useState(null);
    const [firstValue, setfirstValue] = useState(0);
    const [secondValue, setsecondValue] = useState(0);
    const [verifyBack, setverifyBack] = useState(0);
    const [historyData, setHistoryData] = useState([]);

    const columns = [
        {
            title: '账户',
            align: 'center',
            dataIndex: 'customerNumber',
        },
        {
            title: '总资产',
            align: 'center',
            dataIndex: 'totalAsset',
            render: (text, record, index) => {
                return <div>{parseFloat(text).toFixed(2)}</div>
            },
        },
        {
            title: '可用资产',
            align: 'center',
            dataIndex: 'availableBalance',
            render: (text, record, index) => {
                return <div>{parseFloat(text).toFixed(2)}</div>
            },
        },
    ]

    useEffect(() => {
        // console.log('sub', JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
        async function _get() {
            const data1 = await getTransBalanceFlow({ subAccountId: null });
            const data2 = await getBalanceFlow({ securitiesAccountId: null });
            setlist(data1.data);
            setlistBottom(data2.data);
        }
        _get();
        async function _getAccBottom() {
            const data = await getAccountSubs();
            if (data.code === '200') {
                let arr = data.data;
                arr.map((item, key) => {
                    item.label = item.productName
                    item.value = key
                    item.children = item.securitiesAccountList
                    item.children.map((item2, key2) => {
                        item2.label = item2.securitiesAccountName
                        item2.value = key2
                        return item2
                    })
                    return item;
                })
                setsubListBottom(arr);
            }
        }
        _getAccBottom();
        async function _getPhone() {
            let userInfo = JSON.parse(JSON.stringify(mobxStore.userInfo));
            const data = await getUserPhone({ params: { email: userInfo.email } });
            if (data.code === '200') {
                setuserPhone(data.data.phone);
            }
        }
        _getPhone();
    }, [mobxStore])

    async function _getAcc() {
        const data = await getAccountSubs();
        // console.log('subs', data);
        if (data.code === '200') {
            // 将证券集合数据加工成适配级联选择器的数据 value为key
            let arr = data.data;
            arr.map((item, key) => {
                item.label = item.productName
                item.value = key
                item.children = item.securitiesAccountList
                item.children.map((item2, key2) => {
                    item2.label = item2.securitiesAccountName
                    item2.value = key2
                    item2.children = item2.subAccountList
                    item2.children.map((item3, key3) => {
                        item3.label = item3.subAccountName
                        item3.value = key3
                        return item3
                    })
                    return item2
                })
                return item;
            })
            mobxStore._mobxAccountSubs(arr);
            setsubList(arr);
            let data1 = null;
            let data2 = null;;
            if (cascaderValueLeft.length === 0) {
                data1 = await getTransBalanceFlow({ subAccountId: null });
            } else {
                data1 = await getTransBalanceFlow({ subAccountId: subList[cascaderValueLeft[0]].children[cascaderValueLeft[1]].children[cascaderValueLeft[2]].subAccountId });
            }
            if (cascaderValueBottomLeft.length === 0) {
                data2 = await getBalanceFlow({ securitiesAccountId: null });
            } else {
                data2 = await getBalanceFlow({ securitiesAccountId: subListBottom[cascaderValueBottomLeft[0]].children[cascaderValueBottomLeft[1]].securitiesAccountId });
            }
            setlist(data1.data);
            setlistBottom(data2.data);
        }
    }


    function handleChange(value) {
        if (value.length === 0) {
            setsubData(null);
            setcascaderValue([]);
            return;
        }
        if (value.length === 3) {
            setcascaderValue(value);
            // 根据 key 找到当前选中的子账户
            let data = subList[value[0]].children[value[1]].children[value[2]];
            // console.log(111, data);
            setsubData(data);
            setsliderValue(0);
            setsliderValueBottom(0);
            setsliderFunds(subList[value[0]].children[value[1]].securitiesBalance);
            setsliderSubAccount(subList[value[0]].children[value[1]].children[value[2]].subAccountBalance);
        } else {
            message.error('该证券账户下暂无子账户！');
        }
    }
    async function handleTableChange(value) {
        const data = await showProductSecuritiesAndSubHistory({ productId: value });
        //console.log('handleTableChange', data)
        if (data.code === '200') {
            setHistoryData(_.get(data, 'data.securitiesAccounts', []))
        }
    }
    async function handleChangeLeft(value) {
        if (value.length === 3) {
            setcascaderValueLeft(value);
            let subAccount = subList[value[0]].children[value[1]].children[value[2]].subAccountId;
            const data = await getTransBalanceFlow({ subAccountId: subAccount });
            if (data.code === '200') {
                setlist(data.data);
            } else {
                message.error(data.message);
            }
        } else {
            message.error('该证券账户下暂无子账户！');
        }
    }
    function handleChangeBottom(value) {
        if (value.length === 0) {
            setsubDataBottom(null);
            setcascaderValueBottom([]);
            return;
        }
        if (value.length === 2) {
            setcascaderValueBottom(value);
            let data = subListBottom[value[0]].children[value[1]];
            setsubDataBottom(data);
            setvalueBottom(0);
            setsliderFundsConfirm(subList[value[0]].children[value[1]].securitiesBalance);
        } else {
            message.error('该产品下暂无证券账户！');
        }
    }
    async function handleChangeBottomLeft(value) {
        if (value.length === 2) {
            setcascaderValueBottomLeft(value);
            let securitiesAccountId = subListBottom[value[0]].children[value[1]].securitiesAccountId;
            const data = await getBalanceFlow({ securitiesAccountId: securitiesAccountId });
            if (data.code === '200') {
                setlistBottom(data.data);
            } else {
                message.error(data.message);
            }
        } else {
            message.error('该产品下暂无证券账户！');
        }
    }
    async function _phoneVerify(valuesCode) {
        // console.log(111, valuesCode);
        const data = await postVerifyPhone({ account: valuesCode.phone, verifyCode: valuesCode.phoneCode });
        if (data.code === '200') {
            // setvaluesCode(valuesCode);
            setphoneVisible(false);
            if (verifyBack === 1) {
                let account = subListBottom[cascaderValueBottom[0]].children[cascaderValueBottom[1]]
                let params = {
                    securitiesAccountId: account.securitiesAccountId,
                    balance: valueBottom,
                    phone: valuesCode.phone,
                    code: valuesCode.phoneCode,
                    direction: 1
                }
                const data = await changeTransfer(params);
                if (data.code === '200') {
                    message.success("入资成功");
                    _getAcc();
                    setcascaderValueBottom([]);
                    setvalueBottom(0);
                } else {
                    message.error(data.message);
                }
            } else if (verifyBack === 2) {
                let account = subListBottom[cascaderValueBottom[0]].children[cascaderValueBottom[1]]
                let params = {
                    securitiesAccountId: account.securitiesAccountId,
                    balance: valueBottom,
                    phone: valuesCode.phone,
                    code: valuesCode.phoneCode,
                    direction: 2
                }
                const data = await changeTransfer(params);
                if (data.code === '200') {
                    message.success("出资成功");
                    _getAcc();
                    setcascaderValueBottom([]);
                    setvalueBottom(0);
                } else {
                    message.error(data.message);
                }
            }
        } else {
            message.error(data.message);
        }
    }
    async function submitIn() {
        if (valueBottom === 0) {
            return message.error('变动资金量不能为0');
        }
        setverifyBack(1);
        setphoneVisible(true);
        setvCount(vCount + 1)
    }
    async function submitOut() {
        if (valueBottom === 0) {
            return message.error('变动资金量不能为0');
        }
        setverifyBack(2);
        setphoneVisible(true);
        setvCount(vCount + 1)
    }
    // 点击确认资金划转
    async function _assetsTransfer() {
        if (!subData) return message.error('请选择要交易的子账户');
        // 要划转的资金总量 产品 证券账户 子账户
        let num = firstValue;
        let product = subList[cascaderValue[0]];
        let account = subList[cascaderValue[0]].children[cascaderValue[1]];
        let subAccount = subList[cascaderValue[0]].children[cascaderValue[1]].children[cascaderValue[2]];
        if (num === 0) return message.error('交易资金量不能为0');
        Modal.confirm({
            title: '是否执行划转资金量的操作！',
            content: `${account.securitiesAccountName} -- ${subAccount.subAccountName} 划转资金 ${num}`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let params = {
                    productId: product.productId,
                    securitiesAccountId: account.securitiesAccountId,
                    subAccountId: subAccount.subAccountId,
                    balance: num,
                    direction: '2'
                }
                const data = await postCashTransfer(params);
                if (data.code === '200') {
                    _getAcc();
                    setsubData(null);
                    setcascaderValue([]);
                    setsliderFunds(0);
                    setsliderSubAccount(0);
                    message.success('划转成功');
                } else {
                    message.error(data.message);
                }
            }
        });
    }
    //子账户划转到证券账户
    async function _assetsTransferBottom() {
        if (!subData) return message.error('请选择要交易的子账户');
        // 要划转的资金总量 产品 证券账户 子账户
        let num = secondValue;
        let product = subList[cascaderValue[0]];
        let account = subList[cascaderValue[0]].children[cascaderValue[1]];
        let subAccount = subList[cascaderValue[0]].children[cascaderValue[1]].children[cascaderValue[2]];
        if (num === 0) return message.error('交易资金量不能为0');
        Modal.confirm({
            title: '是否执行划转资金量的操作！',
            content: `${subAccount.subAccountName} -- ${account.securitiesAccountName} 划转资金 ${num}`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let params = {
                    productId: product.productId,
                    securitiesAccountId: account.securitiesAccountId,
                    subAccountId: subAccount.subAccountId,
                    balance: num,
                    direction: 1
                }
                const data = await postCashTransfer(params);
                if (data.code === '200') {
                    _getAcc();
                    setsubData(null);
                    setcascaderValue([]);
                    setsliderFunds(0);
                    setsliderSubAccount(0);
                    message.success('划转成功');
                } else {
                    message.error(data.message);
                }
            }
        });

    }

    const expandedRowRender = (fatRecord) => {
        const columnsChildren = [
            {
                title: '子账户',
                dataIndex: 'name',
                render: (text, record, index) => {
                    return <div><Tag color="blue">{text}</Tag></div>
                }
            },
            {
                title: '资产',
                dataIndex: 'totalAsset',
                sorter: (a, b) => b.totalAsset - a.totalAsset,
                render: (text, record, index) => {
                    return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
                }
            },
            {
                title: '净值',
                dataIndex: 'netValue',
                sorter: (a, b) => b.netValue - a.netValue,
                render: (text, record, index) => {
                    return <div><Tag color="blue">{parseFloat(text).toFixed(4)}</Tag></div>
                }
            },
            {
                title: '收益',
                dataIndex: 'totalRevenue',
                sorter: (a, b) => b.totalRevenue - a.totalRevenue,
                render: (text, record, index) => {
                    return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
                }
            },
            {
                title: '日收益',
                dataIndex: 'dailyRevenue',
                align: 'center',
                sorter: (a, b) => b.dailyRevenue - a.dailyRevenue,
                render: (text, record, index) => {
                    return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
                },
            },
            {
                title: '可用资金',
                dataIndex: 'availableBalance',
                sorter: (a, b) => b.availableBalance - a.availableBalance,
                render: (text, record, index) => {
                    return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
                },
            },
            {
                title: '交易员',
                dataIndex: 'traderName',
                align: 'right',
                render: (text, record, index) => {
                    return (
                        <><Tag color="blue">{text}</Tag></>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: 'right',
                render: (text, record, index) => {
                    let newText = ''
                    if (text === 1) {
                        newText = '托管中'
                    } else if (text === 0) {
                        newText = '停用'
                    } if (text === 2) {
                        newText = '已删除'
                    }
                    return (
                        <><Tag color="blue">{newText}</Tag></>
                    )
                }
            },
            {
                title: '操作',
                align: 'right',
                render: (text, record, index) => {
                    return <Button type="link" onClick={() => _goFundManagerListsDetails(record)}>查看</Button>
                }
            }
        ]

        return (
            <Table
                rowKey="id"
                dataSource={_.get(fatRecord, 'subAccountList', [])}
                columns={columnsChildren}
                pagination={false}
            />
        )
    }

    function _goFundManagerListsDetails(record) {
        record.subAccountName = record.name;
        record.subAccountId = record.id;
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        if (record.assetsType === '1') {
            props.history.push({
                pathname: '/home/listsDetails',
                state: { subAccountInfo: record }
            });
        } else if (record.assetsType === '2') {
            props.history.push({
                pathname: '/home/fundManagerListsDetails',
                state: { subAccountInfo: record }
            });
        }
    }

    return (
        <Observer>
            {() => (
                <div className="contentView">
                    <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
                        <Col span={24}>
                            <Card title="历史托管记录" style={{ minHeight: 450 }} extra={
                                <Row justify="end" align="middle">
                                    <span style={{ marginRight: 8 }}>选择产品:</span>
                                    <Select style={{ width: 220 }} onChange={handleTableChange}>
                                        {_.isArray(subList) && subList.map((n, i) => <Option value={n.productId}>{n.productName || ''}</Option>)}
                                    </Select>
                                </Row>
                            }>
                                <Table
                                    rowKey="id"
                                    dataSource={historyData}
                                    columns={columns}
                                    bordered
                                    expandable={{
                                        expandedRowRender,
                                    }}
                                    pagination={false}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card title="本月资金划转一览" style={{ minHeight: 450 }} extra={
                                <Row justify="end" align="middle">
                                    <span>选择产品:</span>
                                    <Cascader
                                        options={subList}
                                        onChange={handleChangeLeft}
                                        value={cascaderValueLeft}
                                        style={{ width: 270, marginLeft: 10 }}
                                    />
                                </Row>
                            }>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={list}
                                    pagination={{
                                        defaultPageSize: 5,
                                        hideOnSinglePage: true,
                                    }}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                // title={<>{item.title}</>}
                                                description={
                                                    // <Row style={{ color: '#000' }}>
                                                    //     <Col>{item.name}</Col>
                                                    //     <Col className="colorGrey" style={{ marginLeft: 20 }}>{item.date}</Col>
                                                    // </Row>
                                                    <>
                                                        {/* <div>
                                                 {item}
                                             </div> */}
                                                        <Input.TextArea
                                                            bordered={false}
                                                            value={item}
                                                            autoSize={true}
                                                        />
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>
                            <br />
                            <Card title="本月资金确认一览" style={{ minHeight: 100 }} extra={
                                <Row justify="end" align="middle">
                                    <span>选择证券账户:</span>
                                    <Cascader
                                        options={subListBottom}
                                        onChange={handleChangeBottomLeft}
                                        value={cascaderValueBottomLeft}
                                        style={{ width: 200, marginLeft: 10 }}
                                    />
                                </Row>
                            }>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={listBottom}
                                    pagination={{
                                        defaultPageSize: 5,
                                        hideOnSinglePage: true,
                                    }}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                description={
                                                    <>
                                                        <Input.TextArea
                                                            bordered={false}
                                                            value={item}
                                                            autoSize={true}
                                                        />
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="资金划转" style={{ minHeight: 450, width: '100%' }}>
                                <Row justify="start" align="middle">
                                    <span style={{ width: '15%', textAlign: 'left' }}>选择产品:</span>
                                    <Cascader
                                        options={subList}
                                        onChange={handleChange}
                                        value={cascaderValue}
                                        style={{ width: '80%' }}
                                    />
                                </Row>

                                {
                                    subData &&
                                    <>
                                        <Row style={{ marginTop: 40 }}>
                                            <span style={{ color: 'red', marginRight: 5 }}>*</span>证券账户到子账户
                                        </Row>
                                        <Row align="middle" style={{ marginTop: 10 }}>
                                            证券账户总资金<span style={{ marginLeft: 5, marginRight: 5, color: 'green' }}>{Math.floor(sliderFunds * 10000) / 10000}</span>
                                            <Slider
                                                style={{ width: '35%' }}
                                                onChange={(value) => { setsliderValue(value); setfirstValue(sliderFunds * (value / 100)) }}
                                                value={typeof sliderValue === 'number' ? sliderValue : 0}
                                            />
                                            <span>{sliderValue + '%'}</span>
                                            <span style={{ marginLeft: 5, marginRight: 5 }}>划转资金:</span>
                                            <Tooltip title={firstValue}>
                                                <InputNumber min={0} value={firstValue} onChange={e => { setfirstValue(e) }} />
                                            </Tooltip>
                                        </Row>
                                        <br />
                                        <Row justify="center">
                                            <span>子账户可用资金  (划转前:{sliderSubAccount})</span>
                                            <span style={{ marginLeft: 10 }}>子账户可用资金  (划转后{sliderSubAccount + (sliderFunds * (sliderValue / 100))})</span>
                                        </Row>
                                        <br />
                                        <Row justify="center">
                                            <Button type="primary" onClick={_assetsTransfer}>提交</Button>
                                        </Row>
                                        <Row style={{ marginTop: 10 }}>
                                            <span style={{ color: 'red', marginRight: 5 }}>*</span>子账户到证券账户
                                        </Row>
                                        <Row align="middle" style={{ marginTop: 10 }}>
                                            子账户总资金<span style={{ marginLeft: 5, marginRight: 5, color: 'green' }}>{sliderSubAccount}</span>
                                            <Slider
                                                style={{ width: '35%' }}
                                                onChange={(value) => { setsliderValueBottom(value); setsecondValue(sliderSubAccount * (value / 100)) }}
                                                value={typeof sliderValueBottom === 'number' ? sliderValueBottom : 0}
                                            />
                                            <span>{sliderValueBottom + '%'}</span>
                                            <span style={{ marginLeft: 5, marginRight: 5 }}>划转资金:</span>
                                            <Tooltip title={secondValue}>
                                                <InputNumber min={0} value={secondValue} onChange={e => { setsecondValue(e) }} />
                                            </Tooltip>
                                        </Row>
                                        <br />
                                        <Row justify="center">
                                            <span>证券账户可用资金  (划转前:{sliderFunds})</span>
                                            <span style={{ marginLeft: 10 }}>证券账户可用资金  (划转后:{sliderFunds + (sliderSubAccount * (sliderValueBottom / 100))})</span>
                                        </Row>
                                        <br />
                                        <Row justify="center">
                                            <Button type="primary" onClick={_assetsTransferBottom}>提交</Button>
                                        </Row>
                                    </>
                                }

                            </Card>
                            <br />
                            <Card title="资金确认" style={{ minHeight: 150, width: '100%' }}>
                                <Row justify="start" align="middle">
                                    <span>选择证券账户:</span>
                                    <Cascader
                                        options={subListBottom}
                                        onChange={handleChangeBottom}
                                        value={cascaderValueBottom}
                                        style={{ width: '80%', marginLeft: 10 }}
                                    />
                                </Row>
                                {
                                    subDataBottom &&
                                    <>
                                        <Row align="middle" style={{ marginTop: 40 }}>
                                            证券账户总资金<span style={{ marginLeft: 10, color: 'green' }}>{sliderFundsConfirm}</span>
                                        </Row>
                                        <Row style={{ marginTop: 40 }}>
                                            <span style={{ textAlign: 'left', marginRight: 10 }}>
                                                证券账户资金变动:
                                            </span>
                                            <InputNumber min={0}
                                                step={10000}
                                                value={valueBottom}
                                                onChange={value => {
                                                    setvalueBottom(value);
                                                    if (value >= 10000) {
                                                        setenDisabled(false)
                                                    } else { setenDisabled(true) }
                                                }
                                                }
                                            />
                                            <Button type="primary"
                                                shape="circle"
                                                icon={<PlusOutlined />}
                                                style={{ marginLeft: 10 }}
                                                onClick={() => {
                                                    setvalueBottom(valueBottom + 10000);
                                                    if (valueBottom >= 0) {
                                                        setenDisabled(false);
                                                    }
                                                }}
                                            />
                                            <Button type="primary"
                                                shape="circle"
                                                icon={<MinusOutlined />}
                                                onClick={() => {
                                                    setvalueBottom(valueBottom - 10000);
                                                    if (valueBottom <= 10000) {
                                                        setenDisabled(true);
                                                    }
                                                }}
                                                style={{ marginLeft: 10 }}
                                                disabled={enDisabled}
                                            />
                                            <Button
                                                type='primary'
                                                style={{ marginLeft: 40, borderRadius: '7px' }}
                                                onClick={submitIn}
                                            >入资</Button>
                                            <Button
                                                type='primary'
                                                style={{ marginLeft: 20, borderRadius: '7px' }}
                                                onClick={submitOut}
                                            >出资</Button>
                                            <PhoneVerify
                                                vCount={vCount}
                                                visible={phoneVisible}
                                                phoneHandleCancel={() => setphoneVisible(false)}
                                                gobackValues={_phoneVerify}
                                                getCode={getVerifPhoneCode}
                                                userPhone={userPhone}
                                            />
                                        </Row>
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </div>

            )}
        </Observer>
    )
}