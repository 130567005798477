import React, { useState } from 'react';
import { Col, Row, Modal, InputNumber, message } from 'antd';
import { getfundManagerAssets, createTemporaryRulesEasy } from '@/api/workbench'; //showSubAccountRules, getFirstRiskControl
import { visShowAssetsAndProducts } from '@/api/reviewApi';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { useUnmount, useMount, useReactive } from 'ahooks';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
// import { cacheStore } from '@/store/mobx/cache.js';
import { HOME_PRODUCT_NUMBER, autoCol, validateResponse } from '@/utils/utils';
import { saveStorage, labelIncludesFalse } from '@/utils/utils2';
import AstHeader from './Components/AstHeader';
// import Achieve from './Components/Achieve';
// import Alerts from './Components/Alerts';
// import Traders from './Components/Traders';
import HomeCard from './Components/HomeCard';
import _ from 'lodash';
import './assets.scss'

// 创建homecard内部排序的时间字段，date字段为需要diff计算的数据字段
const createResultTimeKey = (datas) => {
  return datas.map(n => 'latestDate' in n ? _.assign(n, { 'timekey': moment(_.get(n, 'latestDate')).diff(moment(), 'd') }) : n);
}

let assets = null; let timer2 = null;
export default withRouter(function AssetsShow(props) {
  const mobxStore = useLocalObservable(() => store);
  // const cacheMobxStore = useLocalObservable(() => cacheStore);
  const [assetss, setassetss] = useState({ currentProductResultList: [] });
  const [riskMessage] = useState([]);
  // const [riskHour, setriskHour] = useState(0);
  const [riskValue, setriskValue] = useState(0);
  // const [riskState, setriskState] = useState(false);
  const [dealDisabled, setdealDisabled] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [glbAssets] = useState(JSON.parse(JSON.stringify(mobxStore.assetsData)));
  const [userRole] = useState(_.get(JSON.parse(JSON.stringify(mobxStore.userInfo)), 'role', ''));
  const [homeResult, setHomeResult] = useState({});
  const assetState = useReactive({ isShow: true, spin: false });
  let isUnmont = false;

  useMount(() => {
    const isGlobalData = _.get(glbAssets, 'assets', null);
    //const urlFrom = getStorage('1');
    // home 从登录或者点击左侧menu，路由状态会携带{from:'home'},则进行更新，否则使用缓存
    if (!!isGlobalData) {
      const curResult = createResultTimeKey(_.get(glbAssets, 'assets.currentProductResultList', []));
      setassetss(_.get(glbAssets, 'assets'));
      setHomeResult({
        'testing': _.filter(curResult, o => !labelIncludesFalse(_.get(o, 'productName'))),
        'results': _.filter(curResult, o => labelIncludesFalse(_.get(o, 'productName'))),
      });
    }
    _gets();

    mobxStore._getProductAccount();
    mobxStore._showVirtualProduct();
    mobxStore._isAllStockHoliday();
    timer2 = setTimeout(() => {
      const status = _.get(JSON.parse(JSON.stringify(mobxStore.tradingObj)), 'status', '') === 'trading' ? true : false;
      if (status) {
        _intervalGet();
      }
    }, 2000);
  });

  useUnmount(() => {
    assets && clearInterval(assets);
    timer2 && clearTimeout(timer2);
    isUnmont = true;
  });

  async function _gets() {
    assetState.spin = true;
    let params = {
      isShow: assetState.isShow,
      pageNum: 1,
      pageSize: HOME_PRODUCT_NUMBER
    }
    let globalAssets = {};
    const res = userRole === 'visitor' ? await visShowAssetsAndProducts() : await getfundManagerAssets(params);
    if (validateResponse(res, isUnmont)) {
      if (res.data !== null && res.data.currentProductResultList.length !== 0) {
        res.data.currentProductResultList.map(item => {
          item.totalRevenueRate = parseFloat(item.totalRevenueRate).toFixed(2) + '%';
          item.totalRevenueRate_float = parseFloat(item.totalRevenueRate);
          item.dailyRevenueRate = parseFloat(item.dailyRevenueRate).toFixed(2) + '%';
          item.dailyRevenueRate_float = parseFloat(item.dailyRevenueRate);
          return item;
        })
        res.data.totalRevenueRate = parseFloat(res.data.totalRevenueRate).toFixed(2) + '%';
        res.data.dailyRevenueRate = parseFloat(res.data.dailyRevenueRate).toFixed(2) + '%';
      }
      let newAssets = _.get(res, 'data', {});
      const getResultList = createResultTimeKey(_.get(newAssets, 'currentProductResultList', []));
      if (_.size(getResultList) === 0 && params.isShow === true) {
        assetState.isShow = false;
        _gets();
        return;
      }
      setassetss(newAssets);
      const newResult = _.filter(getResultList, o => labelIncludesFalse(_.get(o, 'productName')));
      const newTesting = _.filter(getResultList, o => !labelIncludesFalse(_.get(o, 'productName')))
      setHomeResult({ 'testing': newTesting, 'results': newResult });
      globalAssets.assetArr = newAssets;
      globalAssets.assets = newAssets;
      mobxStore.changeAssetsData(globalAssets);
      mobxStore.changeAllProducts(newResult);
      mobxStore.saveAccountCache([]);
    }
    assetState.spin = false;
  }

  async function _intervalGet() {
    assets = setInterval(
      async function () {
        if (document.getElementById("assetsShow") === null) {
          clearInterval(assets);
          return;
        }
        _gets();
      }, 30000);
  }

  // async function getRisk() {
  //     const data = await getFirstRiskControl();
  //     if (data.code === '200') {
  //         riskMessage[0] = data.data;
  //         if (riskMessage[0] !== null) {
  //             const sendTime = moment(data.data.sendTime);
  //             const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
  //             // setriskHour(nowTime.diff(sendTime, 'hours'));
  //             if (nowTime.diff(sendTime, 'minutes') > 30) {
  //                 setisDisabled(true);
  //             }
  //             // if (data.data.type === 'execute_risk_control') {
  //             //     setriskState(true);
  //             // }
  //         }
  //     }
  // }

  function _goAddProduct() {
    props.history.push('/home/AddProduct');
    saveStorage('1', 'AddProduct');
  }
  // 点击更多跳转产品列表
  function _goSubAccount() {
    saveStorage('1', 'subAccount');
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/subAccount',
      state: { assetss: assetss }
    });
  }
  function _onVirtualAdd() {
    saveStorage('1', 'addVirtuals');
    props.history.push('/home/addVirtuals');
  }
  function _goProductDetails(item) {
    saveStorage('1', 'ProductDetails');
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/ProductDetails',
      state: { paramsData: item }
    });
  }

  async function createTemp() {
    if (isDisabled) {
      setdealDisabled(false);
      return;
    }
    const data = await createTemporaryRulesEasy({ subAccountId: riskMessage[0].subAccountId, alertLine: riskValue });
    if (data.code === '200') {
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  }

  // async function dealWith() {
  //     const data = await showSubAccountRules({ subAccountId: riskMessage[0].subAccountId });
  //     if (data.code === '200') {
  //         setriskValue(data.data.alertLine);
  //         setdealDisabled(true);
  //         if (isDisabled === true) {
  //             message.info("已超过30分钟，不可修改预警线");
  //         }
  //     } else {
  //         message.error(data.message);
  //     }
  // }

  return (
    <div className="contentView" id="assetsShow">
      <Modal
        title="风控规则"
        visible={dealDisabled}
        onOk={createTemp}
        onCancel={() => { setdealDisabled(false) }}
      >
        <span style={{ marginRight: 20 }}>预警线:</span>
        <InputNumber min={0} value={riskValue} disabled={isDisabled} onChange={setriskValue} step={0.01} />
        <br /><br /><br />
        {isDisabled ? '' : <span style={{ color: 'red' }}>注意：此预警线设置后，两小时内有效，过期后自动恢复初始预警线，进入风控管理进行调整</span>}
      </Modal>

      <AstHeader
        role={userRole}
        assetss={assetss}
        onAdd={_goAddProduct}
        onVirtualAdd={_onVirtualAdd}
      />

      <br />

      <Row gutter={8}>
        <Col {...autoCol([24])} >
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <HomeCard
                homeItem={homeResult}
                loading={assetState.spin}
                goProductDetails={(item) => _goProductDetails(item)}
                goSubAccount={() => _goSubAccount()}
              />
            </Col>

            {/* <Col span={24}>
                            <Traders assetssArr={assetssArr} role={userRole} />
                        </Col> */}

          </Row>
        </Col>
        {/* <Col {...autoCol([8])} >
                    <Row gutter={[0, 16]}>

                        <Alerts
                            riskState={riskState}
                            riskHour={riskHour}
                            role={userRole}
                            riskMessage={riskMessage}
                            goRisk={_goFundManagerNewsRisk}
                            goNews={_goFundManagerNews}
                            dealWith={dealWith}
                        />

                        <Achieve assetssArr={assetssArr} assetss={assetss} />
                    </Row>
                </Col> */}
      </Row>
    </div>
  )
})