import React, { useState, memo } from 'react';
import { Card, message, Row, Input, Space, Typography, Switch } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useMount, useReactive, useUpdateEffect, useUnmount } from 'ahooks';
import { showList, getLatestTrade, getAllTrade } from '@/api/stock';
import { isValidArray, isValidString, createDatasTimeKey } from '@/utils/utils2';
import { KlineDrawer, NewRangePicker } from '@/view/common/widgets';
import TradeCharts from './Components/TradeCharts';
import RecordTable from './Components/RecordTable';
import PlateTable from './Components/PlateTable';
import moment from 'moment';
import _ from 'lodash';

const { Search } = Input;
const { Text } = Typography;
const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const TOMORROW_RANGE_TIME = [TODAY, moment().add(1, 'd').format('YYYY-MM-DD')]

const TAB_LIST = [
  { key: '1', tab: '记录' },
  { key: '2', tab: '统计' },
  { key: '3', tab: '统计+' },
  { key: '4', tab: '超额' },
  { key: '5', tab: '板块' },
];
/**
 * 交易记录；tab功能包含统计、板块、及需要获取额外接口的超额功能
 */
const TradeRecord = ({
  pageKey = '',
  subAccountId = 0,
  productId = 0,
  role = '',
  tradeStatus = 'rest',
  upCount = 0,
  initTime = [],
  strategyType = 0,
  newProps = {},
  onTimeChange,
  onSignal
}) => {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [orderList, setorderList] = useState({ list: [], rate: {}, org_list: [], exeRate: {}, plateList: [] });
  const [orderCount, setOrderCount] = useState(0);
  const [orderKey, setOrderKey] = useState('list');
  const [recordDate, setRecordDate] = useState([]);
  const [contentVal, setContentVal] = useState('');
  const allState = useReactive({ state: 'done', last: '', lastPlate: [], isTow: false });
  const [loading, setLoading] = useState(false);
  const [klineState, setKlineState] = useState({ show: false, record: {}, lists: [] });
  const isProductPage = pageKey === 'products' ? true : false;
  let isUnmont = false;

  useMount(() => {
    getTradeList();
  });

  useUnmount(() => {
    isUnmont = true;
  });
  // test-simx需拿到交易日期获取list数据，所以更新时须拿到上层exeTimes数据进行初始加载
  useUpdateEffect(() => {
    getTradeList(initTime);
    if (activeTabKey !== '1') {
      setActiveTabKey('1');
    }
  }, [upCount])

  function getTradeList() {
    let params = {
      subAccountId: subAccountId,
      beginTime: TOMORROW_RANGE_TIME[0],
      endTime: TOMORROW_RANGE_TIME[1],
      state: true,
    }
    let pro_params = { productId, 'beginTime': params.beginTime, 'endTime': params.endTime, 'state': params.state }
    allState.last = 'getTradeList';
    setRecordDate([params.beginTime, params.endTime]);
    onTimeChange([params.beginTime, params.endTime]);
    _showList(isProductPage ? pro_params : params, 'list');
  }

  function selectTime(dateString) {
    onTimeChange(dateString);
    setRecordDate(dateString);
    getTransData(dateString, null);
  }
  //根据时间和股票名称查询交易记录
  function selectRecord(value) {
    let newVal = value.replace(/(^\s*)|(\s*$)/g, "");
    setContentVal(newVal);
    getTransData(null, newVal);
  }
  //获取交易记录
  function getTransData(date, content) {
    const validGet = isProductPage ? productId : subAccountId;
    if (validGet) {
      let params = {
        subAccountId: subAccountId,
        state: allState.state === 'done' ? true : false,
        beginTime: date ? date[0] : recordDate[0],
        endTime: date ? date[1] : recordDate[1],
        content: content ?? contentVal,
        continuation: allState.isTow
      }
      allState.last = 'getTransData';
      params.endTime = moment(params.endTime).add(1, 'd').format('YYYY-MM-DD');// 获取默认多加一天，服务器查询到当日00:00
      let pro_params = {
        productId, 'beginTime': params.beginTime, 'endTime': params.endTime,
        'state': params.state, 'content': params.content, 'continuation': allState.isTow
      }
      _showList(isProductPage ? pro_params : params, 'search');
    }
  }
  // 获取记录及统计信息
  async function _showList(params, key) {
    setLoading(true);
    const res = isProductPage ? await getAllTrade(params) : await showList(params);
    if ((_.get(res, 'code', '') === '200' || _.get(res, 'code', '') === '205') && !isUnmont) {
      const getList = _.get(res, 'data.result', []);
      let stCodeObj = {}; let newList = []; let newOrgList = []; let keyCount = 0;
      let plateObj = {};
      if (isValidArray(getList)) {
        const newTimeKeyList = createDatasTimeKey(getList, 'commissionTime', 'fms', 'asc');
        newTimeKeyList.map(item => {
          const getObjCode = stCodeObj[item.stockCode];
          const split_plate = _.split(_.get(item, 'plate', ''), ','); // 板块拆分为数组
          const newItem = { ...item, 'profit': _.get(item, 'profit') ?? 0 }; // profit没有会返回null，字段空=0
          newOrgList.push(_.cloneDeep(newItem)); //【tips】深拷贝，否则继续执行以下合并方法
          if (!(item.stockCode in stCodeObj)) {
            newList[keyCount] = newItem;
            newList[keyCount].otherRecord = [];
            stCodeObj[item.stockCode] = keyCount;
            keyCount = keyCount + 1;
          } else {
            newList[getObjCode].otherRecord.push(newItem);
          }
          // 多个板块逗号拆分,板块内计算收益率和 及 个数数据
          if (isValidArray(split_plate)) {
            const getRate = parseFloat(_.get(newItem, 'rate') ?? 0);
            split_plate.map(a => {
              const plate_name = isValidString(a) ? a : '无板块';
              if (plate_name in plateObj) {
                _.set(plateObj, plate_name, { 'rate': plateObj[plate_name]['rate'] + getRate, 'total': plateObj[plate_name]['total'] + 1 });
              } else {
                _.set(plateObj, plate_name, { 'rate': getRate, 'total': 1 });
              }
            })
          }
        })
      }
      let newPlates = [];
      _.keys(plateObj).map((plname, idx) => {
        newPlates.push({ // 创建table数据，求平均
          name: plname,
          value: _.round(_.get(plateObj[plname], 'rate') / _.get(plateObj[plname], 'total'), 4),
          index: idx
        });
      });
      setorderList({
        'list': _.get(params, 'continuation', false) ? newOrgList : newList, // 两日条件开启用原始list
        'rate': _.get(res, 'data.rateResult', {}),
        'org_list': newOrgList,
        'exeRate': _.get(res, 'data.excessRateResult', {}),
        'plateList': newPlates
      });
      setOrderCount(orderCount + 1);
    } else {
      message.error(_.get(res, 'message', '获取错误！'));
    }
    setLoading(false);
    setOrderKey(key);
  }

  // 点击处理交易信号，上层跳转到交易信号页面
  async function handleSignal(record, sList = [], type = '') {
    const getOrgList = _.get(orderList, 'org_list', []);
    const getSymbol = _.get(record, 'stockCode');
    const filterList = isValidArray(sList) ? sList : _.filter(getOrgList, o => o.stockCode === getSymbol);
    if (isValidArray(filterList)) {
      let orders = _.orderBy(filterList, ['timeKey'], ['desc']);
      let timeArray = [];
      // 交易开始结束时间默认增加5天，增加时间周期
      let starts = _.get(_.head(orders), 'commissionTime'); // 统计图表获取上一次的交易时间
      if (type === 'charts') {  // 获取上一笔交易时间
        const res = await getLatestTrade({
          subAccountId: subAccountId,
          stockCode: _.get(record, 'stockCode', ''),
          tradeDate: moment(_.get(record, 'commissionTime', '')).format(FORMAT)
        });
        if (isValidString(_.get(res, 'data', 0))) {
          starts = _.get(res, 'data');
        }
      }
      const ADD_DAY = 14; // 前后增加的天数;
      let ends = _.get(_.last(orders), 'commissionTime');
      const endDiff = moment().diff(moment(ends), 'd');
      if (endDiff > ADD_DAY) { //交易日期>n天，取n天前日期
        ends = moment(ends).add(ADD_DAY, 'd').format(FORMAT);
      } else if (endDiff < ADD_DAY + 1 && endDiff > -1) { // n天内默认今天为最后时间
        ends = moment(TODAY).add(1, 'd').format(FORMAT);
      }
      timeArray[0] = starts ? moment(starts).subtract(ADD_DAY, 'd').format(FORMAT) : TODAY;
      timeArray[1] = ends || TODAY;
      onSignal(record, timeArray); // 传参record 和 交易的开始和结束时间
    }
  }

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const isVisitor = role === 'visitor' ? true : false;
  let newTabList = [TAB_LIST[0]];
  if (!isVisitor) { // 非访客；交易员可见
    newTabList = _.concat(newTabList, [TAB_LIST[1], TAB_LIST[2]]);
    if (strategyType === 1) { // 策略类型 = 1;
      newTabList = _.concat(newTabList, [TAB_LIST[4]]);
    } else if (strategyType === 2) {
      newTabList = _.concat(newTabList, [TAB_LIST[3]]);
    };
  }
  const SelectComp = <Row justify="end" align="middle" style={{ marginBottom: isMobile ? 10 : 1 }}>
    <Space size='small' direction={isMobile ? 'vertical' : 'horizontal'} align={isMobile ? 'end' : 'center'}>
      <Switch checkedChildren="两日" unCheckedChildren="两日" checked={allState.isTow}
        onChange={(checked) => {
          allState.isTow = checked;
          getTransData();
        }}
      />
      <NewRangePicker dates={recordDate} onSelect={(dateString) => selectTime(dateString)} />
      <Search placeholder="请输入股票代码/名称" allowClear enterButton style={{ width: 230, marginLeft: 10 }} onSearch={(v) => {
        selectRecord(v);
        onTabChange('1');
      }} />
    </Space>
  </Row>;
  return (
    <Card
      title={<Text strong style={{ fontSize: 15 }}>{'交易记录'}</Text>}
      bordered={true}
      style={{ height: activeTabKey === '3' ? 970 : 788, overflowY: activeTabKey === '3' ? 'scroll' : 'auto' }}
      size='small'
      tabList={newTabList}
      activeTabKey={activeTabKey}
      onTabChange={(key) => {
        onTabChange(key);
      }}
      extra={!isMobile && SelectComp}>
      {isMobile && SelectComp}

      {activeTabKey === '1' && <RecordTable
        orderKey={orderKey}
        loading={loading}
        isProduct={isProductPage}
        list={_.get(orderList, 'list', [])}
        onSignal={(record) => handleSignal(record)}
        showKline={(record) => {
          setKlineState({ show: true, lists: _.get(orderList, 'list', []), record });
        }}
      />}

      {_.includes(['2', '3', '4'], activeTabKey) && <div style={{ marginTop: 20 }}>
        <TradeCharts
          datas={_.get(orderList, 'rate', {})}
          exDatas={_.get(orderList, 'exeRate', {})}
          is_product={isProductPage}
          tradeStatus={tradeStatus}
          subId={subAccountId}
          active={activeTabKey}
          upCount={orderCount}
          onDetail={(record, sList) => handleSignal(record, sList, 'charts')}
          showKline={(record, dlist) => {
            setKlineState({ show: true, lists: dlist, record });
          }}
        />
      </div>}

      {activeTabKey === '5' && <PlateTable
        datas={_.get(orderList, 'plateList', [])}
        loading={loading}
      />}

      <KlineDrawer
        pageKey='record'
        show={_.get(klineState, 'show', false)}
        value={_.get(klineState, 'record', {})}
        lists={_.get(klineState, 'lists', [])}
        newProps={newProps}
        onClose={() => setKlineState({ show: false, record: {}, lists: [] })}
      />
    </Card>
  )
}

export default memo(TradeRecord, arePropsEqual);

function arePropsEqual(oldProps, newProps) {
  return oldProps.upCount === newProps.upCount;
};