import React, { useState, useEffect } from 'react';
import { Select,InputNumber,DatePicker,Cascader,Table,Button,Card,Row,Col,Input, message,Form,Modal } from 'antd';
import './Futuresmanagements.scss'
import {leftcontract,rightcontract,getStrategyOptions,filter,getScope,addFuturesPool,showFuturesPoolFutures,delFuturesPool } from '@/api/workbench';
import moment from 'moment';
import { PlusOutlined,MinusOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import FuturePoolList from '@/view/Home/AccountAdm/Futuresmanagements/FuturePoolList';
import AddFuture from '@/view/Home/AccountAdm/Futuresmanagements/AddFuture';


export default function Futuresmanagements(props) {
    const { Option } = Select;
    const [leftForm] = Form.useForm();
    const [centerForm] = Form.useForm();
    const [rightForm] = Form.useForm();
    const [leftOneForm] = Form.useForm();
    const [centerOneForm] = Form.useForm();
    const [rightOneForm] = Form.useForm();
    const { RangePicker } = DatePicker;
    const [startTimes, setstartTimes] = useState((moment().subtract('years',1)).format('YYYY-MM-DD'));
    const [endTimes, setendTimes] = useState(moment().format('YYYY-MM-DD'));
    const [limit, setlimit] = useState(0);
    const [kline, setkline] = useState('');
    const [leftContract, setleftContract] = useState('');
    const [rightContract, setrightContract] = useState('');
    const [getData, setgetData] = useState([]);
    const [leftData, setleftData] = useState([]);
    const [rightData, setrightData] = useState([]);
    const [list, setlist] = useState([]);
    const [centerList, setcenterList] = useState({});
    const [isModalVisible, setisModalVisible] = useState(false);
    const [inputValue, setinputValue] = useState('');
    const [listData, setlistData] = useState([]);
    const [futurePoolListIndex, setfuturePoolListIndex] = useState(0);
    const [futurePoolList, setfuturePoolList] = useState([]);

    const columns = [
        {
            title: '交易对',
            align: 'center',
            dataIndex: 'tradingPair',
        },
        {
            title: '收益/最大回撤',
            align: 'center',
            dataIndex: 'maxdrawdown',
            sorter: (a, b) => Number(a.maxdrawdown) - Number(b.maxdrawdown),
            render: (text) => (
                <>{text === null?'--':Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            title: '收益率',
            align: 'center',
            dataIndex: 'incomerate',
            sorter: (a, b) => Number(a.incomerate) - Number(b.incomerate),
            render: (text) => (
                <>{text === null?'--':Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            title: '夏普比率',
            align: 'center',
            dataIndex: 'sharpratio',
            sorter: (a, b) => Number(a.sharpratio) - Number(b.sharpratio),
            render: (text) => (
                <>{text === null?'--':Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            title: '价格相关性',
            align: 'center',
            dataIndex: 'correlation',
            sorter: (a, b) => Number(a.correlation) - Number(b.correlation),
            render: (text) => (
                <>{text === null?'--':Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            align: 'center',
            title: '计算k线数量',
            dataIndex: 'diffnumber',
            sorter: (a, b) => Number(a.diffnumber) - Number(b.diffnumber),
        },
        {
            align: 'center',
            title: '标准差倍数',
            dataIndex: 'stdmultiple',
            sorter: (a, b) => Number(a.stdmultiple) - Number(b.stdmultiple),
        },
        {
            title: '止损率',
            align: 'center',
            dataIndex: 'stoploss',
            sorter: (a, b) => Number(a.stoploss) - Number(b.stoploss),
            render: (text) => (
                <>{text === null?'--':Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            title: '止盈率',
            align: 'center',
            dataIndex: 'stopprofit',
            sorter: (a, b) => Number(a.stopprofit) - Number(b.stopprofit),
            render: (text) => (
                <>{text === null?'--':Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            title: '订单数',
            align: 'center',
            dataIndex: 'ordernumber',
            sorter: (a, b) => Number(a.ordernumber) - Number(b.ordernumber),
        },
        {
            title: '操作',
            align: 'center',
            width: 150,
            render: (text, record, index) => {
                return  <div>
                            <Button type='link' size='small' style={{marginRight:10,borderRadius:'7px'}}>保存</Button>
                            <Button type='link' size='small' style={{borderRadius:'7px'}}>查看</Button>
                        </div>
            }
        },
    ]
    const futureColumns = [
        {
            title: '名称',
            align: 'center',
            dataIndex: 'name',
        },
        {
            title: '代码',
            align: 'center',
            dataIndex: 'code',
            render: (text) => {
                return (text.indexOf('.')===-1?text:text.substring(0,text.indexOf('.')))
            },
        },
        {
            title: '类型',
            align: 'center',
            dataIndex: 'typeName',
        },
        {
            title: 'k线周期',
            align: 'center',
            dataIndex: 'kline',
            sorter: (a, b) => Number(a.kline) - Number(b.kline),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: 'K线数量',
            align: 'center',
            dataIndex: 'diffnumber',
            sorter: (a, b) => Number(a.diffnumber) - Number(b.diffnumber),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '波动率倍数',
            align: 'center',
            dataIndex: 'stdmultiple',
            sorter: (a, b) => Number(a.stdmultiple) - Number(b.stdmultiple),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '收益回撤比',
            align: 'center',
            dataIndex: 'incomedrawdown',
            sorter: (a, b) => Number(a.incomedrawdown) - Number(b.incomedrawdown),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '收益率',
            align: 'center',
            dataIndex: 'incomerate',
            sorter: (a, b) => Number(a.incomerate) - Number(b.incomerate),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
    ]
    useEffect(() => {
        async function showFuturesPoll(){
            const data = await showFuturesPoolFutures({accountType:'fundManager' });
            if(data.code === '200'){
                setlistData(data.data);
                if(data.data !== null && data.data.length !== 0){
                    const obj = data.data[0].futuresPoolFuturesContractList;
                    let arr = [];
                    if(data.data[0].futuresPoolFuturesContractList !== null && data.data[0].futuresPoolFuturesContractList.length !== 0){
                        for(let i in obj){
                            arr = arr.concat(obj[i]);
                        }
                    }
                    setfuturePoolList(arr);
                }else{
                    setfuturePoolListIndex(null);
                    setfuturePoolList([]);
                }
            }   
        }
        async function leftdefgetlist(value){
            let params = {
              kLine:value
            }
            const data = await leftcontract(params);
            const obj = data.data.data;
            var arr = [];
            for(let i in obj){
                var newData = {};
                newData.label = i;
                newData.value = i;
                newData.children = obj[i];
                obj[i].map((item) => {
                    item.label = item.code;
                    item.value = item.code;
                    return item;
                })
                arr.push(newData);
            }
            setleftData(arr);  
        }
        async function rightdefgetlist(value){
            let params = {
              kLine:value
            }
            const data = await rightcontract(params);
            const obj = data.data.data;
            var arr = [];
            for(let i in obj){
                var newData = {};
                newData.label = i;
                newData.value = i;
                newData.children = obj[i];
                obj[i].map((item,index) => {
                    item.label = item.code;
                    item.value = item.code;
                    return item;
                })
                arr.push(newData);
            }
            setrightData(arr);  
        }
        async function _getData() {
            const data = await getStrategyOptions(); 
            const data2 = await getScope({kLine:'1分钟K线'});
            setcenterList(data2.data.data);
            if (data.code === '200') { 
                setgetData(data.data.data);
                if(data.data.data !== null){
                    setlimit(data.data.data[0].limit);
                    setkline(data.data.data[0].kline)
                    leftForm.setFieldsValue(data.data.data[0]);
                    leftOneForm.setFieldsValue(data.data.data[0]);
                    rightForm.setFieldsValue(data.data.data[0]);
                    rightOneForm.setFieldsValue(data.data.data[0]);
                    leftdefgetlist(data.data.data[0].kline);
                    rightdefgetlist(data.data.data[0].kline);
                }
            }else{
                message.error(data.message);
            }
        }
        showFuturesPoll();
        _getData();
    }, [])
    async function showFuturesPoll(){
        const data = await showFuturesPoolFutures({accountType:'fundManager' });
        if(data.code === '200'){
            setlistData(data.data);
            if(data.data !== null && data.data.length !== 0){
                const obj = data.data[0].futuresPoolFuturesContractList;
                let arr = [];
                if(data.data[0].futuresPoolFuturesContractList !== null){
                    for(let i in obj){
                        arr = arr.concat(obj[i]);
                    }
                }
                setfuturePoolList(arr);
            }else{
                setfuturePoolListIndex(null);
                setfuturePoolList([]);
            }
        }   
    }
    async function _change(value) {
        setkline(value.value);
        setleftData([]);   
        setrightData([]);
        let params = {
            kLine:value.value
        }
        const data1 = await leftcontract(params);
        const data2 = await rightcontract(params); 
        const obj1 = data1.data.data;
        const obj2 = data2.data.data;
        var arr = [];
        var brr = [];
        for(let i in obj1){
            var newData1 = {};
            newData1.label = i;
            newData1.value = i;
            newData1.children = obj1[i];
            obj1[i].map((item1) => {
                item1.label = item1.code;
                item1.value = item1.code;
                return item1;
            })
            arr.push(newData1);
        }
        for(let i in obj2){
        var newData2 = {};
        newData2.label = i;
        newData2.value = i;
        newData2.children = obj2[i];
        obj2[i].map((item2) => {
            item2.label = item2.code;
            item2.value = item2.code;
            return item2;
        })
        brr.push(newData2);
        }
        setleftData(arr);   
        setrightData(brr); 
        setkline(value.value);
        const data3 = await getScope({kLine:value.value});
        if(data3.data.data === null) return;
        setcenterList(data3.data.data);
    }
    function getValue(e) {
        leftForm.setFieldsValue(getData[e]);
        leftOneForm.setFieldsValue(getData[e]);
        rightForm.setFieldsValue(getData[e]);
        rightOneForm.setFieldsValue(getData[e]);
        setlimit(getData[e].limit);
        setkline(getData[e].kline)
        let value = {};
        value.value = getData[e].kline;
        _change(value);
    }
    function onOk(dates, dateStrings) {
        setstartTimes(dateStrings[0]);
        setendTimes(dateStrings[1]);
    }
    async function toGetScope(value){
        setrightContract(value[1]);
        if(!leftContract){
            message.info('请先选择左交易对');
            return;
        }
        const data = await getScope({kLine:kline,tradingPair:leftContract+'_'+value[1]});
        if(data.data.code === '200'){
            setcenterList(data.data.data);
        }else{
            message.error(data.data.data);
        }   
    }
    async function handleOk() {
        const data = await addFuturesPool({ futuresPoolName: inputValue});
        if (data.code === '200') {
            message.success('保存成功');
            showFuturesPoll();
            setfuturePoolListIndex(listData.length);
            setfuturePoolList([]);
        } else {
            message.error(data.message);
        }
        setisModalVisible(false);
        setinputValue('');
    };
    async function retrieve(){
        if(leftContract && !rightContract){
            message.info('请选择右交易对');
            return;
        }
        if(rightContract && !leftContract){
            message.info('请选择左交易对');
            return;
        }
        const leftValues = await leftForm.validateFields();
        const leftOneValues = await leftOneForm.validateFields();
        const rightValues = await rightForm.validateFields();
        const rightOneValues = await rightOneForm.validateFields();
        let params = {
            limit: limit,
            kcycles: kline,
            startTimes: startTimes,
            endTimes: endTimes,
            tradingPair: leftContract+'_'+rightContract,
            minIncomerate: leftValues.minIncomerate,
            maxIncomerate: rightValues.maxIncomerate,
            minSharpratio: leftValues.minSharpratio,
            maxSharpratio: rightValues.maxSharpratio,
            minIncomedrawdown: leftValues.minIncomedrawdown,
            maxIncomedrawdown: rightValues.maxIncomedrawdown,
            minDiffnumber: leftOneValues.minDiffnumber,
            maxDiffnumber: rightOneValues.maxDiffnumber,
            minStdmultiple: leftOneValues.minStdmultiple,
            maxStdmultiple: rightOneValues.maxStdmultiple,
            minTakeProfitParameters: leftOneValues.minTakeProfitParameters,
            maxTakeProfitParameters: rightOneValues.maxTakeProfitParameters,
            minStopLossParameters: leftOneValues.minStopLossParameters,
            maxStopLossParameters: rightOneValues.maxStopLossParameters,
            minMaxRetracement: leftValues.minMaxRetracement,
            maxMaxRetracement: rightValues.maxMaxRetracement
        }
        const data = await filter(params);
        if(data.data.code === '200'){
            setlist(data.data.data);
        }else if(data.data.code === '205'){
            message.info('交易对不存在');
        }else{
            message.error(data.data.message);
        }
    }
    function onChange2(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }
    function changeIndex(e) {
        setfuturePoolListIndex(e);
        let arr = [];
        if(listData[e].futuresPoolFuturesContractList !== null){
            let obj = listData[e].futuresPoolFuturesContractList;
            for(let i in obj){
                arr = arr.concat(obj[i]);
            }
        }
        setfuturePoolList(arr);
    }
    async function delFuturePool() {
        Modal.confirm({
            title: '是否删除该期货池！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await delFuturesPool({ futuresPoolId: listData[futurePoolListIndex].futuresPoolId });
                if (data.code === '200') {
                    message.success('删除成功');
                    setfuturePoolListIndex(0);
                    showFuturesPoll();
                    
                } else {
                    message.error(data.message);
                }
            }
        });
    }
    return (
        <div className="contentView">
            <Card style={{ marginTop: 20 }}>
                <Card>
                    <Row>
                        <h2>我的期货池</h2>
                        <Select style={{marginLeft:20,width:180}} onChange={changeIndex} defaultActiveFirstOption='true' value={futurePoolListIndex}>
                            {
                                listData.length !== 0 && listData.map((item, index) => {
                                    return (
                                        <Option
                                            key={item.futuresPoolId}
                                            value={index}
                                        >
                                            {item.futuresPoolName}
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                        <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" onClick={()=>{setisModalVisible(true)}} style={{marginLeft:20}}/>
                        <Button type="primary" shape="circle" icon={<MinusOutlined />} size="large" onClick={delFuturePool} style={{marginLeft:20}}/>
                        <Modal title="期货池名称" visible={isModalVisible} onOk={handleOk} onCancel={() => {setisModalVisible(false)}}>
                            <Input placeholder="请输入要添加的期货池名称" value={inputValue} allowClear onChange={e => {setinputValue(e.target.value)}}/>
                        </Modal>           
                    </Row>
                    <Row justify="end">
                        <AddFuture _goBack={showFuturesPoll} futurePoolInfos={listData.length !== 0 && listData[futurePoolListIndex]} />
                        <FuturePoolList _goBack={showFuturesPoll} ban={false} futurePoolInfos={listData.length !== 0 && listData[futurePoolListIndex]}/>
                    </Row>
                </Card>
                <Table
                    rowKey="id"
                    dataSource={futurePoolList}
                    columns={futureColumns}
                    bordered
                    pagination={{
                        defaultPageSize: 10,
                    }}
                />
            </Card>
            <br/>
            {/* <Card>
                <Row className="headView">
                    <Col span={5} className="headViewCol">
                        <div>
                            <span style={{ marginRight: 10 }}>策略选择:</span>
                            <Select
                                style={{ width: 120 }}
                                defaultValue={0}
                                onChange={getValue}
                            >
                                {
                                    getData.length !== 0 &&
                                    getData.map((item,index) => {
                                        return (
                                            <Option
                                                key={item._id.timestamp}
                                                value={index}
                                            >
                                                {item.name}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </Col>
                    <Col span={5} className="headViewCol">
                        <div>
                            <span style={{ marginRight: 10 }}>展示数量:</span>
                            <InputNumber min={0} max={200} value={limit} onChange={setlimit} />
                        </div>
                    </Col>
                    <Col span={5} className="headViewCol">
                        <div>
                            <span style={{ marginRight: 10 }}>k线周期:</span>
                            <Select
                                labelInValue
                                value={{value:kline}}
                                style={{ width: 120 }}
                                onChange={_change}
                            >
                                <Option value="1分钟K线">1分钟K线</Option>
                                <Option value="5分钟K线">5分钟K线</Option>
                                <Option value="15分钟K线">15分钟K线</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span={8} className="headViewCol">
                        <div>
                            <span style={{ marginRight: 10 }}>选择日期:</span>
                            <RangePicker 
                                // style={{width:'400px'}}  
                                format='YYYY-MM-DD'
                                defaultValue={[moment(moment().subtract('years',1)),moment(moment())]}
                                onChange={onOk}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
            <br/> */}
            {/* <Card>
                <Row>
                    <span>交易对选择:</span>
                </Row>
                <br/>
                <Row>
                    <Cascader options={leftData} onChange={value => {setleftContract(value[1])}} placeholder="左合约" style={{ width:250,marginLeft: 250 }}/>
                    <Cascader options={rightData} onChange={toGetScope} placeholder="右合约" style={{ width:250,marginLeft: 150 }}/>
                </Row>

                <Row className='xias'>
                    <Col style={{marginTop:20}}>
                        <Form form={leftForm}>
                            <Form.Item
                                label=""
                                name='minIncomerate'  
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='minSharpratio'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='minIncomedrawdown'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='minMaxRetracement'
                            >
                                <Input className='input'/>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col style={{marginTop:20}}>
                        <Form form={centerForm}>
                            <Form.Item>
                                <Input className='inpts' value={'收益率     ('+(parseFloat(centerList.minIncomerate)*100).toFixed(2)+'%——'+(parseFloat(centerList.maxIncomerate)*100).toFixed(2)+'%)'}/>
                            </Form.Item>
                            <Form.Item>
                                <Input className='inpts' value={'夏普比率   ('+(parseFloat(centerList.minSharpratio)*100).toFixed(2)+'%——'+(parseFloat(centerList.maxSharpratio)*100).toFixed(2)+'%)'} />
                            </Form.Item>
                            <Form.Item>
                                <Input className='inpts' value={'收益回撤比   ('+parseFloat(centerList.minIncomedrawdown).toFixed(2)+'——'+parseFloat(centerList.maxIncomedrawdown).toFixed(2)+')'} />
                            </Form.Item>
                            <Form.Item >
                                <Input className='inpts' value={'最大回撤   ('+(parseFloat(centerList.minMaxRetracement)*100).toFixed(2)+'%——'+(parseFloat(centerList.maxMaxRetracement)*100).toFixed(2)+'%)'} />
                            </Form.Item>
                        </Form>  
                    </Col>
                    <Col style={{marginTop:20}}>
                        <Form form={rightForm}>
                            <Form.Item
                                label=""
                                name='maxIncomerate'  
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='maxSharpratio'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='maxIncomedrawdown'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='maxMaxRetracement'
                            >
                                <Input className='input'/>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col style={{marginTop:20,marginLeft:40}}>
                        <Form form={leftOneForm}>
                            <Form.Item
                                label=""
                                name='minDiffnumber'
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value) {
                                                if ((/^[0-9]*[0-9][0-9]*$/.test(value))) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('只能输入0或正整数'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='minStdmultiple'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='minTakeProfitParameters'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='minStopLossParameters'
                            >
                                <Input className='input'/>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col style={{marginTop:20}}>
                        <Form form={centerOneForm}>
                            <Form.Item>
                                <Input className='inpts' value={'计算参考K线数   ('+centerList.minDiffnumber+'——'+centerList.maxDiffnumber+')'} />
                            </Form.Item>
                            <Form.Item>
                                <Input className='inpts' value={'倍数  ('+centerList.minStdmultiple+'——'+centerList.maxStdmultiple+')'} />
                            </Form.Item>
                            <Form.Item>
                                <Input className='inpts' value={'止盈参数   ('+(parseFloat(centerList.minTakeProfitParameters)*100).toFixed(2)+'%——'+(parseFloat(centerList.maxTakeProfitParameters)*100).toFixed(2)+'%)'} />
                            </Form.Item>
                            <Form.Item>
                                <Input className='inpts' value={'止损参数   ('+(parseFloat(centerList.minStopLossParameters)*100).toFixed(2)+'%——'+(parseFloat(centerList.maxStopLossParameters)*100).toFixed(2)+'%)'}/>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col style={{marginTop:20}}>
                        <Form form={rightOneForm}>
                        <Form.Item
                                label=""
                                name='maxDiffnumber'
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value) {
                                                if ((/^[0-9]*[0-9][0-9]*$/.test(value))) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('只能输入0或正整数'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='maxStdmultiple'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='maxTakeProfitParameters'
                            >
                                <Input className='input'/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name='maxStopLossParameters'
                            >
                                <Input className='input'/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Button size='large' type='primary' onClick={retrieve} style={{marginTop:20,marginRight:30,width:'130px'}}>检索</Button>
                </Row>
            </Card> */}
            <br />
            <Card title="">
                <Table onChange={onChange2}  
                        rowKey={(record,index) => {return index}}
                        pagination={{ responsive: true, showSizeChanger: true, pageSizeOptions: [5, 10, 30, 50]}} 
                        bordered
                        columns={columns} 
                        dataSource={list} 
                        // data={(e) => {
                        //                 console.log(e, '666');
                        //                 this.setState({
                        //                 current:e.current,
                        //                 pageSize:e.pageSize
                        //                 })
                        // }} 
                />
            </Card>
        </div>
    )
}