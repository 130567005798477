import React, { useState } from 'react';
import { Row, Col, Select, Segmented } from 'antd';
import * as echarts from 'echarts';
import { COMMON_CHART } from '../Components/chartsInfoUtils';
import { useUpdateEffect } from 'ahooks';
import _ from 'lodash';

const { Option } = Select;

const OPTION_LINE = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  xAxis: {
    type: 'category',
    name: '时间',
    ...COMMON_CHART.rateX
  },
  yAxis: [
    COMMON_CHART.rateY,
  ],
  grid: {
    top: 85
  },
  series: [],
  legend: {
    selectedMode: 'multiple',
  },
  tooltip: {
    trigger: 'axis',
    showDelay: 0,
    axisPointer: {
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    },
    padding: 5,
  },
};

export default function DisplayCalCharts(props) {
  const [topSymbol, setTopSymbol] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [option2, setoption2] = useState(OPTION_LINE);

  useUpdateEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('display_cal_charts'));
    let newOption = option2;
    const getSeriesData = _.get(props, 'chartsDatas.datas', []);
    newOption.xAxis.data = _.get(props, 'chartsDatas.time', []);
    newOption.series = _.filter(getSeriesData, o => topSymbol.indexOf(o.name) !== -1);
    setoption2(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();

  }, [updateCount]);

  useUpdateEffect(() => {
    setTopSymbol(_.slice(_.get(props, 'optionVal', []), 0, 5)) // 默认显示前5数据
    setUpdateCount(updateCount + 0.1);
  }, [props.upCount])

  const onSymbolChange = (value) => {
    setTopSymbol(value);
    setUpdateCount(updateCount + 0.1);
  };

  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 16, padding: 8 };
  const topChildren = _.size(_.get(props, 'optionVal', [])) > 0 ? _.get(props, 'optionVal', []).map((n, i) => <Option key={n} value={n}>{n}</Option>) : null;
  const isShow = _.get(props, 'active', '') === '4' ? true : false;
  return (
    isShow ? <>
      <Row style={controlBarStyle}>
        <Col span={20}>
          <Select
            mode="multiple"
            allowClear
            bordered={false}
            style={{
              width: '100%',
            }}
            value={topSymbol}
            onChange={onSymbolChange}
          >
            {topChildren}
          </Select>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Segmented
            options={_.get(props, 'segOptions')}
            value={_.get(props, 'segType')}
            onChange={props.onTypeChange} />
        </Col>
      </Row>

      <div style={{
        width: '100%',
        height: 450
      }}>

        <div style={{ display: 'flex' }}>
          <div
            id="display_cal_charts"
            style={{width: '100%',height: 450}}
          />
        </div>
      </div>
    </> : <></>
  )
}