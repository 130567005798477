import React, { useState, useEffect } from 'react';
import {
    Card,
    Button,
    Select,
    Row,
    Col,
    Table,
    Spin,
    Space,
    Input,
    Typography,
    PageHeader,
    Tooltip,
    Tabs
} from 'antd';
import { getStockPoolStocks, getStockScreeningList, searchIndexStocks, showMemberStockPools, showIndexStocks } from '@/api/stock';
import { checkUserExpire, showSubAccountStrategy } from '@/api/userSystem';
import './StockScreening.scss';
import { PayCircleOutlined, UserSwitchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMediaQuery } from 'react-responsive'
import CalModal from '../../AccountAdm/ListsDetails/Components/CalModal';
import SelectAccModal from './Components/SelectAccModal';
import { INDEX_CODE } from '@/utils/indexCode';
import _ from 'lodash';

const { Option, OptGroup } = Select;
const { Text } = Typography;
const { TabPane } = Tabs
const POOL_CODE = [
    { name: '上证50', value: '000016' },
    { name: '沪深300', value: '000300' },
    { name: '中证500', value: '000905' },
    { name: '中证1000', value: '000852' },
    { name: '科创板', value: '999996' },
    { name: '创业板', value: '999997' },
    { name: '普通深市', value: '999998' },
    { name: '普通沪市', value: '999999' },
];

export default function StockScreening(props) {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const mobxStore = useLocalObservable(() => store);
    const [userVip] = useState(JSON.parse(JSON.stringify(mobxStore.userVip)));
    const [subAccountInfo] = useState(_.get(props, 'location.state.subAccountInfo', null));
    const [listData, setlistData] = useState([]); // 股票池及股票池下详细数据
    const [searchData, setSearchData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [stockPoolList, setstockPoolList] = useState([]);    // 首页股票池列表表格数据
    const [selectValue, setSelectValue] = useState(subAccountInfo ? null : POOL_CODE[0].name);
    const [spinning, setspinning] = useState(false);
    const [exUser, setExUser] = useState(_.get(userVip, 'exData', {}));
    const [calcModal, setCalcModal] = useState({ show: false, data: null, code: null });
    const [pool, setPool] = useState([]);
    const [userPool, setUserPool] = useState([]);
    const [pageNum, setPageNum] = useState({ page: 1, size: 0 });
    const [stockType, setStockType] = useState('org');
    const [indexCodeNum, setIndexCodeNum] = useState(0);
    const [accVisible, setAccVisible] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(subAccountInfo ? {} : JSON.parse(JSON.stringify(mobxStore.stockAccount)));
    const { Search } = Input;

    const columns = [
        {
            title: '股票名称',
            align: 'center',
            dataIndex: 'stockName',
            width: 110,
        },
        {
            title: '股票代码',
            align: 'center',
            dataIndex: 'stockCode',
            width: 110,
        },
        {
            title: '一分钟涨幅',
            align: 'center',
            width: 120,
            dataIndex: 'minuteChange',
            sorter: (a, b) => Number(a.minuteChange) - Number(b.minuteChange),
            render: (text) => (
                <>{Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        // {
        //     title: '超出均价线占比',
        //     align: 'center',
        //     width: 120,
        //     dataIndex: 'busyBeyondMovingAverage',
        //     sorter: (a, b) => Number(a.busyBeyondMovingAverage) - Number(b.busyBeyondMovingAverage),
        //     render: (text) => (
        //         <>{Math.round(text * 10000) / 100 + '%'} </>
        //     )
        // },
        {
            title: '一分钟成交量',
            align: 'center',
            width: 120,
            dataIndex: 'volumePerMinute',
            sorter: (a, b) => Number(a.volumePerMinute) - Number(b.volumePerMinute),
        },
        {
            title: '操作',
            align: 'center',
            fixed: isMobile ? '' : 'right',
            width: 160,
            render: (record) => {
                const isDisabled = renderOptionDisable();
                return <Space>
                    <Button
                        type="primary"
                        shape="round"
                        icon={<PayCircleOutlined />}
                        disabled={isDisabled}
                        onClick={() => {
                            goTrading(record);
                        }}>
                        买入
                    </Button>
                </Space>
            }
        },
    ];

    function renderOptionDisable() {
        const allStockArray = [...userPool, ...listData];
        if (subAccountInfo) {
            return false
        } else if (_.findIndex(allStockArray, o => o.name === selectValue) === -1 || _.size(currentAccount) === 0) {
            return true;
        } else {
            return false;
        }
    }

    function goTrading(record) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        props.history.push({
            pathname: '/home/Trading',
            state: {
                subAccountInfo: subAccountInfo || currentAccount,
                active: "1",
                record: record
            }
        });
    }

    async function _checkUserExpire() {
        const data = await checkUserExpire();
        if (_.get(data, 'code') === '200') {
            let newData = { ...data.data };
            let formalDisable = true;
            if (mobxStore.userInfo.role === 'trader') {
                formalDisable = _.get(newData, 'type', '') === 'official' ? true : false
            }
            setExUser(newData);
            mobxStore.changeUserVip({
                exData: newData,
                formalDis: formalDisable
            });
        }
    }

    function searchChange(e) {
        setSearchText(e.target.value)
    }

    useEffect(() => {
        //console.log('全局账户列表', accountList)
        _getStockPool();
        if (subAccountInfo) {
            _get();
        }
        if (!subAccountInfo) {
            _showIndexStocks(POOL_CODE[0].value, 1)
        }
        if (!subAccountInfo && _.size(currentAccount) > 0) {
            _get(currentAccount.subAccountId)
        }

        return () => {
            return;
        }
    }, [])

    async function _getStockPool() {
        const data = await showMemberStockPools();
        if (_.get(data, 'code', '') === '200') {
            const getStockPool = _.get(data, 'data.stockPool', '')
            const userCode = _.split(getStockPool, ';')
            let userP = [];
            let allP = [];
            Object.keys(INDEX_CODE).map(n => {
                let getVal = INDEX_CODE[n];
                if (userCode.indexOf(getVal) !== -1) {
                    userP.push({ name: n, value: getVal })
                }
                allP.push({ name: n, value: getVal })
            })
            setPool(allP);
            setUserPool(userP);
        }
    }

    async function _showSubAccountStrategy(code, isUpdate) {
        if (code) {
            const data = await showSubAccountStrategy({
                subAccountId: subAccountInfo.subAccountId,
                symbol: code
            });
            //  console.log('查询当前策略：', data)
            if (_.get(data, 'code') === '200') {
                setCalcModal({ show: true, data: data.data, code: code })
            }
        }

        if (isUpdate) {
            _checkUserExpire();
        }
    }

    async function _showIndexStocks(indexCode, pagen) {
        setspinning(true);

        let params = {
            indexCode: indexCode || indexCodeNum,
            pagesize: 20,
            page: pagen || pageNum.page,
        }
        const res = await showIndexStocks(params);
        setspinning(false);
        if (_.get(res, 'code') === '200') {
            setstockPoolList(
                _.chain(res).get('data.result').head().get('stockScreeningDTOS', []).value()
            );
            setPageNum({ page: params.page, size: (_.get(res, 'data.totalPage') || 0) * 20 })
        }
    }

    async function _get(id) {
        const newId = id || _.get(subAccountInfo, 'subAccountId', null);
        if (newId) {
            setspinning(true);
            const data = await getStockPoolStocks({
                operationId: newId,
                operationType: mobxStore.userInfo.role
            });     // 股票池详情数据
            const data1 = await getStockScreeningList({
                operationId: newId,
                operationType: mobxStore.userInfo.role
            }); // 股票池首页数据
            // 将data和data1 数据map在一个数组中
            if (data.code === '200') {
                let arrsinfo = data.data;
                let arrslist = data1.data;
                if (arrsinfo !== null && arrslist !== null) {
                    arrsinfo = arrsinfo.map((item, index) => {
                        for (let item1 of arrslist) {
                            if (item.stockPoolId === item1.stockPoolId) {
                                item.stockScreeningDTOS = item1.stockScreeningDTOS
                            }
                        }
                        if (index === 0 && subAccountInfo) {
                            setstockPoolList(item.stockScreeningDTOS);
                        }
                        return { ...item, name: _.get(item, 'stockPoolName', '') };
                    })
                }
                setlistData(arrsinfo);
            }
            setspinning(false);
        }
    }

    async function onSearch(value) {
        if (value === '') {
            return
        } else {
            var reg = new RegExp(value);
            var arr = [];
            setspinning(true);
            if (stockType === 'indexcode') {
                const res = await searchIndexStocks({
                    indexCode: indexCodeNum,
                    search: value
                })
                setSearchData(_.get(res, 'data', []))
            } else {
                if (stockPoolList.length !== 0) {
                    stockPoolList.map(item => {
                        if (reg.test(item.stockName)) {
                            arr.push(item);
                        }
                        return item;
                    })
                    //setstockPoolList(arr);
                    setSearchData(arr);
                }
            }
            setspinning(false);
        }
    }

    function _change(e) {
        setspinning(true);

        const findPoolIndex = _.findIndex(pool, o => o.name === e);
        const findListIndex = _.findIndex(listData, o => o.stockPoolName === e);

        if (findPoolIndex !== -1) {
            let getVal = pool[findPoolIndex].value;
            setIndexCodeNum(getVal);
            _showIndexStocks(getVal);
            setStockType('indexcode')
        }

        if (findListIndex !== -1) {
            const getDTOS = listData[findListIndex].stockScreeningDTOS;
            setstockPoolList(getDTOS);
            setIndexCodeNum(listData[findListIndex].stockPoolId)
            setspinning(false);
            setPageNum({ page: 1, size: _.size(getDTOS) })
            setStockType('org')
        }

        if (searchText) {
            setSearchText('');
            setSearchData([])
        }

        setSelectValue(e);
    }

    function changeAccount(item) {
        setCurrentAccount(item);
        setAccVisible(false);
        _get(item.subAccountId);
        mobxStore.saveStockAccount(item);
    }

    const container_col = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24 };
    const newTitle = _.get(currentAccount, 'subAccountName', null) ? _.get(currentAccount, 'subAccountName', '') + '的股票' : '我的股票';
    const allStock = listData.length > 0 ? [...listData, ...POOL_CODE] : POOL_CODE;
    const ACCOUNT_LIST = JSON.parse(JSON.stringify(mobxStore.accList));
    // console.log('用户可操作票池', [...userPool, ...listData])
    // console.log('searchData', searchData)
    return (
        <>
            <PageHeader
                title={'股票池'}
                subTitle={subAccountInfo ? _.get(subAccountInfo, 'subAccountName', '') : null}
                backIcon={subAccountInfo ? <ArrowLeftOutlined /> : null}
                onBack={() => {
                    if (subAccountInfo) {
                        props.history?.goBack()
                    } else {
                        return null
                    }
                }}
                style={{ backgroundColor: 'white' }}
            ></PageHeader>

            <div className="StockScreening">
                <Spin spinning={spinning}>
                    <Card style={{ marginTop: 20 }}>
                        {!subAccountInfo && <Tabs
                            defaultActiveKey={POOL_CODE[0].name}
                            onChange={_change}
                            value={selectValue}
                        >
                            {allStock.map((stock, index) => {
                                return <TabPane tab={stock.name} key={stock.name} />
                            })}
                        </Tabs>}

                        <Card>
                            <Row gutter={[16, 16]} justify='center' align='center'>
                                <Col {...container_col}>
                                    <Space size='large'>
                                        <h2 style={{ marginTop: 3 }}>{newTitle}</h2>

                                        {!subAccountInfo && <Tooltip title={'点击选择账户'}>
                                            <Button
                                                shape="circle"
                                                icon={<UserSwitchOutlined />}
                                                onClick={() => {
                                                    setAccVisible(true)
                                                }} />
                                        </Tooltip>}

                                        {subAccountInfo && <Select
                                            style={{ width: isMobile ? 135 : 180, marginLeft: 20 }}
                                            onChange={_change}
                                            value={selectValue}
                                            placeholder='请选择股票池'
                                        >
                                            {
                                                listData.length !== 0 &&
                                                <OptGroup label={'自定义'} >
                                                    {listData.map((item, index) => {
                                                        return (
                                                            <Option
                                                                key={item.stockPoolName + index}
                                                                value={item.stockPoolName}
                                                                disabled={!subAccountInfo && _.size(currentAccount) === 0}
                                                            >
                                                                {item.stockPoolName}
                                                            </Option>
                                                        )
                                                    })}
                                                </OptGroup>
                                            }
                                            {_.size(userPool) > 0 &&
                                                <OptGroup label={'成分股'}>
                                                    {userPool.map((item, index) => {
                                                        return (
                                                            <Option
                                                                key={item.name + index}
                                                                value={item.name}
                                                            >
                                                                {item.name}
                                                            </Option>
                                                        )
                                                    })}
                                                </OptGroup>
                                            }
                                        </Select>}
                                        {_.size(currentAccount) === 0 && !subAccountInfo && <Text type='secondary'>请选择账户后操作</Text>}
                                    </Space>
                                </Col>
                                <Col {...container_col} style={{ textAlign: 'right' }}>
                                    <Search
                                        value={searchText}
                                        onChange={searchChange}
                                        placeholder="请输入股票名称"
                                        allowClear
                                        enterButton
                                        onSearch={onSearch}
                                        style={{ width: isMobile ? 230 : 290 }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Table
                            rowKey="id"
                            dataSource={_.size(searchText) > 0 ? searchData : stockPoolList}
                            columns={columns}
                            bordered
                            size='small'
                            scroll={{ x: 690 }}
                            pagination={{
                                pageSize: 20,
                                current: _.get(pageNum, 'page'),
                                total: _.get(pageNum, 'size'),
                                // disabled: _.size(searchText) > 0 ? true : false,
                                onChange: (page, pageSize) => {
                                    if (stockType === 'org') {
                                        setPageNum({ page: page, size: pageNum.size })
                                    } else {
                                        setPageNum({ page: page, size: pageNum.size })
                                        _showIndexStocks(null, page);
                                    }
                                }
                            }}
                        />
                    </Card>
                </Spin>

                <CalModal // 策略服务启动 modal
                    visible={_.get(calcModal, 'show')}
                    sData={_.get(calcModal, 'data')}
                    strategyNumber={{
                        sNumber: _.get(exUser, 'strategiesNum'),
                        openNumber: _.get(exUser, 'openStrategiesNum'),
                    }}
                    onCancel={() => setCalcModal({ show: false, data: null })}
                    onChange={() => {
                        _showSubAccountStrategy(_.get(calcModal, 'code'), 'update');
                    }}
                />

                <SelectAccModal
                    visible={accVisible}
                    account={ACCOUNT_LIST}
                    value={currentAccount}
                    showObj={{ title: '请选择账户', btn: '点击确定' }}
                    onCancel={() => setAccVisible(false)}
                    onSelect={(item) => changeAccount(item)}
                />
            </div>
        </>
    )
}