import React, { useState } from 'react';
import { Button, Select, Modal, message } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { getAllTraders, updateTrader, getFundManagerAllTraders } from '@/api/details';
import _ from 'lodash';

const { Option } = Select;

export default function UpdateTraders(props) {
  const [visible, setvisible] = useState(false);
  const [traderList, settraderList] = useState([]);
  const [traderIn, settraderIn] = useState(null);


  function handleCancel() {
    setvisible(false);
    settraderIn(null);
  }
  // 打开更换交易员
  async function _ChangingTraders() {
    let data
    if (_.get(props, 'pageKey', '') === 'mAccount') {
      data = await getFundManagerAllTraders({
        id: _.get(props, 'accInfo.securitiesAccountId'),
        traderId: _.get(props, 'accInfo.traderId')
      });
    } else {
      data = await getAllTraders({ id: props.fatRecord.id });
    }

    if (data.code === '200') {
      settraderList(data.data.result);
      setvisible(true);
    } else {
      message.error(data.message);
    }
  }
  async function handleOk() {
    if (!traderIn) return message.error('请选择要更换的交易员');

    const data = await updateTrader({ traderId: traderIn, subAccountId: props.record.id });
    //console.log(data);
    if (data.code === '200') {
      message.success('提交替换成功');
      props.goBack();
      handleCancel();
    } else {
      message.error(data.message);
    }
  }

  return (
    <>
      <Button type="link" size='small' onClick={_ChangingTraders} icon={<SwapOutlined />}>更换</Button>

      <Modal
        title="更换交易员"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >

        <Select
          placeholder="请选择交易员"
          onChange={(e) => settraderIn(e)}
          value={traderIn}
          style={{ width: '100%' }}
        >
          {
            traderList.map(item => {
              return (
                <Option
                  key={item.traderid}
                  value={item.traderid}
                >
                  {item.tradername}
                </Option>
              )
            })
          }
        </Select>
      </Modal>
    </>
  )
}