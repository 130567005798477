// global.api = "http://47.114.78.153:9111";

global.api = "/api";
global.newApi = "/fdata";
// global.apc = "/apc";                                // review开发
// global.apc = "http://47.114.118.216:3000/review";       // review生产
global.apc = "/review";       // review生产
global.imapi = '/im/api';
global.verifyUrl = 'https://bt.cfirstquant.com/api/getCaptcha?captchaToken='

// proxy:{
//     "/api":{
//       "target":"http://47.114.78.153:9111",
//       "changeOrigin":true,
//       pathRewrite:{
//         "^/api":""
//       }
//     },
//     "/review":{
//       "target":"http://47.114.118.216:3000/",
//       "changeOrigin":true,
//       pathRewrite:{
//         "^/review":""
//       }
//     }
//   },