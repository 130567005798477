import React, { useState } from 'react';
import { Table } from 'antd';
import { singleColumns } from '@/utils/utils';
import _ from 'lodash';

const PAGE_SIZE = 100;

const PlateTable = ({ datas = [], loading = false }) => {
  const [currentPageBottom, setcurrentPageBottom] = useState(1);

  const columns = [
    singleColumns(['板块', 'name']),
    {
      ...singleColumns(['收益率', 'value'], 'updown'),
      sorter: (a, b) => a.value - b.value,
      sortDirections: ['descend', 'ascend'],
    },
  ]
  // console.log(datas)
  return <Table
    rowKey="index"
    dataSource={datas}
    columns={columns}
    loading={loading}
    scroll={{ y: 535 }}
    bordered
    size='small'
    pagination={{
      defaultPageSize: PAGE_SIZE,
      showSizeChanger: false,
      onChange: e => { setcurrentPageBottom(e) }
    }}
  />
}

export default PlateTable;