import { action, observable, makeObservable, autorun } from 'mobx';
import { getval, setVal } from './server';
import { getAccountSubs, getAllTradeDay } from '@/api/workbench';
import { isAllStockHoliday } from '@/api/strategy';
import { showVirtualProduct } from '@/api/details';
import { isValidArray, accountOptionIndex, labelValues, createDatasTimeKey, isMarketTrading } from '@/utils/utils2';
import moment from 'moment';
import _ from 'lodash';
class Store {
  constructor() {
    makeObservable(this)
  }
  // 登陆令牌
  @observable userLogin = getval('token');
  // 用户信息
  @observable userInfo = getval('userInfo');
  // 账户下证券账户及子账户列表
  @observable mobxAccountSubs = getval('mobxAccountSubs');
  @observable allProductAcc = {};
  // 提现绑定信息
  @observable accoutStatus = { alipay: null, wechat: null };
  // 当前是否在盘中
  @observable tradingObj = { status: 'rest', date: ['2022-11-01', '2022-11-01'], dateStatus: true };
  // home子账户数据
  @observable accList = [];
  // 会员信息
  @observable userVip = {};
  // 股票池选择账户
  @observable stockAccount = {};
  // 首页产品等数据
  @observable assetsData = {};
  @observable assetsAlls = [];
  @observable accountCache = [];
  @observable allProducts = [];   // 当前全部产品信息(详情)
  @observable virProducts = [];   // 虚拟产品
  // 回测页面全局备份
  @observable backTestBackup = {};
  // 所有交易日期
  @observable tradeDates = [];

  // 登陆
  @action landLogin = (token) => {
    this.userLogin = token;
    setVal('token', token);
  }
  // imToken
  @action landImLogin = (token) => {
    setVal('im-token', token);
  }
  // 退出登陆
  @action cancelLogin = () => {
    this.userLogin = null;
    this.userVip = {}
    this.userInfo = {}
    this.accList = []
    localStorage.removeItem('token');
    localStorage.removeItem('menuKey');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('mobxAccountSubs');
  }
  // 登陆接口保存用户信息
  @action landUserInfo = (userInfo) => {
    this.userInfo = userInfo;
    setVal('userInfo', userInfo);
  }
  // 基金经理 - 保存账户下证券账户及子账户列表
  @action _mobxAccountSubs = (data) => {
    this.mobxAccountSubs = data;
    setVal('mobxAccountSubs', data);
  }

  @action _getProductAccount = async () => { // 全局获取账户信息 [增加配置时试用]
    const data = await getAccountSubs();
    if (_.get(data, 'code', '') === '200') {
      const getData = _.get(data, 'data', []);
      // 将证券集合数据加工成适配级联选择器的数据 value为key
      let arr = _.cloneDeep(getData);
      arr.map((item, key) => {
        item.label = item.productName
        item.value = key
        item.children = item.securitiesAccountList
        item.children.map((item2, key2) => {
          item2.label = item2.securitiesAccountName
          item2.value = key2
          item2.children = item2.subAccountList
          item2.children.map((item3, key3) => {
            item3.label = item3.subAccountName
            item3.value = key3
            return item3
          })
          return item2
        })
        return item;
      });
      // 处理产品和子账户，分别生成options
      let product = []; let account = []; let accObj = {}; let productObj = {};
      if (isValidArray(getData)) {
        getData.map(p => {
          let productItem = { label: _.get(p, 'productName'), value: _.get(p, 'productId'), isShow: _.get(p, 'isShow') };
          product.push(productItem);
          _.set(productObj, productItem.value, productItem.label);
          isValidArray(_.get(p, 'securitiesAccountList', [])) && p.securitiesAccountList.map(acc => {
            const getAccList = _.get(acc, 'subAccountList', []);
            isValidArray(getAccList) && acc.subAccountList.map(sub => {
              let newSub = { ...sub, label: _.get(sub, 'subAccountName', ''), value: _.get(sub, 'subAccountId', 0), isShow: productItem.isShow };
              _.set(accObj, newSub.value, newSub.label);// 用id作为key，label作为value，可根据id找到名称
              account.push(newSub);
            })
          });
        })
      }
      // 产品/账户对比修改格式，首页缓存增加分类逻辑；保留原select两组options数据
      let finalProductAcc = {
        product: _.orderBy(product, ['value'], ['desc']),
        account: _.orderBy(account, ['value'], ['desc']),
        accObj, productObj
      }
      let productTwo = ['每日更新', '回测中', '其他'].map(b => labelValues([b, b, []]));
      let accountTwo = ['每日更新', '回测中', '其他'].map(b => labelValues([b, b, []]));
      finalProductAcc.product.map(item => {
        const aindex = accountOptionIndex(item.label, parseFloat(_.get(item, 'isShow')) ? true : false);
        productTwo[aindex].children.push(item);
      });
      finalProductAcc.account.map(item => {
        const aindex = accountOptionIndex(item.label, parseFloat(_.get(item, 'isShow')) ? true : false);
        accountTwo[aindex].children.push(item);
      });
      this.allProductAcc = {
        ...finalProductAcc,
        productTwo, accountTwo
      }
      this.mobxAccountSubs = arr;
      setVal('mobxAccountSubs', arr);
    }
  }

  @action changeAccList = async (data) => {
    this.accList = data;
  }
  @action saveStockAccount = async (data) => {
    this.stockAccount = data;
  }

  @action _isAllStockHoliday = async () => {
    const TOTAY = moment().format('YYYY-MM-DD');
    let holiRes = await isAllStockHoliday({ 'beginDate': TOTAY, 'endDate': TOTAY })
    let isGet = false; let isDate = true;
    let getDate = [TOTAY, TOTAY];
    if (_.get(holiRes, 'code', '') === '200') {
      const holidayStatus = _.get(holiRes, 'data.isAllHoliday', null);
      if (holidayStatus === true) {
        const getLastTradeDate = _.get(holiRes, 'data.preTradeDay', null)
        if (getLastTradeDate) {
          getDate = [getLastTradeDate, getLastTradeDate]
        }
        isDate = false;
      } else if (holidayStatus === false) {
        isGet = isMarketTrading();
      }
    }
    this.tradingObj = {
      status: isGet ? 'trading' : 'rest',
      dateStatus: isDate,
      date: getDate
    };
  }
  // 首页缓存数据
  @action changeAssetsData = async (data) => {
    this.assetsData = data;
  }
  @action changeAssetsAlls = async (data) => {
    this.assetsAlls = data;
  }
  @action saveAccountCache = async (data) => {
    this.accountCache = data;
  }
  // 当前全部产品信息
  @action changeAllProducts = (data) => {
    this.allProducts = data;
  }
  // 回测备份
  @action changeTestBackup = (data) => {
    this.backTestBackup = data;
  }
  // 获取所有交易日期
  @action _getAllTradeDay = async () => {
    const year = moment().year(); // const month = moment().month();
    const res = await getAllTradeDay({ 'year': `${year - 2},${year - 1},${year}` });
    if (_.get(res, 'code', '') === '200') {
      let orders = createDatasTimeKey(
        _.get(res, 'data', []),
        'pure_date',
        'd',
        'asc'
      );
      let final = [];
      // 增加周的信息，间隔日>1则周增加；
      let week_num = 1; let wstart = ''; // 每周的开始日期
      let seaStart = '';
      let curYear = moment(_.get(orders, '[0].date')).year();
      orders.map((n, i) => {
        let cur = _.get(n, 'date');
        let next = _.get(orders, `[${i + 1}].date`);
        let diff = next ? moment(cur).diff(moment(next), 'd') : 0;
        const thisSeason = moment(cur).quarter(); const nextSeaon = moment(next).quarter();
        const splits = _.split(cur, '-');
        if (i === 0) {
          wstart = splits[1] + splits[2];
          seaStart = splits[1] + splits[2];
        }
        if (parseFloat(splits[0]) !== curYear) { // 跨年度，周重新计算
          week_num = 1;
          curYear = parseFloat(splits[0]);
        }
        final.push(_.assign(n, {
          'week': week_num,
          'start': wstart,
          'season': thisSeason,
          'seaStart': seaStart
        }));
        // 交易日大于1则跨周末，周递增
        const nex_split = _.split(next, '-');
        if (Math.abs(diff) > 1) {
          week_num++;
          wstart = nex_split[1] + nex_split[2];
        };
        if (nextSeaon !== thisSeason) {
          seaStart = nex_split[1] + nex_split[2];
        }
      })
      this.tradeDates = final;
    }
  }
  // deprive
  @action changeAccountStatus = async (type, data) => {
    if (type === 'alipay') {
      this.accoutStatus.alipay = data
    } else if (type === 'wechat') {
      this.accoutStatus.wechat = data
    }
  }
  @action changeUserVip = async (data) => {
    this.userVip = data;
  };
    // 获取虚拟产品
    @action _showVirtualProduct = async () => {
      const res = await showVirtualProduct();
      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', []);
        const orderData = createDatasTimeKey(getData, 'date');
        const roundKeys = ['totalAssets', 'totalRevenue', 'dailyRevenue'];
        // 给资产字段保留2位小数
        let newVdata = orderData.map(n => {
          let floatItm = {};
          roundKeys.map(k => {
            _.set(floatItm, k, _.round(n[k], 2));
          })
          return _.assign(n, floatItm);
        });
        this.virProducts = newVdata;
      }
    }
}

export const store = new Store();

autorun(() => {
  // console.log(`登陆${store.userInfo}`)
})
