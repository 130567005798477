import React, { } from 'react';
import { Modal, Row, Col, Button, message } from 'antd';
import { withRouter } from "react-router-dom";
import { getSubAccount } from '@/api/workbench';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import _ from 'lodash';

function ShowMessage(props) {
    const mobxStore = useLocalObservable(() => store);

    function handleCancel() {
        props.handleCancel();
    }
    async function _goListsDetails() {
        const data = await getSubAccount({ subAccountId: props.showMessageData.subAccountId });
        if (data.code === '200') {
            props.history.push({
                pathname: '/home/listsDetails',
                state: { subAccountInfo: { subAccountId: props.showMessageData.subAccountId } }
            });
        } else {
            message.error(data.message);
        }
    }
    async function receive() {
        props.acceptReject();
    }

    function handleRead(id) {
        if (props._onRead) {
            props._onRead(id);
        }
    }

    function renderAccpetButton() {
        // console.log('props.showMessageData', props.showMessageData)
        if (props.showMessageData.subAccountId && props.showMessageData.acceptStatus === 0 && mobxStore.userInfo.role !== 'fund_manager') {
            if (_.get(props, 'showMessageData.type', '') !== 'sub_account_manage') {
                return <Button style={{ margin: '0 20px' }} type="link" onClick={() => handleRead(_.get(props, 'showMessageData.messageId', null))} >
                    知道了
                </Button>
            } else {
                return (
                    <>
                        <Button style={{ margin: '0 20px' }} type="link" onClick={receive} >接受</Button>
                        <Button type="link" danger onClick={() => props.acceptReject('refuse')}>拒绝</Button>
                    </>
                )
            }
        }
    }

    return (
        <>
            <Modal
                title={props.showMessageData.sender}
                visible={props.isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                {
                    props.showMessageData.subAccountId && mobxStore.userInfo.role === 'trader'
                        ?
                        <p style={{ cursor: 'pointer' }} onClick={_goListsDetails}>{props.showMessageData.content}</p>
                        :
                        <p>{props.showMessageData.content}</p>
                }
                <br />  <br />
                <Row justify="end">
                    <Col className="colorGrey">{props.showMessageData.sendTime}</Col>
                </Row>
                <br />  <br />

                <Row justify="end" >

                    { // 已接受或已拒绝
                        props.showMessageData.subAccountId && props.showMessageData.acceptStatus !== 0 &&
                        <Button disabled type="link" className="colorGrey">
                            {props.showMessageData.acceptStatus === 1 ? '已接受' : props.showMessageData.acceptStatus === 2 ? '已拒绝' : ''}
                        </Button>
                    }

                    { // 未接受或未拒绝

                        renderAccpetButton()
                    }

                    { // 已读 删除按钮 
                        props.showMessageData.delete &&
                        <Button style={{ marginLeft: 20 }} type="link" danger onClick={props.messageDel}>删除</Button>
                    }
                </Row>
            </Modal>
        </>
    )
}

export default withRouter(ShowMessage);