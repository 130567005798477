import React, { useState } from 'react';
import { Modal, Switch, message, List, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { calculate } from '@/api/userSystem';
import _ from 'lodash';

const { confirm } = Modal;
const { Text } = Typography;

const CalModal = ({ visible, sData, strategyNumber, onCancel, onChange }) => {
  function onSwitchChange(checked, item) {
    //console.log('onSwitchChange', checked)

    confirm({
      title: `是否${checked ? '开启' : '关闭'}该策略`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk: () => {
        changeCalculate(item.id, checked)
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  async function changeCalculate(id, checked) {
    if (id) {
      const data = await calculate({
        strategiesId: id,
        calculateStatus: checked ? 1 : 0
      });
      // console.log('设置计算功能', data)
      if (_.get(data, 'code', '') === '200') {
        message.success('设置成功！');
        onChange()
      } else {
        message.warning('设置失败！')
      }
    }
  }

  const leftStrategyNumber = (_.get(strategyNumber, 'sNumber') || 0) - (_.get(strategyNumber, 'openNumber') || 0)
  const handleStrategyNumber = () => {
    let finalNumber;
    let strDesc = '剩余启动策略数量: '
    if (leftStrategyNumber) {
      finalNumber = strDesc + leftStrategyNumber;
    } else {
      finalNumber = 0
    }
    return finalNumber !== 0 ? finalNumber : '启动策略余量不足';
  }

  //console.log('策略统计：', strategyNumber)
  const textProps = leftStrategyNumber === 0 ? { warning: true } : {};
  const switchDisabled = leftStrategyNumber <= 0 && _.get(strategyNumber, 'sNumber') !== -1 ? true : false
  return (
    <Modal
      title='开启策略'
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <List
        //bordered
        header={
          <div>
            {switchDisabled && <Text {...textProps}>
              {_.get(strategyNumber, 'sNumber') === -1 ? '' : handleStrategyNumber()}
            </Text>}
            {switchDisabled && <br />}
            <Text type='secondary'>
              可手动开启或关闭需要接受买卖信号提示的策略，如果没有可操作的策略，请进入新增策略页面创建策略
            </Text>
          </div>
        }
        dataSource={sData}
        renderItem={item => (
          <List.Item actions={[
            <Switch
              checked={item.calculateStatus === 1}
              onChange={(checked) => onSwitchChange(checked, item)}
              disabled={switchDisabled}
            />
          ]}>
            {item.strategiesName}
          </List.Item>
        )}
      />
    </Modal >
  )
}

export default CalModal;