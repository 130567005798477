import React, { Component } from 'react';
import {
  Row,
  Col,
  Tabs,
  Button,
  PageHeader,
  Tag,
  message,
} from 'antd';
import axios from 'axios';
import {
  TableOutlined, CheckCircleOutlined, PayCircleOutlined, MoneyCollectOutlined
} from '@ant-design/icons'
import {
  checkUserExpire, getOrders, showInvitees, showSubscriptionServices, closeOrder,
  updateUserInfo, getUserInfo, showMemberInfo, getSignalBills
} from '@/api/userSystem';
import { observer, inject } from 'mobx-react';
import { _validateResponse } from '@/utils/utils';
import { useMediaQuery } from 'react-responsive';
import Slider from "react-slick";
// import RewardCard from './Compents/RewardCard';
// import InfoCard from './Compents/InfoCard';
import OrderTable from './OrderComp/OrderTable';
import InviteTable from './Compents/InviteTable';
import OrderPage from './Order';
import UserCard from './Compents/UserCard';
import BillsTable from './Compents/BillsTable';
import MoneyBar from './Compents/MoneyBar';
import moment from 'moment';
import _ from 'lodash';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './reward.scss'

const { TabPane } = Tabs;

const handleDiffTime = (time2) => {
  return moment().diff(moment(time2), 'minute');
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? <Button key="2"
    type="primary"
    shape="round"
    size='large'
    danger
    icon={<PayCircleOutlined />}
  >
    {'请去PC端支付'}
  </Button> : children
}
@inject('store')
@observer
class RewardPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      exUser: {},
      orders: [],
      inviteList: [],
      serviceList: [],

      loadingObj: { invite: false, orderTable: false, bill: false, },
      userData: {},
      memberInfo: {},
      billDate: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      billsData: [],
    }
    this.userInfo = JSON.parse(JSON.stringify(this.props.store.userInfo));
    this.accoutStatus = JSON.parse(JSON.stringify(this.props.store.accoutStatus));
    this.source = axios.CancelToken.source()
  }

  componentDidMount() {
    //console.log('userInfo', this.userInfo)
    this._checkUserExpire();
    this._showSubscriptionServices();
    this._getUserInfo();
    this._showMemberInfo();
    this._getSignalBills();
    this._getOrders();
  }

  componentWillUnmount() {
    this.source?.cancel();
  }

  _checkUserExpire = async () => {
    let exRes = await checkUserExpire();
    this.setState({ exUser: _validateResponse(exRes) })
  }

  _getOrders = async () => {
    this.handleLoading('orderTable', true);
    let orderRes = await getOrders();
    this.setState({ orders: _validateResponse(orderRes) })
    this.handleLoading('orderTable', false);
  }

  _showInvitees = async () => {
    this.handleLoading('invite', true);
    let invRes = await showInvitees();
    this.setState({ inviteList: _validateResponse(invRes) })
    this.handleLoading('invite', false);
  }

  _showMemberInfo = async () => {
    let memRes = await showMemberInfo();
    this.setState({ memberInfo: _validateResponse(memRes) })
  }

  _getUserInfo = async () => {
    let res = await getUserInfo();
    if (_.get(res, 'code', '') === '200') {
      this.setState({ userData: _.get(res, 'data', {}) })
    }
  }

  _getSignalBills = async () => {
    const { billDate } = this.state;
    let params = {
      startDate: billDate[0],
      endDate: billDate[1]
    }
    let res = await getSignalBills(params);
    if (_.get(res, 'code', '') === '200') {
      this.setState({
        billsData: _.get(res, 'data', {}),
      })
    }
  }

  _showSubscriptionServices = async () => {
    let servRes = await showSubscriptionServices();
    const sData = _validateResponse(servRes, [])
    this.setState({
      serviceList: _.filter(sData, o => o.point > 0)
    })
  }

  _closeOrder = async (id) => {
    if (id) {
      let closeRes = await closeOrder(id);
      if (_.get(closeRes, 'code') === '200') {
        message.success('关闭成功！');
        this._getOrders()
      } else {
        message.info('关闭失败！');
      }
    }
  }
  _updateUserInfo = async (name, avatar) => {
    const { userData } = this.state;
    let params = {
      avatar: avatar || _.get(userData, 'avatar', null),
      name: name || _.get(userData, 'name', null),
    }
    let res = await updateUserInfo(params);
    if (_.get(res, 'code') === '200') {
      message.success('更新成功！');
      this._getUserInfo()
    } else {
      message.info('更新失败！');
    }
  }

  handleLoading = (key, status) => {
    let tempLoading = this.state.loadingObj;
    tempLoading[key] = status;
    this.setState({ loadingObj: tempLoading })
  }

  handleLink = (link) => {
    this.props.history.push({
      pathname: link,
    });
  }

  goForm = (isWithdraw) => {
    const { memberInfo } = this.state;
    this.props.history.push({
      pathname: '/home/manager/withForm',
      state: {
        accountStatus: isWithdraw ? this.accoutStatus : null,
        balance: isWithdraw ? _.get(memberInfo, 'balance', 0) : _.get(memberInfo, 'deposit', 0),
        fromReward: isWithdraw ? false : true,
      }
    });
  }

  goPayment = (fees) => {
    const { exUser, orders } = this.state;
    let jumpState = {
      unpayOrder: _.filter(orders, o => {
        return o.orderStatus === 0 && handleDiffTime(o.expiryTime) < 0
      }),
      exData: exUser,
      fromPay: fees || fees === 0 ? true : false,
    }
    if (fees) {
      jumpState.unpayFees = fees;
    }

    this.props.history.push({
      pathname: '/home/trader/payment',
      state: jumpState
    });
  }

  render() {
    const { loadingObj, exUser, memberInfo, orders, inviteList, serviceList, userData, billsData } = this.state;
    const order_col = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24, flex: 'auto' };

    return (
      <div style={{ overflow: 'hidden' }}>
        <PageHeader
          ghost={false}
          title="个人中心"
          subTitle={
            _.get(exUser, 'status', '') === 1 ?
              <Tag color='blue'>{_.get(exUser, 'serviceName', '')}</Tag>
              : ''
          }
          extra={[
            <Mobile>
              <Button key="2"
                type="primary"
                shape="round"
                size='large'
                danger
                icon={<PayCircleOutlined />}
                onClick={() => this.goPayment()}>
                {_.get(exUser, 'status') === 1 ? '去续费' : '去购买'}
              </Button>
            </Mobile>,
          ]}
        >

        </PageHeader>

        <MoneyBar
          memberInfo={memberInfo}
          exUser={exUser}
          jumpPayment={(fees) => this.goPayment(fees)}
          jumpForm={(isWithdraw) => this.goForm(isWithdraw)}
          jumpLink={(link) => this.handleLink(link)}
        />

        <div className="card-container">
          <Tabs type="card" onChange={(activeKey) => {
            if (activeKey === 'invite') {
              this._showInvitees();
            } else if (activeKey === 'orderTable') {
              this._getOrders();
            }
          }}>
            <TabPane tab={
              <span><CheckCircleOutlined />订阅</span>
            } key="order">
              <Row gutter={[16, 16]}>
                <Col {...order_col}>
                  <UserCard
                    userData={userData}
                    exData={exUser}
                    role={this.userInfo.role || 'trader'}
                    userInfo={this.userInfo}
                    onUpdateAvatar={(data) => this._updateUserInfo(null, data)}
                    onUpdateName={(txt) => this._updateUserInfo(txt, null)}
                  />
                </Col>
                <Col {...order_col}>
                  <OrderPage
                    role={this.userInfo.role || 'trader'}
                    exData={exUser}
                    userInfo={this.userInfo}
                    userData={userData}
                    serviceList={serviceList}
                    memberInfo={this.state.memberInfo}
                    onRefresh={() => {
                      this._checkUserExpire();
                      this._showInvitees();
                    }}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab={
              <span><MoneyCollectOutlined />账单信息</span>
            } key="bill">
              <BillsTable
                bills={_.get(billsData, 'billsResults', [])}
                billsToday={_.get(billsData, 'todayTotalBill', {})}
                billDate={this.state.billDate}
                onTimeChange={(dateStrings) => {
                  this.setState({ billDate: dateStrings }, () => {
                    this._getSignalBills()
                  })
                }}
              />
            </TabPane>

            <TabPane tab={
              <span><TableOutlined />订单列表</span>
            } key="orderTable">
              <OrderTable
                orderData={orders}
                onCloseOrder={(orderNo) => this._closeOrder(orderNo)}
                loading={_.get(loadingObj, 'orderTable')}
              />
            </TabPane>

            <TabPane tab={
              <span><TableOutlined />邀请列表</span>
            } key="invite">
              <InviteTable datas={inviteList} loading={_.get(loadingObj, 'invite')} />
            </TabPane>

          </Tabs>
        </div>

        {/* <div style={{ paddingLeft: 15, paddingRight: 10, marginTop: 8 }}>
          <Slider
            dots={false}
            infinite={true}
            centerMode={true}
            slidesToShow={1}
            slidesToScroll={1}
            variableWidth={true}
            arrows={true}
          >
            {dataList.map((item, index) => {
              return <RewardCard key={'slider' + index} info={item} />
            })}
          </Slider>
        </div> */}
      </div >
    )
  }
}

export default RewardPage;