import React from 'react';
import {
  Tabs, Row, Col, Select, Checkbox, Space, Slider, Collapse, Table, Typography, Modal, Descriptions,
  InputNumber, Form, Radio, Divider, Popover, Button, Input, Segmented, Tooltip, Switch
} from 'antd';
import { CaretRightOutlined, RedoOutlined, RollbackOutlined } from '@ant-design/icons';
import { createBasicColums, RateText } from '@/utils/utils';
import { isValidArray, isValidObj, isValidNumber, labelValues } from '@/utils/utils2';
import { TAB_LIST, LIMIT_COL_LIST, PRE_DAY_CN } from './chartsInfoUtils';
import { INDEX_OPTIONS } from '@/utils/indexCode'; // PLATE_OPTIONS
import { useReactive, useUpdateEffect } from 'ahooks';
//import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
// 指数选择
const NO_SHOW_TODAY = ['866001', '866006', '000985'];
const IndexSelect = ({ active = '', idv = null, size = 'small', onIndexChange }) => {
  const newIndexOptions = (active === 'TODAY' || active === '1') ? INDEX_OPTIONS.map(n => {
    let newItem = _.cloneDeep(n);
    if (_.includes(NO_SHOW_TODAY, n.value)) {
      _.set(newItem, 'disabled', true);
    }
    return newItem;
  }) : INDEX_OPTIONS;
  return <Select
    size={size}
    value={idv}
    style={{ width: size === 'normal' ? 160 : 120 }}
    onChange={onIndexChange}
    options={newIndexOptions}
  />
}
// 切换收益率时间周期组件
const MainRangeTabs = ({
  active = '',
  idv = '',
  years = [],

  onTabChange,
  onIndexChange,
}) => {
  const tabComp = _.concat(TAB_LIST, years).map(n => <Tabs.TabPane key={n.key} tab={n.tab} />);
  const tabRightComp = <Space align='center'>
    <IndexSelect active={active} idv={idv} onIndexChange={onIndexChange} />
  </Space>;

  return <Row style={{ padding: 6 }}>
    <Col span={24}>
      <Tabs
        activeKey={active}
        onChange={onTabChange}
        tabPosition='top'
        size='small'
        tabBarExtraContent={{ right: tabRightComp }}
      >
        {tabComp}
      </Tabs>
    </Col>
  </Row>
}
// 滑动时间组件
const MainSlider = ({
  isNotToday = false,
  timeNameArray = [], // 名称数组
  timeArray = [], // index值数组
  sDisable = false,
  value = 0, // slider的值
  svalue = 0, // slider index的值
  empComp = <></>,
  isFull = false,

  onSliderChange,
  onSliderAfterChange,
  onReset
}) => {
  const formatter = (value) => timeNameArray[value] || 'none';
  const firstdate = _.get(timeNameArray, `[1]`, '');
  const start = _.get(timeNameArray, `[${value[0]}]`, '');
  const end = _.get(timeNameArray, `[${value[1]}]`, '');
  const max = _.last(timeArray) || 0;
  const textProps = { strong: true, style: { fontSize: 13 } };
  // start会等于前一交易日，所以符合sliderValue=0及中文相同，则显示数组1位的有效日期
  if (isNotToday) {
    return (
      <Row align='middle'>
        <Col span={6}>
          <Text {...textProps}>开始日期: {value[0] === 0 && start === PRE_DAY_CN ? firstdate : start}</Text>
          <div style={{ height: 15, width: 15, cursor: 'pointer', marginRight: 4, float: 'right' }} onClick={onReset}>
            {isFull ? <></> : <RollbackOutlined style={{ color: '#9f9f9f' }} />}
          </div>
        </Col>
        <Col span={12}>
          <Slider
            min={0}
            max={max}
            disabled={sDisable}
            range={{ draggableTrack: true }}
            tipFormatter={formatter}
            value={svalue}
            onChange={onSliderChange}
            onAfterChange={onSliderAfterChange}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Text {...textProps}>结束日期: {end}</Text>
        </Col>
      </Row>
    )
  } else {
    return empComp
  }
}

const MainLimitTable = ({ datas = [] }) => {
  const columns = [...createBasicColums(LIMIT_COL_LIST, [1, 'strong'])];

  return isValidArray(datas) ? <>
    <br />
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Collapse.Panel header="涨停票" key="1" className="site-collapse-custom-panel">
        <Table
          rowKey="name"
          dataSource={datas}
          columns={columns}
          bordered
          size='small'
          pagination={{ defaultPageSize: 5 }}
        />
      </Collapse.Panel>
    </Collapse>
  </> : <></>
}
// 图表切换竖向tab 组件
const ChartTabs = ({
  active = '',
  options = [],
  onTabChange
}) => {
  function renderLabel(label) {
    return _.size(label) > 2 ? label.substring(0, 2) + '...' : label;
  };

  return <div className='aidxContent'>
    {options.map(n => <div key={n.value}
      className={active === n.value ? 'aidxTab-active' : 'aidxTab'}
      onClick={() => {
        if (active !== n.value) {
          onTabChange(n.value);
        };
      }}
    >
      <Tooltip title={n.label} placement='right'>
        {renderLabel(n.label)}
      </Tooltip>
    </div>)}
  </div>;
};

const EXTRA_OPTIONS = [labelValues(['区间', 'ranges']), labelValues(['季度', 'season']), labelValues(['月度', 'months']), labelValues(['周度', 'weeks'])];
const CEXTRA = { 'day': '日', 'weeks': '周', 'months': '月', 'season': '季' };
// 月度超额统计; 新增周及区间信息的显示
const ExtraMonthTable = ({
  isShow = false,
  isExtra = false,
  isOverlap = false,
  noExtra = false,
  extras = {},
  chrtabSize = 0,

  onTypeChange,
  onExtraChange,
  onOverlapChange
}) => {
  const extraState = useReactive({ type: 'ranges' });
  const newExtra = _.get(extras, extraState.type, {});
  // 对ctype的key进行处理，与数据字段相同；
  const newCtype = extraState.type === 'ranges' ? 'day' : extraState.type;
  const otherRateNum = chrtabSize - 3;
  return isShow ? <>
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <Row >
          <Col span={16}>
            <Space size='small'>
              <Segmented
                size='small'
                options={EXTRA_OPTIONS}
                value={extraState.type}
                onChange={v => {
                  extraState.type = v;
                  onTypeChange('type', v);
                }}
              />
              <Divider type='vertical' />
              {!noExtra && <Switch
                checkedChildren="超额"
                unCheckedChildren="超额"
                checked={isExtra}
                onChange={onExtraChange}
              />}
            </Space>
          </Col>
          {extraState.type !== 'ranges' && !noExtra && <Col span={8} style={{ textAlign: 'right' }}>
            <Text type='secondary'>其他收益率: {otherRateNum} 个</Text>
            <Divider type='vertical' />
            <Switch
              disabled={otherRateNum < 2}
              checkedChildren="叠加"
              unCheckedChildren="叠加"
              checked={isOverlap}
              onChange={onOverlapChange}
            />
          </Col>}
        </Row>
      </Col>
      <Col span={16}>
        <div style={{ maxHeight: 345, overflowY: 'auto' }}>
          <Descriptions size='small' bordered column={3}>
            {_.keys(newExtra).map((keyname, i) => {
              let finalLabel = '';
              if (extraState.type === 'month') {
                const split_month = _.split(keyname, '-');
                const year = split_month[0]; const mon = parseInt(split_month[1]);
                finalLabel = mon === 1 ? `${year}-${mon}月` : `${mon}月`;
              } else {
                finalLabel = keyname;
              }
              return <Descriptions.Item key={'mon' + i} label={finalLabel}>
                {extraState.type === 'ranges' ?
                  <Text>{newExtra[keyname]}</Text>
                  : <RateText rate={newExtra[keyname]} carry={2} />}
              </Descriptions.Item>
            })}
          </Descriptions>
        </div>
      </Col>
      <Col span={8}>
        <Descriptions size='small' bordered column={1}>
          <Descriptions.Item label={'区间'}>
            <Text strong>{_.get(extras, `cal.${newCtype}.size`, '')}</Text>
            <Text>个交易{CEXTRA[newCtype]}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={'平均'}>
            <RateText rate={_.get(extras, `cal.${newCtype}.avg`, '')} carry={4} />
          </Descriptions.Item>
          <Descriptions.Item label={'胜率'}>
            <RateText rate={_.get(extras, `cal.${newCtype}.psize`, '')} carry={2} />
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  </> : <></>
};

// 渲染FormItem的props内容
const message_type = { 1: '输入', 2: '选择' };
function renderFormProps(name, key, isRequired, type) {
  let newProps = { label: name, name: key }
  if (isRequired) {
    _.set(newProps, 'rules', [{ required: true, message: `请${message_type[type]}正确的${name}` }]);
  }
  return newProps;
}
// 配置modal form 组件
const ConfigModal = ({
  isShow = false,
  update = 0,
  loading = false,
  products = {},
  lasts = {},
  historys = [],

  onCancel,
  onConfirm
}) => {
  const modalState = useReactive({
    dayOptions: [],
    formTypes: ['0', '0', '0'], // 控制表单内容，可全选也可每项进行单独配置
    id: 0, cid: 0, //恢复的id，及选中的id
    saveType: '新建', saveItem: {}, // saveItem 保存点击是的item数据
  });
  const [form] = Form.useForm();
  const FORM_TYPES = ['产品', '指数', '期货'];
  const SEG_OPTIONS = ['新建', '保存'];
  /**
   * tips: 选中cid，已经恢复id、saveType:完全手动，所以切换或者其他状态要更新对应状态，配合modal的开关..
   */
  useUpdateEffect(() => {
    modalState.dayOptions = findDaliyUpdateProduct();
    if (isValidObj(lasts)) {
      modalState.formTypes = _.get(lasts, 'type', []);
      form.setFieldsValue(_.get(lasts, 'val', {}));
      if (isValidNumber(_.get(lasts, 'val.id'))) {
        modalState.id = _.get(lasts, 'val.id');
        modalState.cid = _.get(lasts, 'val.id');
        modalState.saveType = SEG_OPTIONS[1];
      }
    } else {
      modalState.id = 0;
      modalState.cid = 0;
      modalState.saveItem = {};
      modalState.saveType = SEG_OPTIONS[0];
    }
  }, [update]);
  // 找到bt中每日更新的数组作为options
  function findDaliyUpdateProduct() {
    let dindex = _.findIndex(_.get(products, 'category', []), o => o.label === '每日更新');
    return dindex !== -1 ? _.get(products, `category[${dindex}].children`, []) : [];
  }
  // 渲染content内容；静态文字直接提取数据，格局params字段进行处理
  const baseKeys = ['name', 'baseProductCostParam', 'baseShare']
  const subKeys = ['name', 'compareProductId', 'productCostParam', 'share', 'indexCode', 'side', 'weight']
  function renderContent(pms) {
    const newPms = JSON.parse(_.get(pms, 'configParam'));
    const is_one = 'productConfigurationParam' in newPms;
    const is_two = 'indexConfigurationParam' in newPms;
    const is_three = 'optionConfigurationParam' in newPms;
    return <div className='his-content'>
      <Space size='small' direction='vertical'>
        {is_one && <Text>
          {`对比产品:${_.get(products, 'names.' + _.get(newPms, 'productConfigurationParam.compareProductId'), '')};
          基准${_.get(newPms, 'baseProductCostParam', '')};
          对比${_.get(newPms, 'productConfigurationParam.productCostParam', '')}`}
        </Text>}
        {is_two && <Text>
          {`指数份额${_.get(newPms, 'indexConfigurationParam.share', '')};
          指数代码${_.get(newPms, 'indexConfigurationParam.indexCode', '')}`}
        </Text>}
        {is_three && <Text>
          {`期货份额${_.get(newPms, 'optionConfigurationParam.share', '')};
          期货代码${_.get(newPms, 'optionConfigurationParam.indexCode', '')}`}
        </Text>}
        <Button type='link' size='small' icon={<RedoOutlined />}
          onClick={() => {
            // 解构提交的数据，赋值给表单，并且处理特殊字段
            let edits = { name: _.get(pms, 'name') };
            _.keys(newPms).map(keyname => {
              if (_.includes(baseKeys, keyname)) {
                _.set(edits, keyname, newPms[keyname]);
              }
              if (typeof newPms[keyname] === 'object') {
                _.keys(newPms[keyname]).map(subs => {
                  if (_.includes(subKeys, subs)) {
                    // 处理期货 share2 和 indexCode2 d的字段赋值问题
                    if (keyname === 'optionConfigurationParam' && subs === 'share') {
                      _.set(edits, 'share2', _.get(newPms, `optionConfigurationParam.share`));
                    } else if (keyname === 'optionConfigurationParam' && subs === 'indexCode') {
                      _.set(edits, 'indexCode2', _.get(newPms, `optionConfigurationParam.indexCode`));
                    } else {
                      _.set(edits, subs, _.get(newPms, `${keyname}.${subs}`));
                    }
                  }
                })
              }
            });
            form.setFieldsValue(edits);
            modalState.formTypes = [is_one ? FORM_TYPES[0] : '0', is_two ? FORM_TYPES[1] : '0', is_three ? FORM_TYPES[2] : '0'];
            modalState.id = _.get(pms, 'id');
            modalState.saveType = SEG_OPTIONS[1];
            modalState.saveItem = { ...edits, id: _.get(pms, 'id') };
          }}>
          恢复配置
        </Button>
      </Space>
    </div >
  }
  //console.log(modalState)
  const select_props = { style: { width: 220 } };
  return <Modal
    visible={isShow}
    width={890}
    maskClosable={false}
    style={{ top: 20 }}
    bodyStyle={{ minHeight: 300 }}
    confirmLoading={loading}
    onCancel={() => {
      onCancel();
      form.resetFields();
      modalState.formTypes = ['0', '0', '0'];
    }}
    onOk={() => {
      form
        .validateFields()
        .then((values) => {
          //console.log(values);
          onConfirm(
            _.assign(values, { 'id': modalState.id }),
            modalState.formTypes
          );
        })
        .catch((info) => {
          console.log('Validate Failed:', info);
        });
    }}
  >
    <div style={{ marginBottom: 24 }}>
      <Text type='secondary'>配置对比 - </Text><Text strong>{_.get(products, 'pdctName', '-')}</Text>
    </div>
    <Row>
      <Col span={18}>
        <Row gutter={[8, 8]} style={{ marginBottom: 16 }}>
          <Col span={3}><Text>类型：</Text></Col>
          {FORM_TYPES.map((n, i) => <Col span={3} key={i + n}>
            <Checkbox checked={modalState.formTypes[i] === n ? true : false}
              onChange={(e) => modalState.formTypes[i] = e.target.checked ? n : '0'}>
              {n}
            </Checkbox>
          </Col>)}
          <Col span={12} style={{ textAlign: 'right' }}>
            <Segmented value={modalState.saveType} size='small' options={SEG_OPTIONS}
              onChange={(v) => {
                modalState.saveType = v;
                if (v === SEG_OPTIONS[0]) {
                  modalState.id = 0;
                  modalState.cid = 0;
                  modalState.saveItem = {};
                  form.resetFields();
                } else {
                  if (isValidObj(modalState.saveItem)) {
                    modalState.id = _.get(modalState.saveItem, 'id');
                    modalState.cid = _.get(modalState.saveItem, 'id');
                  }
                }
              }}
            />
          </Col>
        </Row>
        <Divider />
        <Form
          form={form}
          layout="horizontal"
          name="modal_config"
          size='small'
          initialValues={{}}
        >
          <Form.Item {...renderFormProps('配置名称', 'name', true, 1)}>
            <Input autoComplete='off' />
          </Form.Item>
          <Form.Item {...renderFormProps('基准成本', 'baseProductCostParam', true, 1)}>
            <InputNumber step={0.1} min={0} />
          </Form.Item>
          {modalState.formTypes[0] === FORM_TYPES[0] && <>
            <Divider />
            <Form.Item {...renderFormProps('对比产品', 'compareProductId', true, 2)}>
              <Select options={modalState.dayOptions} style={{ width: 320 }} />
            </Form.Item>
            <Form.Item {...renderFormProps('对比成本', 'productCostParam', true, 1)}>
              <InputNumber step={0.1} min={0} />
            </Form.Item>
            <Form.Item {...renderFormProps('产品份额', 'productShare', true, 1)}>
              <InputNumber step={1} min={0} />
            </Form.Item>
          </>}
          {modalState.formTypes[1] === FORM_TYPES[1] && <>
            <Divider />
            <Form.Item {...renderFormProps('指数份额', 'share', true, 1)}>
              <InputNumber step={1} min={0} />
            </Form.Item>
            <Form.Item {...renderFormProps('指数代码', 'indexCode', true, 2)}>
              <Select {...select_props} options={INDEX_OPTIONS} />
            </Form.Item>
          </>}
          {// share2 / indexCode2 为自定义字段，提交时重新赋值
            modalState.formTypes[2] === FORM_TYPES[2] && <>
              <Divider />
              <Form.Item {...renderFormProps('期货份额', 'share2', true, 1)}>
                <InputNumber step={1} min={0} />
              </Form.Item>
              <Form.Item {...renderFormProps('期货代码', 'indexCode2', true, 2)}>
                <Select {...select_props} options={INDEX_OPTIONS} />
              </Form.Item>
              <Form.Item {...renderFormProps('多空方向', 'side', true, 2)}>
                <Radio.Group>
                  <Radio value={1}>多</Radio>
                  <Radio value={2}>空</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item {...renderFormProps('杠杆倍数', 'weight', true, 1)}>
                <InputNumber step={1} min={0} />
              </Form.Item>
              {/* <Form.Item {...renderFormProps('仓位比例', 'positionRatio', true)}>
          <InputNumber step={0.1} min={0} />
        </Form.Item> */}
            </>}
        </Form>
      </Col>
      <Col span={6} style={{ minHeight: 205, maxHeight: 350, overflowY: 'auto', overflowX: 'hidden' }}>
        <div className='history-title'>
          <Text type='secondary'>历史记录</Text>
        </div>
        <ul className='config-history'>
          {isValidArray(historys) && historys.map(item => <li key={item.id} onClick={() => modalState.cid = item.id}>
            <Popover trigger='click' content={renderContent(item)} placement='rightTop'>
              <Text style={{ color: modalState.cid === item.id ? '#1890fb' : '#262626' }}>
                {_.get(item, 'name') ?? ('配置' + item.id)}
              </Text>
            </Popover>
          </li>)}
        </ul>
      </Col>
    </Row>
  </Modal>
};
// 收益率实际减去差异的配置form
const RateDiffModal = ({
  isShow = false,

  onCancel,
  onConfirm
}) => {
  const [formDiff] = Form.useForm();

  return <Modal
    visible={isShow}
    width={710}
    maskClosable={false}
    onCancel={onCancel}
    onOk={() => {
      formDiff
        .validateFields()
        .then((values) => {
          //console.log(values);
          onConfirm(values);
        })
        .catch((info) => { console.log('Validate Failed:', info) });
    }}
  >
    <div style={{ height: 35 }}></div>
    <Form
      form={formDiff}
      layout="horizontal"
      name="modal_diff_rate"
      initialValues={{ 'diff': 0.2 }}
    >
      <Form.Item {...renderFormProps('差异比例', 'diff', true, 1)}>
        <InputNumber step={0.1} min={0} addonAfter="%" />
      </Form.Item>
    </Form>
  </Modal>
}

export {
  MainRangeTabs,
  MainSlider,
  MainLimitTable,
  ChartTabs,
  IndexSelect,
  ExtraMonthTable,
  ConfigModal,
  RateDiffModal,
}