import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, Select, Modal, message, InputNumber, Tag, PageHeader } from 'antd';
import { getBrokerages, checkTradeAccount, addProduct, initAccount, isRepeatTradeUnit, initProductOriginAssets } from '@/api/details';
import { PRODUCT_STRATEGY_TYPE } from '@/utils/utils';

const { Option } = Select;
const productStrOptions = Object.keys(PRODUCT_STRATEGY_TYPE).map(keyname => { return <Option key={keyname} value={keyname}>{PRODUCT_STRATEGY_TYPE[keyname]}</Option> })

export default function AddProduct(props) {
  const [bindingType, setbindingType] = useState(1);
  const [brokerageType, setbrokerageType] = useState('1');
  const [visible, setvisible] = useState(false);
  const [brokeragesList, setBrokeragesList] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [initAsset, setinitAsset] = useState([]);
  const [securitiesAccounts, setsecuritiesAccounts] = useState([]);
  const [phone, setphone] = useState(null);
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();

  useEffect(() => {
    async function _get() {
      const data = await getBrokerages();
      if (data.code === '200') {
        setBrokeragesList(data.data);
      }
    }
    _get();
  }, [])

  // 提交添加产品选项
  async function onFinish(values) {
    let params = { ...values, bindType: String(values.bindType) };
    if (bindingType === 1) {
      if (securitiesAccounts.length === 0) return message.error('请绑定证券账户');
      params.securitiesAccounts = securitiesAccounts;
    } else if (bindingType === 2) {
      if (!phone) return message.error('请绑定证券账户手机号');
      params.phone = phone;
    }
    params.initAsset = initAsset;
    Modal.confirm({
      title: '是否添加该产品',
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const data = await addProduct(params);
        // console.log('add', data);
        if (data.code === '200') {
          // message.success('添加成功');
          if (bindingType === 1) {
            const data1 = await initAccount({ securitiesAccounts: params.securitiesAccounts });
            // console.log('初始化', data1);
            if (data1.code === '200' && bindingType === 1) {
              const data2 = await initProductOriginAssets({ productId: String(data.data.productId) });
              if (data2.code === '200') {
                message.success('添加成功');
              } else {
                message.error(data2.message);
              }
              // console.log('更新初始资金',data2);
            }
          }
          props.history.push({
            pathname: '/home/AssetsShow',
            state: { key: "1" }
          });

        } else {
          message.error(data.message);
        }
      }
    });
  }
  // 打开线上线下绑定弹窗
  async function _bindingType() {
    if (bindingType === 1) {
      if (securitiesAccounts.length === 3) {
        return message.error('线上绑定证券账户不能超过3个');
      }
    }
    if (brokeragesList.length !== 0) {
      if (brokerageType === '1') {
        let brr = [];
        brokeragesList.map(item => {
          if (item.brokerageType === 1) {
            brr.push(item);
          }
          return item;
        })
        setBrokerages(brr);
      }
      if (brokerageType === '2') {
        let brr = [];
        brokeragesList.map(item => {
          if (item.brokerageType === 2) {
            brr.push(item);
          }
          return item;
        })
        setBrokerages(brr);
      }
    }
    setvisible(true);
  }
  function handleCancel() {
    setvisible(false);
    modalForm.resetFields();
  }
  // 切换线上线下绑定 清空数据
  function _bindingTypeChange(e) {
    setbindingType(e);
    setsecuritiesAccounts([]);
    setphone(null);
  }
  // 删除当前指定线上绑定
  function _securitiesAccountsDel(e, index) {
    e.preventDefault();
    let arr = JSON.parse(JSON.stringify(securitiesAccounts));
    arr.splice(index, 1);
    setsecuritiesAccounts(arr);
  }
  // 确认线上绑定或线下绑定
  async function modalOnFinish() {
    const values = await modalForm.validateFields();
    if (bindingType === 1) {          // 线上绑定
      let brokerage = JSON.parse(values.brokerage);
      let params = {};
      if (brokerageType === '1') {
        params = {
          brokerageId: String(brokerage.id),
          securityType: brokerage.securityType,
          customerNumber: values.customerNumber,
          normalTradeUnit: String(values.normalTradeUnit),
          creditTradeUnit: values.creditTradeUnit,
          password: values.password,
          tradeAccount: String(values.tradeAccount),
        }
      } else if (brokerageType === '2') {
        params = {
          brokerageId: String(brokerage.id),
          securityType: brokerage.securityType,
          customerNumber: values.customerNumber,
          password: values.password,
          tradeAccount: String(values.tradeAccount),
        }
      }
      const data = await checkTradeAccount(params);
      if (data.code === '200') {
        // 校验成功 退出弹窗 保存数据
        message.success('校验成功');
        let arr = JSON.parse(JSON.stringify(securitiesAccounts));
        arr.push(params);
        setsecuritiesAccounts(arr);
        handleCancel();
      } else {
        // 如果校验失败 
        message.error(data.message);
      }
    } else if (bindingType === 2) {    // 线下绑定
      setphone(values.phone);
      handleCancel();
      Modal.confirm({
        title: '信息已提交，请等待工作人员和您联系',
        content: '',
        okText: '确认',
        cancelText: '取消',
      });
    } else {

    }
  }

  return (
    <>
      <PageHeader
        title={'添加产品'}
        onBack={() => props.history?.goBack()}
        style={{ backgroundColor: 'white' }}
      ></PageHeader>

      <div style={{ backgroundColor: '#fff', marginTop: 10, height: '98vh' }}>
        <Row >
          <Col span={6}></Col>
          <Col span={12}>
            <br /><br /><br /><br /><br /><br /><br /><br /><br />

            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{ bindType: bindingType }}
            >
              <Form.Item
                label="产品登记编号"
                name='registraionNumber'
                rules={[{ required: true, message: '请输入产品登记编号', },]}
              >
                <Input placeholder="请输入产品登记编号" />
              </Form.Item>

              <Form.Item
                label="备案产品名称"
                name='name'
                rules={[{ required: true, message: '请输入备案产品名称', },]}
              >
                <Input placeholder="请输入备案产品名称" />
              </Form.Item>

              <Form.Item
                label="产品策略类型"
                name="strategyType"
                rules={[{ required: true, message: '请选择产品策略类型', },]}
              >
                <Select placeholder="请选择产品策略类型">
                  {productStrOptions}
                </Select>
              </Form.Item>

              <Row align="stretch">
                <Form.Item
                  label="绑定证券账户方式及类型"
                  name="bindType"
                  rules={[{ required: true, message: '请选择绑定证券账户及类型', },]}
                >
                  <Select placeholder="" style={{ width: 120 }} onChange={_bindingTypeChange}>
                    <Option value={1} >线上绑定</Option>
                    <Option value={2} >线下绑定</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label=""
                  name="accountType"
                  rules={[{ required: true, message: '请选择备案账户类型', },]}
                >
                  <Select placeholder="请选择证券账户类型" style={{ width: 180, marginLeft: 20 }} onChange={e => { setbrokerageType(e) }}>
                    <Option value='1'>股票账户</Option>
                    <Option value='2'>期货账户</Option>
                    <Option value='3'>大宗商品账户</Option>
                  </Select>
                </Form.Item>
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={_bindingType}
                >
                  {bindingType === 1 ? securitiesAccounts.length === 0 ? '添加' : '继续添加' : '绑定'}
                </Button>
              </Row>

              <Row>
                {
                  securitiesAccounts.length !== 0 &&
                  securitiesAccounts.map((item, index) => (
                    <Tag
                      style={{ margin: '10px 10px 30px 10px' }}
                      color="#2db7f5"
                      key={index}
                      closable
                      onClose={e => _securitiesAccountsDel(e, index)}
                    >
                      {item.customerNumber}
                    </Tag>
                  ))
                }
              </Row>


              <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                <Button type="primary" htmlType="submit" block>提交</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={6}></Col>
        </Row>
        {/* 选绑定证券账户的方式 */}
        <Modal
          title={`线${bindingType === 1 ? '上' : '下'}添加`}
          visible={visible}
          onCancel={handleCancel}
          onOk={modalOnFinish}
          maskClosable={false}
          okText='添加'
        >
          <Form
            form={modalForm}
            initialValues={{}}
          >
            {
              bindingType === 1 ?
                <>
                  <Form.Item name="brokerage" rules={[{ required: true, message: '请选择股票/期货账户', },]} >
                    <Select placeholder={`请选择${brokerageType === '1' ? '股票' : '期货'}账户`} >
                      {
                        brokerages.length !== 0 && brokerages.map(item => (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.name}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label=""
                    name='customerNumber'
                    rules={[{ required: true, message: '请输入股票/期货账户名称', },]}
                  >
                    <Input placeholder={`请输入${brokerageType === '1' ? '股票' : '期货'}账户名称`} />
                  </Form.Item>
                  {
                    brokerageType === '1' &&
                    <Form.Item
                      label=""
                      name='normalTradeUnit'
                      validateTrigger='onBlur'
                      rules={[{
                        required: true,
                        validator: async (_, value) => {
                          if (value) {
                            const data = await isRepeatTradeUnit({ tradeUnit: value });
                            if (data.code === '200') {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(data.message));
                          } else {
                            return Promise.reject(new Error('请输入普通交易单元'));
                          }
                        }
                      },]}
                    >
                      <InputNumber placeholder="请输入普通交易单元" style={{ width: '100%' }} />
                    </Form.Item>
                  }
                  {
                    brokerageType === '1' &&
                    <Form.Item
                      label=""
                      name='creditTradeUnit'
                    // rules={[{ required: true, message: '请输入信用交易单元', },]}
                    >
                      <InputNumber placeholder="请输入信用交易单元" style={{ width: '100%' }} />
                    </Form.Item>
                  }
                  <Form.Item
                    label=""
                    name='tradeAccount'
                    rules={[{ required: true, message: '请输入交易账户', },]}
                  >
                    <InputNumber placeholder="请输入交易账户" style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item
                    label=""
                    name='password'
                    rules={[{ required: true, message: '请输入交易密码', },]}
                  >
                    <Input placeholder="请输入交易密码" />
                  </Form.Item>

                  <Form.Item
                    label=""
                    name='initAsset'
                    rules={[{ required: true, message: '请输入初始资金', },]}
                  >
                    <InputNumber placeholder="请输入初始资金" style={{ width: '100%' }} onChange={setinitAsset} />
                  </Form.Item>
                </>
                :
                <>
                  <Form.Item
                    label=""
                    name='phone'
                    rules={[{ required: true, message: '请输入您的联系方式', },]}
                  >
                    <Input placeholder="请输入您的联系方式" />
                  </Form.Item>
                </>
            }

          </Form>
        </Modal>
      </div>
    </>
  )
}