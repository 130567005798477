import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Form,
  Card,
  Button,
  Select,
  InputNumber,
  message
} from 'antd';
import { startWithdrawal, depositToBalance } from '@/api/userSystem';
import CryptoJS from 'crypto-js'
import _ from 'lodash';

const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const WithdrawalForm = (props) => {
  const [form] = Form.useForm();
  const [disType, setDisType] = useState([])
  const [bal, setBal] = useState() // 当前提现余额

  const isFromReward = _.get(props, 'location.state.fromReward', false);

  useEffect(() => {
    //_getAllLevel();
    console.log('路由:', props.location.state.accountStatus)
    const getAccountData = props.location.state.accountStatus;
    const getAmount = parseFloat(_.get(props, 'location.state.balance', 0));

    if (getAccountData) {
      let setValue = { amount: getAmount }
      let disArray = [];
      if (getAccountData.alipay && !getAccountData.wechat) {
        setValue.transferType = 'alipay'
        disArray.push('wechat');
      }
      if (getAccountData.wechat && !getAccountData.alipay) {
        setValue.transferType = 'wechat'
        disArray.push('alipay');
      }
      if (!getAccountData.wechat && !getAccountData.alipay) {
        disArray = ['wechat', 'alipay']
      }
      setDisType(disArray)
      setBal(getAmount)
      form.setFieldsValue(setValue);
    } else {
      setBal(getAmount);
      form.setFieldsValue({ amount: getAmount });
    }

    return () => { return; }
  }, [])

  async function submit(value) {
    // md5 加密生成校验字符串
    let md5sign = `amount${value.amount}transfertype${value.transferType}tamp_service_inner_key`
    let params = {
      amount: value.amount,
      transferType: value.transferType,
      sign: CryptoJS.MD5(_.toLower(md5sign)).toString()
    }

    const res = await startWithdrawal(params);
    if (_.get(res, 'code') === '200') {
      message.success(_.get(res, 'message', ''))
      props.history.goBack()
    } else {
      message.warning('提现失败！')
    }
  }

  async function _depositToBalance(value) {
    let params = {
      amount: value.amount,
    }

    const res = await depositToBalance(params);
    if (_.get(res, 'code') === '200') {
      message.success(_.get(res, 'message', ''))
      props.history.goBack()
    } else {
      message.warning('转化失败！')
    }
  }

  const onFinish = (values) => {
    if (isFromReward) {
      _depositToBalance(values)
    } else {
      submit(values)
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const checkPrice = (_, value) => {
    let reg = /(^(([1-9]([0-9]+)?)|(0{1}))(\.[0-9]{1,2})?$)/;

    if (value > bal) {
      return Promise.reject(new Error('当前提现金额不足！当前金额:' + bal));
    }

    if (reg.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('请输入正确的金额'));
  };


  return (
    <div>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={isFromReward ? '押金转余额' : '提现'}
        style={{ backgroundColor: 'white' }}
      >
      </PageHeader>
      <div style={{ margin: 10 }}>
        <Card>
          <Form {...layout} form={form} name="withdrawal-form" onFinish={onFinish}>
            {!isFromReward && <Form.Item
              name="transferType"
              label="提现方式"
              rules={[{
                required: true,
              }]}
            >
              <Select style={{ width: 220 }}>
                <Option value={'alipay'} disabled={_.includes(disType, 'alipay')}>支付宝</Option>
                <Option value={'wechat'} disabled={_.includes(disType, 'wechat')}>微信支付</Option>
              </Select>
            </Form.Item>}
            <Form.Item
              name="amount"
              label={isFromReward ? '划转金额' : "提现金额"}
              rules={[{ required: true, validator: checkPrice }]}
            >
              <InputNumber step={0.01} min={0} style={{ width: 220 }} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={_.size(disType) === 2 ? true : false}
              >
                提交
              </Button>
              {/* <Button htmlType="button" onClick={onReset} style={{ marginLeft: 8 }}>
            重置
          </Button> */}
            </Form.Item>
          </Form>
        </Card>

      </div>
    </div>
  )
}

export default WithdrawalForm;