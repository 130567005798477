import React, { useEffect, useState } from 'react';
import { Card, Col, Table, Row, Progress, Form, Input, Space, Spin, Button, Modal, Select, Cascader, message, InputNumber, Tabs, DatePicker, Tag, List } from 'antd';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import {
    getNowWarningInfo, getWarningCondition, Firmoffermonitoring, getSubAccountRules, postSubAccountRules, CreatePositionRules, Transactionsummary, Transactionsum, plate, excuteRiskControl
    , mShowChildrenList
} from '@/api/workbench';
import { formatDate } from '@/utils/common';
import moment from 'moment';
import _ from 'lodash';
import './RiskAdm.scss'

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

export default function RiskAdm(props) {
    const mobxStore = useLocalObservable(() => store);
    const [subList, setSubList] = useState(JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
    const [nowWarningInfo, setNowWarningInfo] = useState({ warningLineList: [], warningPeopleList: [], unwindLineList: [] });
    const [warningCondition, setwarningCondition] = useState({
        unwindLine: { now: 0, percentage: 0, history: 0 },
        warningLine: { now: 0, percentage: 0, history: 0 },
        warningPeople: { now: 0, percentage: 0, history: 0 },
    });
    const [warningPeriod, setwarningPeriod] = useState();
    const [warningPeriodReal, setwarningPeriodReal] = useState();
    const [cascaderValue, setcascaderValue] = useState([]);
    const [cascaderValue2, setcascaderValue2] = useState([]);
    const [cascaderValue3, setcascaderValue3] = useState([]);
    const [cascaderValue4, setcascaderValue4] = useState([]);
    const [cascaderValue5, setcascaderValue5] = useState([]);
    const [isNewManager, setIsNewManager] = useState(_.get(props, 'location.state.userRole', '') === 'trader_manager' ? true : false);
    const [subData, setsubData] = useState(null);            // 选择的子账户数据
    const [spinning, setspinning] = useState(false);
    const [time, timer] = useState([]);
    const [pageindex, cuurr] = useState(1);
    const [pageSizeindex, pageSizeindexs] = useState(5);
    const [zid, zidl] = useState();
    const [sum, cuwwuwrr] = useState(0);
    const [controlmodule, setcontrolmodule] = useState([{}]);
    const [Stockamount, cuwwurr] = useState(2);
    const [Stockcode, cuw22wurr] = useState(1);
    const [tabledata, Transactiondata] = useState([]);
    const [tabledatas, Transactiondatas] = useState([]);
    const [datelist, getdatelist] = useState([]);
    const [plactdata, platedatalist] = useState([]);
    const [form] = Form.useForm();
    const [positionForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalBottomVisible, setIsModalBottomVisible] = useState(false);
    // const [DiskInspection, DiskInspectionlist] = useState();
    const [rateOfReturn, DiskInspectionlists] = useState();
    const [winRate, DiskInspectionlistss] = useState();
    const [orderCancellationRatio, DiskInspectionlistsss] = useState();
    const [rateOfReturns, DiskInspec] = useState();
    const [winRates, DiskInspe] = useState();
    const [orderCancellationRatios, DiskInsp] = useState();
    const showModal = () => {
        if (!subData) return message.error('请先选择账户');
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    async function Transactions(dateStrings, value) {
        timer(prevList => ([...prevList, value]))
        let params = { subAccountId: subData.subAccountId, startDate: moment(value[0]).format('YYYY-MM-DD H:mm:ss'), endDate: moment(value[1]).format('YYYY-MM-DD H:mm:ss') };
        const data = await Transactionsum(params);
        Transactiondata(data.data)
    }
    function timesChange(dateStrings, value) {
        if (!subData) return message.error('请先选择账户');
        Transactions(dateStrings, value)
    }
    async function handleOk() {
        const data = await excuteRiskControl({ subAccountId: subData.subAccountId, riskDay: warningPeriod, lineType: '8' })
        if (data.code === '200') {
            message.success(data.message);
        } else {
            message.error(data.message);
        }
        setIsModalVisible(false);
    };
    async function handleOkReal() {
        const data = await excuteRiskControl({ subAccountId: subData.subAccountId, riskDay: warningPeriodReal, lineType: '10' })
        if (data.code === '200') {
            message.success(data.message);
        } else {
            message.error(data.message);
        }
        setIsModalBottomVisible(false);
    };
    function timesChanges(dateStrings, value) {
        if (!subData) return message.error('请先选择账户');
        Transactionss(dateStrings, value)
    }
    //汇总
    async function Transactionss(dateStrings, value) {
        let params = { subAccountId: subData.subAccountId, startDate: moment(value[0]).format('YYYY-MM-DD h:mm:ss'), endDate: moment(value[1]).format('YYYY-MM-DD h:mm:ss') };
        const data = await Transactionsummary(params);
        Transactiondatas(data.data)
    }
    async function onChange6(dateString) {
        if (!subData) return message.error('请先选择账户');
        let params = { subAccountId: subData.subAccountId, date: moment(dateString).format('YYYY-MM-DD') };
        const data = await Firmoffermonitoring(params);
        if (data.code === '200') {
            DiskInspec(data.data.rateOfReturn)
            DiskInspe(data.data.winRate)
            DiskInsp(data.data.orderCancellationRatio)
        } else {
            DiskInspec(0)
            DiskInspe(0)
            DiskInsp(0)
        }
    }

    const columns = [
        {
            title: '交易员',
            align: 'center',
            dataIndex: 'userName',
        },
        {
            title: '股票代码',
            align: 'center',
            dataIndex: 'stock_code',
        },
        {
            title: '股票名称',
            align: 'center',
            dataIndex: 'stock_name',
        },
        {
            title: '手数',
            align: 'center',
            dataIndex: 'primaryStockNum',
        },
        {
            title: '成交均价',
            align: 'center',
            dataIndex: 'avgPrice',
        },
        {
            title: '实际收益率%',
            align: 'center',
            dataIndex: 'actualIntradayRateOfReturn',
            render: (text) => {
                return <span>{parseFloat(text * 100).toFixed(2) + '%'}</span>
            }
        },
        {
            title: '个股收益率%',
            align: 'center',
            dataIndex: 'intradayStockYield',
            render: (text) => {
                return <span>{parseFloat(text * 100).toFixed(2) + '%'}</span>
            }
        },
        {
            title: '超额收益率%',
            align: 'center',
            dataIndex: 'excessRateOfReturn',
            render: (text) => {
                return <span>{parseFloat(text * 100).toFixed(2) + '%'}</span>
            }
        },
        {
            title: '交易方向',
            align: 'center',
            dataIndex: 'side',
            render: (text) => {
                return text === 'BUY' ? <span>买入</span> : <span>卖出</span>
            }

        },
        {
            title: '订单交易时间',
            dataIndex: 'tradeTime',
            align: 'center',
            render: (text, record, index) => {
                return <span>{moment(text).format('YYYY-MM-DD h:mm:ss')}</span>
            }

        },
    ]
    const column = [
        {
            title: '股票名称',
            align: 'center',
            dataIndex: 'stockName',
        },
        {
            title: '股票代码',
            align: 'center',
            dataIndex: 'stockCode',
        },
        {
            title: '总超额收益率%',
            align: 'center',
            dataIndex: 'totalExcessRateOfReturn',
            render: (text, record, index) => {
                return <span>{text.toFixed(2) * 100 + '%'}</span>
            }
        },
        {
            title: '历史超额收益率',
            align: 'center',
            dataIndex: 'historicalExcessRateOfReturn',
            render: (text, record, index) => {
                return <span>{text.toFixed(2) * 100 + '%'}</span>
            }
        },
    ]
    async function platelist() {
        const data = await plate();
        platedatalist(data.data)
    }

    useEffect(() => {
        platelist()
        cuwwuwrr(Stockamount - Stockcode)
        async function _get() {
            const data = await getNowWarningInfo();
            if (data.code === '200') {
                setNowWarningInfo(data.data);
            }
            const data1 = await getWarningCondition({ dateType: 'ONE_WEEK' });
            if (data1.code === '200') {
                setwarningCondition(data1.data);
            }
        }
        async function _getChildren() {
            const dataSub = await mShowChildrenList();
            if (dataSub.code === '200') {
                let newData = _.get(dataSub, 'data', []).map(n => {
                    let child = _.get(n, 'subAccountList', []).map(s => {
                        return { label: s.name, value: parseInt(s.id) }
                    })
                    return {
                        label: n.parentName,
                        value: n.pid,
                        children: child
                    }
                })
                setSubList(newData);
            } else {
                setSubList([]);
            }
        }
        _get();

        if (isNewManager) {
            _getChildren()
        }

    }, [controlmodule, tabledata, datelist])
    // 获取本月预警情况与历史占比
    async function _getWarningRatio(type) {
        const data1 = await getWarningCondition({ dateType: type });
        if (data1.code === '200') {
            setwarningCondition(data1.data);
        } else {
            message.error(data1.message);
        }
    }
    function remove(e, index) {
        e.preventDefault();
        let arr = JSON.parse(JSON.stringify(datelist));
        arr.splice(index, 1);
        getdatelist(arr)
    }
    // 获取账户风控规则
    async function _getSubAccountRules(id, num) {
        setspinning(true);
        let data = await getSubAccountRules({ subAccountId: id });
        if (data.code === '200') {
            if (num !== 2) {
                form.setFieldsValue(data.data);
            } else {
                let params = {
                    stockAssetsAlertLine: data.data.stockAssetsAlertLine,
                    singleStockAlertLine: data.data.singleStockAlertLine,
                }
                positionForm.setFieldsValue(params);
                if (data.data.plateAlertLine) { getdatelist(data.data.plateAlertLine) };
            }
        } else {
            message.error(data.message);
        }
        setspinning(false);
    }

    // 选择子账户
    async function handleChange(value) {
        if (value.length === 3) {
            setcascaderValue(value);
            // 根据 key 找到当前选中的子账户
            let data = subList[value[0]].children[value[1]].children[value[2]];
            setsubData(data);
            _getSubAccountRules(data.subAccountId);
        } else {
            message.error('该证券账户下暂无子账户！');
        }
    }
    async function handleChange2(value) {
        if (value.length === 3) {
            setcascaderValue2(value);
            let data = subList[value[0]].children[value[1]].children[value[2]];
            setsubData(data);
            _getSubAccountRules(data.subAccountId, 2);
        } else {
            message.error('该证券账户下暂无子账户！');
        }
    }
    async function handleChange3(value) {
        if (value.length === 3) {
            setcascaderValue3(value);
            // 根据 key 找到当前选中的子账户
            let data = subList[value[0]].children[value[1]].children[value[2]];
            setsubData(data);
            _getSubAccountRules(data.subAccountId);
            zidl(data.subAccountId)
        } else {
            message.error('该证券账户下暂无子账户！');
        }
    }
    async function handleChange4(value) {

        if (value.length === 3) {
            setcascaderValue4(value);
            // 根据 key 找到当前选中的子账户
            let data = subList[value[0]].children[value[1]].children[value[2]];
            setsubData(data);
            _getSubAccountRules(data.subAccountId);

        } else {
            message.error('该证券账户下暂无子账户！');
        }
    }
    // 基金经理新增选择
    async function handleChange5(value) {
        console.log('新子账户', value)
        if (value.length === 2) {
            setcascaderValue5(value);
            // 根据 key 找到当前选中的子账户
            let data = value[1];
            setsubData({subAccountId:data});
            _getSubAccountRules(data);

        } else {
            message.error('该证券账户下暂无子账户！');
        }
    }
    // 修改风险
    function cwtype(value) {
        setcontrolmodule(value)
    }

    async function onFinish1() {
        if (!subData) return message.error('请先选择账户');
        const values = await positionForm.validateFields();
        let params = {
            subAccountId: subData.subAccountId,
            stockAssetsAlertLine: values.stockAssetsAlertLine,
            singleStockAlertLine: values.singleStockAlertLine,
            plateAlertList: datelist
        };
        const data = await CreatePositionRules(params);
        if (data.code === '200') {
            message.success('修改成功');
        } else {
            message.error(data.message);
        }
    }
    async function onFinish() {
        if (!subData) return message.error('请先选择账户');
        const values = await form.validateFields();
        let params = { subAccountId: subData.subAccountId, ...values };
        const data = await postSubAccountRules(params);
        if (data.code === '200') {
            _getSubAccountRules(subData.subAccountId);
            message.success('修改成功');
        } else {
            message.error(data.message);
        }
    }

    function addchonelist() {
        let data = {
            var: positionForm.getFieldsValue().testformval,
            plate: controlmodule,
        }
        getdatelist(oldArray => [...oldArray, data])
    }

    function onChange(value) {
        _getWarningRatio(value);
    }
    async function timeChange(value) {
        if (!subData) return message.error('请先选择账户');
        let params = { subAccountId: subData.subAccountId, dateType: value.value };
        const data = await Firmoffermonitoring(params);
        if (data.code === '200') {
            DiskInspectionlists(data.data.rateOfReturn)
            DiskInspectionlistss(data.data.winRate)
            DiskInspectionlistsss(data.data.orderCancellationRatio)
        } else {
            DiskInspectionlists(0)
            DiskInspectionlistss(0)
            DiskInspectionlistsss(0)
        }
    }

    return (
        <Observer>
            {() => (
                <div className="contentView">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card
                                title="本月预警情况与历史占比"
                                extra={
                                    <Select defaultValue="ONE_WEEK" style={{ width: 200 }} onChange={onChange}>
                                        <Option value="ONE_WEEK">一周</Option>
                                        <Option value="ONE_MONTH">一月</Option>
                                        <Option value="THREE_MONTH">三月</Option>
                                        <Option value="SIX_MONTH">六月</Option>
                                        <Option value="ONE_YEAR">一年</Option>
                                        <Option value="THREE_YEAR">三年</Option>
                                    </Select>
                                }
                            >
                                <Row>
                                    <Col span={8}>
                                        <Row gutter={[0, 16]}>
                                            <Col span={24}>
                                                <Row justify="center" >
                                                    <Progress
                                                        strokeWidth={12}
                                                        width={145}
                                                        type="circle"
                                                        strokeColor="royalblue"
                                                        // percent={handlePercentage(warningCondition.warningLine.percentage)}
                                                        percent={100}
                                                        format={percent => (
                                                            <div style={{ fontSize: 16 }} className="colorGrey">
                                                                <br />
                                                                <p>{warningCondition.warningLine.now}次</p>
                                                                {/* <p>
                                                                    {handlePercentage(warningCondition.warningLine.percentage)}%
                                                                </p> */}
                                                            </div>
                                                        )}
                                                    />
                                                </Row>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}><span>触发预警线</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row gutter={[0, 16]}>
                                            <Col span={24}>
                                                <Row justify="center" >
                                                    <Progress
                                                        strokeWidth={12}
                                                        width={145}
                                                        strokeColor="royalblue"
                                                        type="circle"
                                                        // percent={handlePercentage(warningCondition.warningPeople.percentage)}
                                                        percent={100}
                                                        format={percent => (
                                                            <div style={{ fontSize: 16 }} className="colorGrey">
                                                                <br />
                                                                <p>{warningCondition.warningPeople.now}人</p>
                                                                {/* <p>{handlePercentage(warningCondition.warningPeople.percentage)}%</p> */}
                                                            </div>
                                                        )}
                                                    />
                                                </Row>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}><span>当前处于预警期人数</span></Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row gutter={[0, 16]}>
                                            <Col span={24}>
                                                <Row justify="center" >
                                                    <Progress
                                                        strokeWidth={12}
                                                        width={145}
                                                        type="circle"
                                                        strokeColor="royalblue"
                                                        // percent={handlePercentage(warningCondition.unwindLine.percentage)}
                                                        percent={100}
                                                        format={percent => (
                                                            <div style={{ fontSize: 16 }} className="colorGrey">
                                                                <br />
                                                                <p>{warningCondition.unwindLine.now}次</p>
                                                                {/* <p>{handlePercentage(warningCondition.unwindLine.percentage)}%</p> */}
                                                            </div>
                                                        )}
                                                    />
                                                </Row>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center' }}><span>触发平仓线</span></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>

                            <br />

                            <Card title="处于预警期人员" bordered={false} >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={nowWarningInfo.warningPeopleList}
                                    pagination={{
                                        defaultPageSize: 5,
                                        hideOnSinglePage: true
                                    }}
                                    renderItem={item => (
                                        <List.Item>
                                            <Row justify="space-between" key={item.tradersName}>
                                                <p>
                                                    <span>{item.tradersName}</span>
                                                    <span style={{ marginLeft: 10 }}>{item.securitiesName}</span>
                                                    <span style={{ marginLeft: 10 }}>{item.productName}</span>
                                                </p>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                            <br />
                            <Card title=" 触发预警线记录" bordered={false}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={nowWarningInfo.warningLineList}
                                    pagination={{
                                        defaultPageSize: 5,
                                        hideOnSinglePage: true
                                    }}
                                    renderItem={item => (
                                        <List.Item actions={[<span className="colorGrey">{formatDate(item.createTime)}</span>]}>
                                            <Row justify="space-between" key={item.id}>
                                                <p>
                                                    <span>{item.tradersName}</span>
                                                    <span style={{ marginLeft: 10 }}>{item.securitiesName}</span>
                                                    <span style={{ marginLeft: 10 }}>{item.productName}</span>
                                                </p>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                            <br />
                            <Card title="本月触发平仓线记录" bordered={false}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={nowWarningInfo.unwindLineList}
                                    pagination={{
                                        defaultPageSize: 5,
                                        hideOnSinglePage: true
                                    }}
                                    renderItem={item => (
                                        <List.Item actions={[<span className="colorGrey">{formatDate(item.createTime)}</span>]}>
                                            <Row justify="space-between" key={item.id}>
                                                <p>
                                                    <span>{item.tradersName}</span>
                                                    <span style={{ marginLeft: 10 }}>{item.securitiesName}</span>
                                                    <span style={{ marginLeft: 10 }}>{item.productName}</span>
                                                </p>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Tabs onChange={() => { }} type="card" defaultActiveKey="1" >
                                <TabPane tab="净值风控" key="1">
                                    <Card title="日内净值风控" style={{ minHeight: 600, width: '100%' }}>
                                        <Spin spinning={spinning}>
                                            <Row justify="start" align="middle">
                                                <span style={{ width: '20%', textAlign: 'center' }}>选择子账户:</span>
                                                <Cascader
                                                    options={subList}
                                                    onChange={isNewManager ? handleChange5 : handleChange}
                                                    value={isNewManager ? cascaderValue5 : cascaderValue}
                                                    style={{ width: '80%' }}
                                                />
                                            </Row>

                                            <br /><br />
                                            <Row justify="center">
                                                <Form
                                                    form={form}
                                                    initialValues={{}}
                                                    layout="vertical"
                                                    style={{ width: '70%' }}
                                                >
                                                    <Form.Item
                                                        label="预警线设置"
                                                        name='alertLine'
                                                        rules={[{ required: true, message: '请输入预警线设置', },]}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            step="0.1"
                                                            formatter={value => `${value}`}
                                                            parser={value => value.replace('%', '')}

                                                            placeholder="请输入净值的百分比"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                    <p className="colorGrey">当账户净值低于此百分比时系统触发预警线。此时系统将给出提示，禁止开新仓，若收盘后净值超过此百分比，则在第二个交易日解除禁止指令</p>

                                                    <Form.Item
                                                        label="预警期设置"
                                                        name='alertDays'
                                                        rules={[{ required: true, message: '请输入预警期时间', },]}
                                                    >
                                                        <InputNumber
                                                            min={1}
                                                            step="0.1"

                                                            style={{ width: '100%' }}
                                                            placeholder="请输入预警期时间"
                                                        />
                                                    </Form.Item>
                                                    <p className="colorGrey">当账户触发预警线后自动进入预警期，此时系统将给出提示，禁止开新仓，预警期到期后第二个交易日解除禁止指令</p>

                                                    <Form.Item
                                                        label="平仓线设置"
                                                        name='closeLine'
                                                    // rules={[{ required: true, message: '请输入平仓线设置', },]}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            step="0.1"

                                                            formatter={value => `${value}`}
                                                            parser={value => value.replace('%', '')}
                                                            placeholder="请输入净值的百分比"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                    <p className="colorGrey">当账户净值低于此百分比时系统触发平仓线。此时系统将给出提示，此时经理有权锁定并强平该帐户所有持仓并停止交易员交易，同时结算亏损金额。</p>

                                                    <Button type="primary" onClick={onFinish}>修改</Button>

                                                </Form>
                                            </Row>
                                        </Spin>
                                    </Card>
                                </TabPane>
                                <TabPane tab="仓位风控" key="2">
                                    <Card title="仓位风控" style={{ minHeight: 600, width: '100%' }}>
                                        <Spin spinning={spinning}>
                                            <Row justify="start" align="middle">
                                                <span style={{ width: '20%', textAlign: 'center' }}>选择子账户:</span>
                                                <Cascader
                                                    options={subList}
                                                    onChange={isNewManager ? handleChange5 : handleChange2}
                                                    value={isNewManager ? cascaderValue5 : cascaderValue2}
                                                    style={{ width: '65%' }}
                                                />
                                            </Row>
                                            <br />
                                            <Row justify="center">
                                                <Form
                                                    form={positionForm}
                                                    initialValues={{}}
                                                    layout="vertical"
                                                    style={{ width: '70%' }}
                                                >

                                                    <Form.Item
                                                        label="子账户仓位风控设置"
                                                    // rules={[{ required: true, message: '请输入仓位占比', },]}
                                                    >
                                                        <Form.Item name='stockAssetsAlertLine'>
                                                            <InputNumber
                                                                min={0}
                                                                max={100}
                                                                step="0.1"
                                                                formatter={value => `${value}`}
                                                                parser={value => value.replace('%', '')}
                                                                placeholder="请输入仓位占比"
                                                                style={{ width: '100%' }}
                                                            />
                                                        </Form.Item>
                                                        {
                                                            plactdata.map((item, index) => {
                                                                return <Option key={index} value={item}>{item}</Option>
                                                            })
                                                        }
                                                    </Form.Item>
                                                    <p className="colorGrey">交易员持仓占比达到整体仓位风控阈值后自动触发风控线，此时交易员不可执行开仓操作，当交易员出售资产或有新资金划转导致资产占比低于风控线后方可继续开仓</p>

                                                    <Form.Item
                                                        label="行业仓位风控设置"
                                                        name='testformval'
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            step="0.1"

                                                            formatter={value => `${value}`}
                                                            parser={value => value.replace('%', '')}
                                                            placeholder="请输入仓位占比"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                    <p className="colorGrey">交易员持有某板块股票占比达到板块占比风控阈值后自动触发风控线，此时交易员不可执行对该板块股票的购买操作，当交易员出售该股或有新资金划转导致占比低于风控线后方可继续开仓</p>

                                                    <Row align="stretch">
                                                        <Form.Item
                                                            label=""
                                                            name='industryPosition'
                                                        >
                                                            <Select
                                                                showSearch
                                                                onChange={cwtype}
                                                                style={{ width: 200 }}
                                                                placeholder="请选择模块"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {
                                                                    plactdata.map((item, index) => {
                                                                        return <Option key={index} value={item}>{item}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                        <Button style={{ marginLeft: 20 }} type="primary" onClick={() => { addchonelist() }}>添加</Button>
                                                    </Row>
                                                    <Row>
                                                        {
                                                            datelist.length !== 0 &&
                                                            datelist.map((item, index) => (
                                                                <Tag
                                                                    style={{ margin: '10px 10px 30px 10px' }}
                                                                    color="#2db7f5"
                                                                    key={index}
                                                                    closable
                                                                    onClose={e => { remove(e, index) }}
                                                                >
                                                                    <span >行业:{item.plate}</span>
                                                                    <br />
                                                                    <span >行业风控比例:{item.var}</span>
                                                                </Tag>
                                                            ))
                                                        }
                                                        {/* {
                                                            datelist.map((item,index)=>{
                                                                return  <li key={index} style={{listStyle:'none',
                                                                width: '400px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between'}}>
                                                                    <span >行业:{item.plate}</span>
                                                                    <span >行业风控比例:{item.var}</span>
                                                                    <button onClick={()=>{
                                                                        remove(item,index)
                                                                    }}>删除</button>
                                                                </li>
                                                            })
                                                        } */}
                                                    </Row>

                                                    <Form.Item
                                                        label="个股仓位风控设置"
                                                        name='singleStockAlertLine'
                                                    // rules={[{ required: true, message: '请输入平仓线设置', },]}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            step="0.1"

                                                            formatter={value => `${value}`}
                                                            parser={value => value.replace('%', '')}
                                                            placeholder="请输入仓位占比"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                    <p className="colorGrey">交易员持有单只股票占比达到单只股票风控阈值后自动触发风控线，此时交易员不可执行对该只股票的购买操作，当交易员出售该股或有新资金划转导致占比低于风控线后方可继续开仓</p>

                                                    <Button type="primary" onClick={onFinish1}>修改</Button>

                                                </Form>
                                            </Row>
                                        </Spin>
                                    </Card>
                                </TabPane>
                                <TabPane tab="交易风控" key="3" style={{ size: '2px' }}>
                                    <Card title="交易风控" style={{ minHeight: 600, width: '100%' }}>
                                        <Spin spinning={spinning}>
                                            <Row justify="start" align="middle">
                                                <span style={{ width: '20%', textAlign: 'center' }}>选择子账户:</span>
                                                <Cascader
                                                    options={subList}
                                                    onChange={isNewManager ? handleChange5 : handleChange3}
                                                    value={isNewManager ? cascaderValue5 : cascaderValue3}
                                                    style={{ width: '80%' }}
                                                />
                                            </Row>

                                            <br /><br />
                                            <Space direction="vertical" size={4}>
                                                <RangePicker
                                                    format="YYYY/MM/DD H:mm:ss"
                                                    onChange={timesChange}
                                                />
                                            </Space>
                                            <span style={{ marginLeft: '80%', textAlign: 'center' }}>共交易{tabledata.length}比</span>
                                        </Spin>
                                        <Row>
                                            <Col span={24}>
                                                <Table style={{ textAlign: 'center' }} rowKey='id' pagination={{ responsive: true, size: "small", total: tabledata.length, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: [5, 10, 30, 50], current: pageindex, pageSize: pageSizeindex }} columns={columns} dataSource={tabledata} onChange={(e) => {
                                                    // console.log(e, '666');
                                                    cuurr(e.current)
                                                    pageSizeindexs(e.pageSize)
                                                    // console.log(pageindex);
                                                }} />
                                            </Col>
                                        </Row>
                                        <div className='button_box'>
                                            <>

                                                <Button type="primary" onClick={showModal}>
                                                    预警
                                                </Button>
                                                <Modal title='预警期' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                                    <span style={{ marginRight: 10 }}>预警期:</span>
                                                    <InputNumber min={0} style={{ width: 150 }} placeholder='请设置预警期' onChange={e => { setwarningPeriod(e) }} />
                                                </Modal>
                                            </>

                                        </div>

                                        <br />
                                        <br />

                                        <Space direction="vertical" size={4}>
                                            <RangePicker
                                                format="YYYY-MM-DD HH:mm"
                                                onChange={timesChanges}
                                            />
                                        </Space>
                                        <br />
                                    </Card>
                                    <Row>
                                        <Col span={24}>
                                            <Table pagination={false} key={1} columns={column} dataSource={tabledatas} />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="实盘监测" key="4">
                                    <Card title="实盘监测" style={{ minHeight: 600, width: '100%' }}>
                                        <Spin spinning={spinning}>
                                            <Row justify="start" align="middle">
                                                <span style={{ width: '20%', textAlign: 'center' }}>选择子账户:</span>
                                                <Cascader
                                                    options={subList}
                                                    onChange={isNewManager ? handleChange5 : handleChange4}
                                                    value={isNewManager ? cascaderValue5 : cascaderValue4}
                                                    style={{ width: '80%' }}
                                                />
                                            </Row>
                                        </Spin>
                                        <br /><br /><br /><br />
                                        <div>
                                            <Space direction="vertical" size={12}>
                                                <DatePicker showToday={false} onChange={onChange6} style={{ marginLeft: '60px' }} />
                                            </Space>
                                            <Row justify='end'>
                                                <Button type="primary" className='left_button' style={{ marginRight: 20 }}>查看</Button>
                                            </Row>
                                        </div>
                                        {/* orderCancellationRatio: 0
                                rateOfReturn: -0.31
                                    winRate: 0 */}

                                        {/* DiskInspec(data.data.rateOfReturn)
        DiskInspe(data.data.winRate)
        DiskInsp(data.data.orderCancellationRatio) */}
                                        <div className='xdb'>
                                            <p> <span>收益率</span><Input placeholder="" value={rateOfReturns ? (rateOfReturns * 100).toFixed(2) + '%' : 0 + '%'} style={{ textAlign: 'center', width: '110px', marginLeft: 10 }} /></p>
                                            <p> <span>胜率</span><Input placeholder="" value={winRates ? (winRates * 100).toFixed(2) + '%' : 0 + '%'} style={{ textAlign: 'center', width: '110px', marginLeft: 10 }} /></p>
                                            <p> <span>下单/撤单比</span><Input placeholder="" value={orderCancellationRatios ? orderCancellationRatios.toFixed(2) : 0} style={{ textAlign: 'center', width: '110px', marginLeft: 10 }} /></p>
                                        </div>

                                        <br /><br />
                                        <Select
                                            labelInValue
                                            defaultValue={{ value: 'ONE_WEEK' }}
                                            style={{ width: 120, marginLeft: '60px' }}
                                            onChange={timeChange}
                                        >
                                            <Option value="ONE_WEEK">一周</Option>
                                            <Option value="ONE_MONTH">一月</Option>
                                            <Option value="THREE_MONTH">三月</Option>
                                            <Option value="ONE_YEAR">半年</Option>
                                            <Option value="ALL">一年</Option>
                                        </Select>
                                        <br /><br />
                                        <div className='xdb'>
                                            <p> <span>收益率</span><Input placeholder="" value={rateOfReturn ? (rateOfReturn * 100).toFixed(2) + '%' : 0 + '%'} style={{ textAlign: 'center', width: '110px', marginLeft: 10 }} /></p>
                                            <p> <span>胜率</span><Input placeholder="" value={winRate ? (winRate * 100).toFixed(2) + '%' : 0 + '%'} style={{ textAlign: 'center', width: '110px', marginLeft: 10 }} /></p>
                                            <p> <span>下单/撤单比</span><Input placeholder="" value={orderCancellationRatio ? orderCancellationRatio.toFixed(2) : 0} style={{ textAlign: 'center', width: '110px', marginLeft: 10 }} /></p>
                                        </div>
                                        <div className='button_box'>

                                            <>
                                                <Button type="primary" onClick={() => { if (!subData) return message.error('请先选择账户'); setIsModalBottomVisible(true) }}>
                                                    预警
                                                </Button>
                                                <Modal title='预警期' visible={isModalBottomVisible} onOk={handleOkReal} onCancel={() => { setIsModalBottomVisible(false) }}>
                                                    <span style={{ marginRight: 10 }}>预警期:</span>
                                                    <InputNumber min={0} style={{ width: 150 }} placeholder='请设置预警期' onChange={e => { setwarningPeriodReal(e) }} />
                                                </Modal>
                                            </>
                                        </div>
                                    </Card>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )}
        </Observer>
    )
}