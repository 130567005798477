import React, { Component } from 'react';
import { Tabs, PageHeader, Modal, message, Button } from 'antd';
import { showAssetsAndRevenue, showAllSubAccount } from '@/api/workbench';
import { showFutureAssetsAndRevenue, showAllSubAccountCal } from '@/api/stock';
import { autoDistributeSub } from '@/api/userSystem';
import TotalComp from './TotalComp';
import AccountCard from './AccountCard';
import { observer, inject } from 'mobx-react';
import addManager from '@/images/mWechatQrcode2.jpg';
import _ from 'lodash';
import './assetDesk.scss';

const { TabPane } = Tabs;
@inject('store')
@observer
class AssetDesk extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo: JSON.parse(JSON.stringify(this.props.store.userInfo)),
      currentKey: '1',
      accountList: [],
      accountData: {},
      accStastic: {},
      futureList: [],
      futureData: {},
      visible: false,
      loader: false,
    }
    this.routeData = props.location.state;
  }

  componentDidMount() {
    this.props.store._isAllStockHoliday();
    this._showAssetsAndRevenue();
    this._showAllSubAccount();
    this._showFutureAssetsAndRevenue();
    this._showAllSubAccountCal();
  }
  
  _showAssetsAndRevenue = async () => {
    const data = await showAssetsAndRevenue();
    if (_.get(data, 'code', '') === '200') {
      this.setState({ accountData: _.get(data, 'data', {}) })
    }
  }
  _showAllSubAccount = async () => {
    const data1 = await showAllSubAccount();
    if (_.get(data1, 'code', '') === '200') {
      const getData = _.get(data1, 'data', []);
      const sizeData = _.size(getData);
      let newList = [];
      let rCount = 0; // 要显示托管账户的账户count
      let nCount = 0; // 无需显示的count
      if (getData && sizeData > 0) {
        getData.map(item => {
          item.netValue = item.netValue.toFixed(4);
          const getResult = _.get(item, 'stockAssetPositionResult', []);
          if (_.size(getResult) > 0) {
            rCount = rCount + 1;
            newList.unshift({ ...item, type: 'result' });
          } else {
            nCount = nCount + 1;
            newList.push({ ...item, type: 'normal' })
          }
        })
      }
      if (rCount > 0 && nCount > 0) {
        newList[rCount].needOffset = 1;
      }
      // console.log('newList', newList)
      this.setState({
        accountList: newList,
        accStastic: { result: rCount, normal: nCount, rEven: rCount % 2 === 1 ? true : false },
        loader: true,
      })
    }
  }

  _showFutureAssetsAndRevenue = async () => {
    const data = await showFutureAssetsAndRevenue();
    if (data.code === '200') {
      this.setState({ futureData: _.get(data, 'data', {}) })
    }
  }

  _showAllSubAccountCal = async () => {
    const data1 = await showAllSubAccountCal();
    if (data1.code === '200') {
      this.setState({ futureList: _.get(data1, 'data', []) })
    }
  }

  _autoDistributeSub = async () => {
    const data = await autoDistributeSub();
    if (_.get(data, 'code') === '200') {
      const getMsg = _.get(data, 'message', '');
      if (data.data === true) {
        message.success('账户创建成功!');
        window.location.reload();
      } else {
        message.warning(getMsg);
        this.setState({ visible: true })
      }
    }
  }

  goAccountDetail = (item) => {
    this.props.history.push({
      pathname: "/home/listsDetails",
      state: { subAccountInfo: item }
    });
  }

  goFutureDetail = (item) => {
    this.props.history.push({
      pathname: '/home/fundManagerListsDetails',
      state: { subAccountInfo: item }
    });
  }

  render() {
    const { accountData, accountList, accStastic, futureData, futureList, currentKey, loader, visible } = this.state;
    const newData = currentKey === '1' ? accountData : futureData;
    const newList = currentKey === '1' ? accountList : futureList;
    const getTotalAsset = _.get(accountData, 'totalAssets', 0) ? parseInt(accountData.totalAssets) : 0
    return (
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <PageHeader
          // onBack={() => window.history.back()}
          style={{ backgroundColor: 'white' }}
          title={(currentKey === '1' ? '股票' : '期货') + '资产'}
          footer={
            <div style={{ paddingBottom: 12 }}>
              <Tabs defaultActiveKey="1" onChange={(key) => {
                this.setState({ currentKey: key })
              }}>
                <TabPane tab="股票" key="1" />
                <TabPane tab="期货" key="2" />
              </Tabs>
            </div>
          }
        >
        </PageHeader>

        <div style={{ padding: 20 }}>
          <TotalComp accountData={newData} />
        </div>
        {getTotalAsset && _.size(accountList) > 0 ? <AccountCard
          accountList={newList}
          accStastic={accStastic}
          onLink={(item) => {
            if (currentKey === '1') {
              this.goAccountDetail(item);
            } else {
              this.goFutureDetail(item);
            }
          }}
        /> : <div style={{ textAlign: 'center', marginTop: 35 }}>
          {getTotalAsset === 0 && _.size(accountList) === 0 && loader ? <div style={{ width: 300, margin: '0 auto' }}>
            <Button block danger type='primary' onClick={() => {
              this._autoDistributeSub();
            }}>
              开通100万模拟资金账户 点击即可开通
            </Button>
          </div> : null}
        </div>}

        <Modal
          title="提醒"
          visible={visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p style={{ fontSize: '16px' }}>请添加以下基金经理微信，为您开通交易服务：</p>
            <img
              src={addManager}
              alt=""
              style={{
                width: 300,
                height: 300,
                margin: '0 auto'
              }}
            />
          </div>
        </Modal>
      </div>
    )
  }
}


export default AssetDesk;