import React, { memo } from 'react';
import { Button, Table, Typography, Space } from 'antd';
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { singleColumns, RateText } from '@/utils/utils';
import { isValidArray, addNumberSplit } from '@/utils/utils2';
import AddProductDetails from './AddProductDetails';
import UpdateTraders from './UpdateTraders';
import _ from 'lodash';

const { Text } = Typography;

const AccountTable = ({
  listData = {},
  role = '',

  getCommission,
  delSubAccount,
  goBack,
  goFundManagerListsDetails
}) => {
  const isVisitor = role === 'visitor' ? true : false;
  // 页面columns渲染汇总处理
  const renderColumns = (keyArray, type, isSort) => {
    const getKey = _.get(keyArray, '[1]', '');
    let defaultProps = { 'carry': 2, 'noPrefix': true, 'rType': type, 'restStyle': { fontWeight: 400, color: 'black' } };
    if (getKey === 'netValue') {
      defaultProps.carry = 4;
    }
    let newCol = {
      ...singleColumns(keyArray),
      render: (text, record) => type === 'text' ? <Text strong>{text}</Text> : <RateText rate={text} {...defaultProps} />
    };
    if (isSort) {
      newCol.sorter = (a, b) => a[getKey] - b[getKey];
    }
    return newCol;
  }

  const columns = [
    { ...singleColumns(['账户', 'customerNumber', 120, 'center'], 'strong') },
    {
      ...singleColumns(['总资产', 'totalAsset', 130, 'center']),
      render: (text) => addNumberSplit(text)
    },
    {
      ...singleColumns(['总收益', 'totalRevenue', 130, 'center']),
      render: (text, record) => {
        let newTotal = 0;
        isValidArray(_.get(record, 'subAccountList', [])) && _.get(record, 'subAccountList', []).map(n => {
          newTotal = newTotal + _.get(n, 'totalRevenue', 0);
        })
        return <RateText rate={_.round(newTotal, 2)} rType='replace' noPrefix />
      }
    },
    {//从子账户统计中汇总得出
      ...singleColumns(['日收益', 'dailyRevenue', 130, 'center']),
      render: (text, record) => {
        let newTotal = 0;
        isValidArray(_.get(record, 'subAccountList', [])) && _.get(record, 'subAccountList', []).map(n => {
          newTotal = newTotal + _.get(n, 'dailyRevenue', 0);
        })
        return <RateText rate={_.round(newTotal, 2)} rType='replace' noPrefix />
      }
    },
    renderColumns(['可用资金', 'availableBalance', 130, 'center'], 'text'),
    !isVisitor ? {
      ...singleColumns(['操作', 'option', 180, 'center']),
      render: (text, record) => {
        return (
          <>
            <Button type='text' size='small' onClick={() => getCommission(record.productId)}>查看手续费</Button>
            <AddProductDetails listData={_.get(listData, 'table', [])} goBack={goBack} />
          </>
        )
      }
    } : {},
  ]

  const expandedRowRender = (fatRecord) => {
    const columnsChildren = [
      renderColumns(['子账户', 'name', 150, 'center'], 'text'),
      renderColumns(['资产', 'totalAsset', 120, 'center'], 'replace', true),
      renderColumns(['净值', 'netValue', 100, 'center'], 'number', true),
      renderColumns(['收益', 'totalRevenue', 100, 'center'], 'replace', true),
      renderColumns(['日收益', 'dailyRevenue', 100, 'center'], 'replace', true),
      renderColumns(['可用资金', 'availableBalance', 100, 'center'], 'replace', true),
      renderColumns(['交易员', 'traderName', 110, 'center'], 'text'),
      {
        ...singleColumns(['操作', 'option', 220, 'left']),
        render: (text, record, index) => {
          return (
            <Space size='small'>
              <Button type="link" size='small' icon={<ArrowRightOutlined />} onClick={() => goFundManagerListsDetails(record)}>查看</Button>
              {!isVisitor && <UpdateTraders fatRecord={fatRecord} record={record} goBack={goBack} />}
              {!isVisitor && <Button type="link" size='small' icon={<CloseOutlined />} danger onClick={() => delSubAccount(record)}>删除</Button>}
            </Space>
          )
        }
      }
    ]

    return (
      <Table
        rowKey="id"
        size='small'
        dataSource={_.get(fatRecord, 'subAccountList', [])}
        columns={columnsChildren}
        pagination={false}
      />
    )
  }

  return (
    <Table
      rowKey="id"
      title={() => <Space>
        <Text>今日手续费：</Text><Text strong>{_.round(_.get(listData, 'commission', 0), 2)}</Text>
        <Text style={{ marginLeft: 8 }}>总手续费：</Text><Text strong>{_.round(_.get(listData, 'totalCommission', 0), 2)}</Text>
      </Space>}
      dataSource={_.get(listData, 'table', [])}
      columns={columns}
      bordered
      expandable={{
        expandedRowRender,
        defaultExpandAllRows: true,
        expandRowByClick: true
      }}
      pagination={false}
    />
  )
}

export default memo(AccountTable);